<template>
  <div id="calculatorReturn">
    <!-- <SeparateSections
      titleSectionSeparate="calc.title_section"
      subTitleSectionSeparate="calc.description_section"
      idSelector="scroll_helper_text_4"
    /> -->

    <SeparateSections
      titleSectionSeparate="home.calcSection.calc_title_section"
      subTitleSectionSeparate="home.calcSection.calc_description_section"
      idSelector="scroll_helper_text_4"
    />

    <Calc />

  </div>
</template>
<script>
import SeparateSections from '../SeparateSections.vue'
import Calc from './Calc.vue'


export default {
  name: 'CalcHomeSection',
  components: {
    SeparateSections,
    Calc,
  }
}
</script>
