<template>
  <div>
    <div class="container-hexagon-white-meber-page mt-20">
      <div v-for="(i, index) in hexagonOptions" :key="index" class="container-hexagon-itens">
        <!-- <div class="block-hexagon-itens"> -->
          <span v-html="iconSets.hexagon_white" class="hexagon-white"></span>
          <div class="block-hexagon-itens-member-page">
            <!-- <img :src="require(`../../assets/imgs/${i.image}`)" alt="Bloomberg img" class="image-into-hexagon-member-page"> -->
            <span v-if="i.image" v-html="iconSets[i.image]" class="image-into-hexagon-member-page"></span>
            <h3 class="color-blue-darken"> {{ i.title }} </h3>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['hexagonOptions'],
  name: 'whiteHexagon',
}
</script>
