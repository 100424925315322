import Vue from 'vue'
import Vuex from 'vuex'
import { endpoints } from '../configs/index'
import axios from 'axios'
import { getDateRange } from '../utils/time'


import { percentData } from '../mock/percent.json'
import { chartData } from '../mock/chart.json'
import { currenciesData } from '../mock/curriencies.json'
import { mockDataPool } from '../mock/pool.json'
import { mockDataPoolSummary } from '../mock/poolSummary.json'
import { taxesMock } from '../mock/taxesMock.json'
import { lastPoolMock } from '../mock/LastPoolMock.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currencies: [],
    assetsPercent: [],
    blueTokenHistoryPrice: [],
    bluetokenReturn: [],
  },
  getters: {
    currencies: (state) => state.currencies,
    assetsPercent: (state) => state.assetsPercent,
    blueTokenHistoryPrice: (state) => state.blueTokenHistoryPrice,
    bluetokenReturn: (state) => state.bluetokenReturn,
  },
  mutations: {
    setPercentAssets(state, assetsPercent) {
      state.assetsPercent = assetsPercent
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies
    },
    setBlueTokenHistoryPrice(state, tokenHistoryPrice) {
      state.blueTokenHistoryPrice = tokenHistoryPrice
    },
    setBlueTokenReturn(state, bluetokenReturn) {
      state.bluetokenReturn = bluetokenReturn
    }
  },
  actions: {
    getPoolsSummaryByDate(context, params) {
      return publicGetRequest(context, `/public/pools/summary?startDate=${params.startAt}&endDate=${params.endAt}`)
      // return lastPoolMock
    },
    async getPools(context) {
      return publicGetRequest(context, "/public/pools")
      // return mockDataPool
    },
    getPoolsSummary(context) {
      return publicGetRequest(context, "/public/pools/summary")
      // return mockDataPoolSummary
    },
    async sendEmail(context, params) {
      const body = {
        cc: "",
        message: `| nome: ${params.name || ''}, | email: ${params.email || ''}, | telefone: ${params.phone || ''}, | mesagem: ${params.message || ''} | Blue Client: ${params.checkBoxAnswrer || ''}`,
        "reply-to": "sales@bluetoken.io",
        subject: params.subject,
        tagSubject: `Client email - [${params.sourcePage}]`,
        to: "sales@bluetoken.io"
      }

      return await publicPostRequest(context, "/public/mail/generic/sent", body)
    },
    async getFees(context) {
      const taxesResponse = await publicGetRequest(context, "/public/taxes")

      // return taxesMock
      return taxesResponse
    },
    async getBluePoolAverage(context) {
      
      return 1.38
    },
    async getAssetsPercent(context) {
      const requestCurrencies = await publicGetRequest(context, "/public/assets/percent").catch(err => { console.info('getAssetsPercent err', err) })
      // console.info('percentData ===> ', percentData)
      context.commit('setPercentAssets', requestCurrencies.data)
      // context.commit('setPercentAssets', percentData)
    },
    async getCurrencies(context) {
      const requestCurrencies = await publicGetRequest(context, "/public/assets/percent").catch(err => { console.info('getAssetsPercent err', err) })
      // console.info('percentData ===> ', percentData)
      context.commit('setPercentAssets', requestCurrencies.data)
      return requestCurrencies.data
      // return currenciesData
    },
    async getAllAssets(context) {
      const requestCurrencies = await publicGetRequest(context, "/public/currencies").catch(err => { console.info('getAllAssets err', err) })
      // const requestCurrencies = await publicGetRequest(context, "/?type=meat-and-filler").catch(err => { console.info('err', err) })

      context.commit('setCurrencies', requestCurrencies.data)
      // context.commit('setCurrencies', currenciesData)
    },
    async getBlueTokenInfo(context) {
      const requestCurrencies = await publicGetRequest(context, "/public/currencies").catch(err => { console.info('getAllAssets err', err) })
      // const requestCurrencies = await publicGetRequest(context, "/?type=meat-and-filler").catch(err => { console.info('err', err) })

      // context.commit('setCurrencies', requestCurrencies.data)
      // console.info('index > ', currenciesData)
      // const filterBlueprice = currenciesData.filter((item) => { return item.symbol === 'BLU' })
      const filterBlueprice = requestCurrencies.data.filter((item) => { return item.symbol === 'BLU' })
      if (filterBlueprice.length > 0) {
        return filterBlueprice[0]
      }
      return null
    },
    async getBitcoinInfo(context) {
      const requestCurrencies = await publicGetRequest(context, "/public/currencies").catch(err => { console.info('getAllAssets err', err) })
      // const requestCurrencies = await publicGetRequest(context, "/?type=meat-and-filler").catch(err => { console.info('err', err) })

      // context.commit('setCurrencies', requestCurrencies.data)
      // console.info('index > ', currenciesData)
      // const filterBlueprice = currenciesData.filter((item) => { return item.symbol === 'BLU' })
      const filterBlueprice = requestCurrencies.data.filter((item) => { return item.symbol === 'BTC' })
      if (filterBlueprice.length > 0) {
        return filterBlueprice[0]
      }
      return null
    },
    async getCurrenciesHistory(context, params) {

      const endDate = getDateRange();

      const url =  `/public/currencies/history?start=${params.start ? params.start : '2018-12-04T00:00:09.0Z'}&end=${params.end ? params.end : endDate}&pair=${params.pair ? params.pair : 'usdt'}&period=${params.period ? params.period : '720'}&symbols=${params.sumbols ? params.sumbols : 'blu'}`

      const responseChartDataApi = await publicGetRequest(
        context,
        url
      );
      // localStorage.setItem('transformSavedChartInfo', JSON.stringify(responseChartDataApi.data))
      // context.commit('setBlueTokenHistoryPrice', chartData)
      context.commit('setBlueTokenHistoryPrice', responseChartDataApi.data)

      // return responseChartDataApi.data
      // return chartData
      return responseChartDataApi.data
  
    },
    async getStockPrice(context, params) {
      const respStockPric = await publicGetRequest(context, `/stock/${params}`);
      return respStockPric
    },
    async getBlueReturn(context, params) {
      const respStockPric = await publicGetRequest(context, `/stock/blue-return`);
      context.commit('setBlueTokenReturn', respStockPric.data)
      return respStockPric.data
    },
    async getStockAssetsReturn(context, params) {
      const respStockPric = await publicGetRequest(context, `/stock/assets/return/${params}`);
      // const url = `http://localhost:8085/api/assets/return/${params}`
      // const respStockPric = await axios.get(url);
      return respStockPric
    }
  },
  modules: {
  }
})

async function publicGetRequest(context, uri, form) {
  return baseRequest(context, "get", uri, form, false)
}

async function publicPostRequest(context, uri, form) {
  return baseRequest(context, "post", uri, form, false)
}

async function baseRequest(context, method, uri, form, authenticated) {
  try {
      let data = JSON.stringify(form);
      let prefix = "";
      // if (window.location.origin.includes("www")) {
      //     prefix = "www.";
      // }
      // let protocol = 'https:'

      let type = '/public';

      if(authenticated){
          type = '/restrict';
      }

      // let url = endpoints.protocol + "//" + prefix + endpoints.backendUrl + "/" + endpoints.urlSuffix + type + uri;
      let url = endpoints.protocol + "//" + endpoints.backendUrl + "/" + endpoints.urlSuffix + uri;

      // console.info('url request ===> ', url)
      // const url = " http://172.16.17.124:84/blue/" + uri ;

      let headers = {
        "content-type": "application/json"
      };
      let withCredentials = false;
      if (authenticated) {
          headers = { 'x-token': context.getters.token };
          withCredentials = true;

          // console.log('aq', headers)
      }
      return await axios({
          method: method,
          url: url,
          data: data,
          headers: headers,
          withCredentials: withCredentials
      });
  } catch (error) {
      if (error.response === undefined) {
        return {error}
      }
      if (error.response) {
          if (error.response.data) {
              // console.log(error.response.data);
              throw new Error(error.response.data.message)
          }
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          if (!error.status) {
              console.log(error.message)
          }
          // throw new Error(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(error.message);
      }
      throw error;
  }


}