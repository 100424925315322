<template>
    <div class="bg-white">
    <InitialBanner
      image="bg_banner_terms_page.png"
      imageTitle="privacy_terms.banner_title"
    />
    <div class="container-default d-flex container-terms">
        <div class="container-text-terms">
            <div>
                <div v-for="(i, index) in terms" :key="index">
                    <h1 class="color-blue-darken" v-html="$t(i.title)"></h1>
                    <p class="txt-alg-justify color-black" v-html="$t(i.description)"></p>
                </div>
            </div>
        </div>

        <div class="content-button">
            <button class="btn-first" @click="redirectToTermsDoc()">
                <span v-html="iconSets.downloadIcon" class="btn-download"></span>
                <p class="m0 color-purple" v-html="$t('download')"></p>
            </button>
        </div>
    </div>
    </div>
</template>

<script>
import InitialBanner from '../components/InitialBanner.vue'

export default {
    name: 'Privacy',
    components: {
        InitialBanner,
    },
    data:() => ({
        terms: [
            {
                title: `privacy_terms.condition_title_01`,
                description: `privacy_terms.condition_description_01`,
            },
            {
                title: `privacy_terms.condition_title_02`,
                description: `privacy_terms.condition_description_02`,
            },
            {
                title: `privacy_terms.condition_title_03`,
                description: `privacy_terms.condition_description_03`,
            },
            {
                title: `privacy_terms.condition_title_04`,
                description: `privacy_terms.condition_description_04`,
            },
            {
                title: `privacy_terms.condition_title_05`,
                description: `privacy_terms.condition_description_05`,
            },
            {
                title: `privacy_terms.condition_title_06`,
                description: `privacy_terms.condition_description_06`,
            },
            {
                title: `privacy_terms.condition_title_07`,
                description: `privacy_terms.condition_description_07`,
            },
            {
                title: `privacy_terms.condition_title_08`,
                description: `privacy_terms.condition_description_08`,
            },
            {
                title: `privacy_terms.condition_title_09`,
                description: `privacy_terms.condition_description_09`,
            },
            {
                title: `privacy_terms.condition_title_10`,
                description: `privacy_terms.condition_description_10`,
            },
            {
                title: `privacy_terms.condition_title_11`,
                description: `privacy_terms.condition_description_11`,
            },
            {
                title: `privacy_terms.condition_title_12`,
                description: `privacy_terms.condition_description_12`,
            },
            {
                title: `privacy_terms.condition_title_13`,
                description: `privacy_terms.condition_description_13`,
            },
            {
                title: `privacy_terms.condition_title_14`,
                description: `privacy_terms.condition_description_14`,
            },
            {
                title: `privacy_terms.condition_title_15`,
                description: `privacy_terms.condition_description_15`,
            },
            {
                title: `privacy_terms.condition_title_16`,
                description: `privacy_terms.condition_description_16`,
            }
        ]
    }),
    methods: {
        redirectToTermsDoc() {
            window.open('./docs/privacy_policy.pdf', '_blank');
        }
    }
}
</script>

<style>

</style>