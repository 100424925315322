<template>
  <div>

      <!-- imageTitle="blue_club.banner_title" -->
    <InitialBanner
      image="bg_banner_blue_club_page.png"
    />
    <div class="mb-10 container-default">
      <SeparateSections
        titleSectionSeparate="blue_club.blue_club_title_section_separate"
        subTitleSectionSeparate="blue_club.blue_club_subtitle_section_separate"
      />

      <a 
        href="https://solscan.io/token/AP9rP81KJzPCQFmE4fK9Ue8NWRaXZ5QbqQJn6PKhQP17" 
        target="_blank"
        class="btn-outlined-default btn-first-blu-club-page" 
        v-html="$t('blue_club.blue_club_view_nft')"
      ></a>

      <div class="container-blue-club-content">
        <div class="left-container-blue-club">
          <h1> {{ $t('blue_club.benefits_title') }} </h1>
          <div>
            <div class="d-flex align-items-center mt-5" v-for="(i, index) in benefitsItens" :key="index">
              <span v-html="iconSets.blueToken_outline" class="blue-token-outline blue-club-icon-item"></span>
              <p class="m0 ml-4"> {{ $t(`${i}`) }} </p>
            </div>

            <button @click="redirectToSignup()" class="default-white-button custom-button-blue-club mt-5">
              <p class="bold color-purple"> {{ $t('blue_club.button_join_now') }} </p>
            </button>
          </div>

        </div>

        <div class="right-container-blue-club">
          <img src="../assets/imgs/blue_club_benefits_img_1.png" alt="" class="image-blue-club">
        </div>
      </div>

    </div>
  </div>
</template>
<script>

import InitialBanner from '../components/InitialBanner.vue'
import SeparateSections from '../components/SeparateSections.vue'

export default {
  name: 'BlueClub',
  components: {
    InitialBanner,
    SeparateSections,
  },
  data: () => ({
    benefitsItens: [
      'blue_club.benefits_item_1',
      'blue_club.benefits_item_2',
      'blue_club.benefits_item_3',
      'blue_club.benefits_item_4',
      'blue_club.benefits_item_5',
      'blue_club.benefits_item_6',
    ]
  }),
  methods: {
    redirectToSignup() {
      window.location.href = 'https://bluetoken.io/app/signup'
    }
  }
}
</script>
