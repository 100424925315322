<template>
  <div class="vue-expand-panel">
    <div class="catalog">
      <div class="item-container" @click="openBar = !openBar">
        <div class="index-bar" :class="{ 'index-bar-border-radius': openBar === true }">
          <p class="left-index m0">{{ $t(title) }}</p>
          <div class="d-flex">
            <!-- <div class="arrow" :class="{ 'rolling-in-the-deep': openBar === true }"></div> -->
            <span v-html="iconSets.plus_icon" class="button-toggle-faq-item-icon"></span>
          </div>
        </div>
      </div>
    </div>

        <div class="content" :class="{ 'content-height': openBar === true }">
          <div>
            <slot></slot>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandPanel',
  props: {
    title: String
  },
  data:() => ({
    openBar: false
  }),
  methods: {
    clickBar () {
      const currentOpenBar = this.openBar
      this.openBar = !currentOpenBar
    }
  }
}
</script>
