<template>
  <div>
    <!-- {{ hexagonOptions }} -->

    <div class="container-hexagon-white-fee-page">
      <div
        v-for="(i, index) in firstData"
        :key="index"
      >
        <div class="container-hexagon-itens-fee-page">
          <img src="../assets/imgs/white_hexagon_fees.png" alt="Bloomberg img" class="image-into-hexagon-fees">
          <div class="block-hexagon-itens-member-page">
            <h3 v-if="i.title" class="color-blue-darken custom-title-fee-page" v-html="$t(`${i.title}`)"></h3>
            <h3 v-if="i.mainValue" class="color-purple bold custom-first-value" v-html="$t(`${i.mainValue}`)"></h3>
            <p v-if="i.mainValueSubtitle" class="color-purple bold" v-html="$t(`${i.mainValueSubtitle}`)"></p>
            <p v-if="i.subTitle" class="color-black bold custom-subtitle-fee-page" v-html="$t(`${i.subTitle}`)"></p>
          </div>
        </div>        
      </div>
    </div>

    <div class="container-hexagon-white-fee-page-second">
      <div
        v-for="(i, index) in secondData"
        :key="index"
      >
        <div class="container-hexagon-itens-fee-page-second">
          <img src="../assets/imgs/white_hexagon_fees.png" alt="Bloomberg img" class="image-into-hexagon-fees">
          <div class="block-hexagon-itens-member-page">
            <h3 v-if="i.title" class="color-blue-darken custom-title-fee-page" v-html="$t(`${i.title}`)"></h3>
            <h3 v-if="i.mainValue" class="color-purple bold custom-first-value" v-html="$t(`${i.mainValue}`)"></h3>
            <p v-if="i.mainValueSubtitle" class="color-purple bold" v-html="$t(`${i.mainValueSubtitle}`)"></p>
            <p v-if="i.subTitle" class="color-black bold custom-subtitle-fee-page" v-html="$t(`${i.subTitle}`)"></p>
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['firstData', 'secondData'],
  name: 'whiteHexagon',
}
</script>
