<template>
    <div class="bg-white">
    <InitialBanner
      image="bg_banner_terms_page.png"
      imageTitle="terms_and_conditions.banner_title"
    />
    <div class="container-default d-flex container-terms">
        <div class="container-text-terms">
            <div>
                <div v-for="(i, index) in terms" :key="index">
                    <h1 class="color-blue-darken" v-html="$t(i.title)"></h1>
                    <p class="txt-alg-justify color-black" v-html="$t(i.description)"></p>
                </div>
            </div>
        </div>

        <div class="content-button">
            <button class="btn-first" @click="redirectToTermsDoc()">
                <span v-html="iconSets.downloadIcon" class="btn-download"></span>
                <p class="m0 color-purple"> {{ $t('download') }} </p>
            </button>
        </div>
    </div>
    </div>
</template>

<script>
import InitialBanner from '../components/InitialBanner.vue'

export default {
    name: 'TermsConditions',
    components: {
        InitialBanner,
    },
    data:() => ({
        terms: [
            {
                title: `terms_and_conditions.condition_title_01`,
                description: `terms_and_conditions.condition_description_01`,
            },
            {
                title: `terms_and_conditions.condition_title_02`,
                description: `terms_and_conditions.condition_description_02`,
            },
            {
                title: `terms_and_conditions.condition_title_03`,
                description: `terms_and_conditions.condition_description_03`,
            },
            {
                title: `terms_and_conditions.condition_title_04`,
                description: `terms_and_conditions.condition_description_04`,
            },
            {
                title: `terms_and_conditions.condition_title_05`,
                description: `terms_and_conditions.condition_description_05`,
            },
            {
                title: `terms_and_conditions.condition_title_06`,
                description: `terms_and_conditions.condition_description_06`,
            },
            {
                title: `terms_and_conditions.condition_title_07`,
                description: `terms_and_conditions.condition_description_07`,
            },
            {
                title: `terms_and_conditions.condition_title_08`,
                description: `terms_and_conditions.condition_description_08`,
            },
            {
                title: `terms_and_conditions.condition_title_09`,
                description: `terms_and_conditions.condition_description_09`,
            },
            {
                title: `terms_and_conditions.condition_title_10`,
                description: `terms_and_conditions.condition_description_10`,
            },
            {
                title: `terms_and_conditions.condition_title_11`,
                description: `terms_and_conditions.condition_description_11`,
            },
            {
                title: `terms_and_conditions.condition_title_12`,
                description: `terms_and_conditions.condition_description_12`,
            },
            {
                title: `terms_and_conditions.condition_title_13`,
                description: `terms_and_conditions.condition_description_13`,
            },
            {
                title: `terms_and_conditions.condition_title_14`,
                description: `terms_and_conditions.condition_description_14`,
            },
            {
                title: `terms_and_conditions.condition_title_15`,
                description: `terms_and_conditions.condition_description_15`,
            },
            {
                title: `terms_and_conditions.condition_title_16`,
                description: `terms_and_conditions.condition_description_16`,
            },
            {
                title: `terms_and_conditions.condition_title_17`,
                description: `terms_and_conditions.condition_description_17`,
            },
            {
                title: `terms_and_conditions.condition_title_18`,
                description: `terms_and_conditions.condition_description_18`,
            },
            {
                title: `terms_and_conditions.condition_title_19`,
                description: `terms_and_conditions.condition_description_19`,
            },
            {
                title: `terms_and_conditions.condition_title_20`,
                description: `terms_and_conditions.condition_description_20`,
            },
            {
                title: `terms_and_conditions.condition_title_21`,
                description: `terms_and_conditions.condition_description_21`,
            },
            {
                title: `terms_and_conditions.condition_title_22`,
                description: `terms_and_conditions.condition_description_22`,
            },
            {
                title: `terms_and_conditions.condition_title_23`,
                description: `terms_and_conditions.condition_description_23`,
            },
            {
                title: `terms_and_conditions.condition_title_24`,
                description: `terms_and_conditions.condition_description_24`,
            },
            {
                title: `terms_and_conditions.condition_title_25`,
                description: `terms_and_conditions.condition_description_25`,
            },
            {
                title: `terms_and_conditions.condition_title_26`,
                description: `terms_and_conditions.condition_description_26`,
            },
            {
                title: `terms_and_conditions.condition_title_27`,
                description: `terms_and_conditions.condition_description_27`,
            },
            {
                title: `terms_and_conditions.condition_title_28`,
                description: `terms_and_conditions.condition_description_28`,
            },
            {
                title: `terms_and_conditions.condition_title_29`,
                description: `terms_and_conditions.condition_description_29`,
            },
            {
                title: `terms_and_conditions.condition_title_30`,
                description: `terms_and_conditions.condition_description_30`,
            },
            {
                title: `terms_and_conditions.condition_title_31`,
                description: `terms_and_conditions.condition_description_31`,
            },
        ]
    }),
    methods: {
        redirectToTermsDoc() {
            window.open('./docs/BLUE_T_C_MAY2022.pdf', '_blank');
        }
    }
}
</script>

<style>

</style>