<template>
  <div>

    <div class="d-flex align-items-center container-custom-calc-home">

      <div class="container-white-card-home-calc">
        <div class="white-card custom-card-calc-home">
          <div class="container-itens-home-calc">
            <div class="container-calc-inputs">

              <div class="container-avaible-capital-input">
                <h5 class="title-calc color-blue-darken">{{ $t('home.calcSection.input_avaible_capital') }}</h5>
                  <money 
                    class="default-calc-input"
                    v-model="avaibleCapital" 
                    v-bind="money"
                  ></money>
                <!-- <input placeholder="R$1.000,00" class="default-calc-input" v-model="avaibleCapital" /> -->
                <!-- <span class="currencyinput">$
                  <input
                    placeholder="R$1.000,00"
                    class="default-calc-input"
                    v-model="avaibleCapital"
                    type="text"
                    @keypress="validateInputNumber"
                    @keyup="applyPercentInitialCapital()"
                    v-mask="'###.###.###-##'"
                  >
                </span> -->
              </div>

              <div class="block-tolerance-calc">
                <div>
                  <h5 class="title-calc color-blue-darken">{{ $t('home.calcSection.input_tolerance') }}</h5>
                  <div class="container-range-tolerance-calc">
                    <input id="range-1" v-model="tolerance" type="range" min="0" max="2" @change="applyPercentInitialCapital()">
                  </div>
                  <div class="d-flex container-range-inputs container-investment-horizon-options">
                    <p v-html="$t('home.calcSection.input_tolerance_0')"></p>
                    <p v-html="$t('home.calcSection.input_tolerance_1')"></p>
                    <p v-html="$t('home.calcSection.input_tolerance_2')"></p>
                  </div>
                </div>
              </div>

              <div class="d-flex block-initial-investment">
                <div class="custom-container-initial-capital-input">
                  <h5 class="title-calc color-blue-darken font-size-1-1" v-html="$t('home.calcSection.input_initial_capital')"></h5>
                  <!-- <input placeholder="R$1.000,00" class="default-calc-input" v-model="initialCapital" @keypress="validateInputNumber"/> -->
                  <money 
                    class="default-calc-input"
                    v-model="initialCapital"
                    v-bind="money"
                  ></money>
                  <!-- <p>{{ initialCapitalPercentage }}{{ $t('home.calcSection.input_initial_capital_warning') }}</p> -->
                </div>
              </div>

              <div class="d-flex">
                <div class="custom-container-monthly-contribution-input">
                  <h5 class="title-calc color-blue-darken font-size-1-1">{{ $t('home.calcSection.input_monthly_contribution') }}</h5>
                  <!-- <input placeholder="R$1.000,00" class="default-calc-input" v-model="monthlyContribution" @keypress="validateInputNumber"/> -->
                  <money 
                    class="default-calc-input"
                    v-model="monthlyContribution"
                    v-bind="money"
                  ></money>
                </div>
              </div>

            </div>

            <div class="container-calc-inputs">

              <div>
                <h5 class="title-calc">{{ $t('home.calcSection.select_goal') }}</h5>
                <div class="custom-select-calc" >
                  <div class="main-select" @click="selectCalcOptions = !selectCalcOptions">
                    <div class="container-selected-goal">
                      <p class="text-select-calc">{{ $t(selectedGoalOptionState.textOption) }}</p>
                      <span class="icon-select-calc" v-html="iconSets.calc[selectedGoalOptionState.icon]"></span>
                      <span class="chevron-select-calc" v-html="iconSets.chevron_down"></span>
                    </div>
                  </div>

                  <div :class="selectCalcOptions ? 'visible-dropdown-calc-options' : 'hidden-dropdown-calc-options' " class="container-options-menu">
                    <div class="options-menu" v-for="(i, index) in goalOptions" :key="index" @click="selectGoalOption(i)">
                      <p class="text-select-calc">{{ $t(i.textOption) }}</p>
                      <span class="icon-select-calc" v-html="iconSets.calc[i.icon]"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="block-target-value-calc">
                <h5 class="title-calc color-blue-darken">{{ $t('home.calcSection.input_target_value') }}</h5>
                <!-- <input placeholder="R$1.000,00" class="default-calc-input" v-model="targetValue" @keypress="validateInputNumber"/> -->
                  <money 
                    class="default-calc-input"
                    v-model="targetValue"
                    v-bind="money"
                  ></money>
              </div>

              <div class="block-investment-horizon-calc">
                <h5 class="title-calc color-blue-darken">{{ $t('home.calcSection.input_invesment_horizon') }}</h5>

                <div class="container-range-tolerance-calc" :class="investHorizon === '2' ? 'set-max-range-tolerance-icon' : 'test' ">
                  <div class="d-flex container-range-inputs">
                    <p>1 {{ $t('home.calcSection.year_range_input') }}</p>
                    <p>2 {{ $t('home.calcSection.years_range_input') }}</p>
                    <p>3 {{ $t('home.calcSection.years_range_input') }} +</p>
                  </div>
                  <input id="range-1" v-model="investHorizon" type="range" min="0" max="2">
                </div>

                <div id="selector">
                  <div class="selectBtn"></div>
                </div>

              </div>

            </div>

            <div class="d-flex-responsive-column-reverse mt-4 justify-space-between">

              <div class="container-footer-description-calc">
                <p class="color-blue-darken">{{ $t('home.calcSection.calc_footer_description') }}</p>
              </div>

              <div>
                <button @click="calcAllValues()" class="btn-calc-home"> {{ $t('home.calcSection.calc_button') }} </button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="container-bg-hexagon-token-bg">
        <div>
          <!-- <img src="../../assets/imgs/bg_hexagon_token_bg.png" alt="" class="bg_hexagon_token_bg"> -->
          <img 
            :src="widthScreen < 700 ? `${require('../../assets/imgs/bg_result_calc_home.png')}` : `${require('../../assets/imgs/bg_hexagon_token_bg.png')}`" 
            alt="" 
            class="bg_hexagon_token_bg">
        </div>

        <div class="container-result-description-calc-home">

          <div class="container-image-text-result">
            <div :class="calcResultStatus === '' ? 'hide-result-message-hexagon' : 'show-result-message-hexagon'" class="block-image-text-result">
              <img class="image-calc-result-token" src="../../assets/imgs/hexagon_white_outlined_token.png" alt="">
              <div class="container-message-result-calc">
                <p v-if="calcResultStatus === 'positive'" class="text-message-result-calc">
                  <span>{{ $t('home.calcSection.congratsMessageCalc') }} </span> 
                  <span>{{ $t(checkGoalMessage(selectedGoalOptionState.textOption)) }} </span> 
                  <span>{{ $t('home.calcSection.congratsMessageCalcFinal') }}</span>
                </p>
                <p class="text-message-result-calc" v-if="calcResultStatus === 'negative' ">{{ $t('home.calcSection.almost_there_negative_calc_result') }}</p>
              </div>
            </div>
          </div>

          <h5 class="custom-title-result">{{ $t('home.calcSection.calc_result_title') }}</h5>
          <p>{{ $t('home.calcSection.total_invested') }} <span class="color-yel"> {{ totalInvestment }} </span> </p>
          <p>{{ $t('home.calcSection.future_Value') }} <span class="color-yel"> {{ returnValue }} </span> </p>
          <p>{{ $t('home.calcSection.gain') }} <span class="color-yel"> {{ gainValue }} </span> </p>

          <p>{{ $t('home.calcSection.calc_footer_message') }}</p>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { Money } from 'v-money'
import { mapGetters } from 'vuex'

export default {
  components: {
    Money
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies',
    }),
  },
  data:() => ({
    price: 123.45,
    bluFirstPrice: 39.57,
    widthScreen: 0,
    money: {
      decimal: '.',
      thousands: ',',
      prefix: '$ ',
      suffix: '',
      precision: 2,
      masked: false
    },

    calcResultStatus: '',

    selectCalcOptions: false,
    
    resultCalcMessage: "",
    
    tolerance: 0,
    investHorizon: 0,
    initialCapital: "",
    avaibleCapital: "",
    monthlyContribution: "",
    targetValue: "",
    selectGoal: '',

    initialCapitalPercentage: 0,
    transformInvestHorizon: 0,

    totalFutureValue: 0,
    totalMonthlyContribution: 0,
    futureValuePlusMonthlyContribution: 0,

    totalInvestment: "",
    returnValue: "",
    gainValue: "",

    selectedGoalOptionState: {
      textOption: "home.calcSection.input_goal_option_1",
      icon: "car",
    },

    goalOptions: [
      {
        textOption: "home.calcSection.input_goal_option_1",
        icon: "car",
      },
      {
        textOption: "home.calcSection.input_goal_option_2",
        icon: "business",
      },
      {
        textOption: "home.calcSection.input_goal_option_4",
        icon: "school",
      },
      {
        textOption: "home.calcSection.input_goal_option_3",
        icon: "house",
      },
      {
        textOption: "home.calcSection.input_goal_option_5",
        icon: "options",
      },
    ]
  }),
  mounted() {
    this.checkInvestHorizon();
    // this.checkCurrentCoin();
    this.checkInitalGoal(this.goalOptions[0]);
    this.getWithScreen()
  },
  methods: {
    checkCurrentCoin() {
      // console.info('this.$i18n.locale > ', this.$i18n.locale)
      if (this.$i18n.locale === 'pt') {
        this.money = {
          decimal: '.',
          thousands: ',',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        }
      }
    },
    calcTotalInvested() {
      const totalInvested = parseFloat(this.initialCapital) + (parseFloat(this.monthlyContribution) * parseFloat(this.transformInvestHorizon));
      this.totalInvestment = totalInvested
    },
    calcFutureValue() {
      const resultFirstOperation = (1+0.079)
      const fv1 = Math.pow(resultFirstOperation, Number(this.transformInvestHorizon))
      const totalFv1 = parseFloat(this.initialCapital)*fv1

      this.totalFutureValue = parseFloat(totalFv1.toFixed(2))
      return parseFloat(totalFv1.toFixed(2))
    },
    calcMonthlyContribution() {
      const resultFirstOperation = (1+0.079)
      const potency = Math.pow(resultFirstOperation, parseFloat(this.transformInvestHorizon))
      const preResult = (potency - 1)/0.079
      const totalFv2 = preResult*parseFloat(this.monthlyContribution)
      
      this.totalMonthlyContribution = parseFloat(totalFv2.toFixed(2))
      return parseFloat(totalFv2.toFixed(2))
    },
    calcResultGains() {
      const totalReturnValue = (this.futureValuePlusMonthlyContribution-this.totalInvestment)/this.totalInvestment
      const totalGainValue = (totalReturnValue.toFixed(2))*100
      this.gainValue = `${totalGainValue.toFixed(2)}%`
      this.returnValue= `${this.futureValuePlusMonthlyContribution.toFixed(2)}`
    },
    calcTotalFutureValue(){
      this.calcTotalInvested();
      const totalFutureValue = this.totalFutureValue + this.totalMonthlyContribution
      this.futureValuePlusMonthlyContribution = totalFutureValue
    },
    checkInvestHorizon() {
      if (Number(this.investHorizon) === 0) {
        this.transformInvestHorizon = 12;

      } else if (Number(this.investHorizon) === 1) {
        this.transformInvestHorizon = 24;

      } else if (Number(this.investHorizon) === 2) {
        this.transformInvestHorizon = 36;
      }
    },
    validateInputNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    applyPercentInitialCapital() {
      if (parseFloat(this.tolerance) === 0) {
        this.initialCapital = this.avaibleCapital*0.1
      }else if (parseFloat(this.tolerance) === 1) {
        this.initialCapital = this.avaibleCapital*0.2

      }else if (parseFloat(this.tolerance) === 2) {
        this.initialCapital = this.avaibleCapital*0.3
      }
    },
    checkToleranceBar() {
      const initialCapitalPercentageTotal = this.initialCapital/this.avaibleCapital
      // console.info('initialCapitalPercentageTotal > ', initialCapitalPercentageTotal)

      this.initialCapitalPercentage = initialCapitalPercentageTotal*100
      if (initialCapitalPercentageTotal < 0.2) {
        this.tolerance = 0;
      }
      if (initialCapitalPercentageTotal > 0.2 && initialCapitalPercentageTotal < 0.3) {
        this.tolerance = 1;
      }
      if (initialCapitalPercentageTotal > 0.3) {
        this.tolerance = 2;
      }
    },
    selectGoalOption(selectedGoalOption) {
      this.selectedGoalOptionState = selectedGoalOption
      this.selectCalcOptions = !this.selectCalcOptions
      this.checkInitalGoal(selectedGoalOption)
    },
    calcResultMessage() {
      if (parseFloat(this.returnValue) > parseFloat(this.targetValue)) {
        this.calcResultStatus = 'positive'
      } else {
        this.calcResultStatus = 'negative'
      }
    },
    checkInitalGoal(selectedGoalOption) {
      if ( selectedGoalOption.icon === "car") {
        this.targetValue = 10000  
      }
      if ( selectedGoalOption.icon === "business") {
        this.targetValue = 20000
      }
      if ( selectedGoalOption.icon === "house") {
        this.targetValue = 100000
      }
      if ( selectedGoalOption.icon === "school") {
        this.targetValue = 30000
      }
      if ( selectedGoalOption.icon === "options") {
        this.targetValue = 0
      }
    },
    checkGoalMessage(currentGoal) {
      if (currentGoal === "home.calcSection.input_goal_option_1") {
        return "home.calcSection.goal_option_1_calc_result"
      }
      if (currentGoal === "home.calcSection.input_goal_option_2") {
        return "home.calcSection.goal_option_2_calc_result"
      }
      if (currentGoal === "home.calcSection.input_goal_option_3") {
        return "home.calcSection.goal_option_3_calc_result"
      }
      if (currentGoal === "home.calcSection.input_goal_option_4") {
        return "home.calcSection.goal_option_4_calc_result"
      }
      if (currentGoal === "home.calcSection.input_goal_option_5") {
        return "home.calcSection.goal_option_5_calc_result"
      }
    },
    getWithScreen() {
      this.widthScreen = window.innerWidth
    },

    async getBlueTokenDays() {
      const allBlueDates = await this.$store.dispatch('getCurrenciesHistory')

      return allBlueDates[0].history.length
    },
    async getBluePriceReturn() {

      const filterBlueprice = await this.$store.dispatch('getBlueTokenInfo')
      if (filterBlueprice) {
        let result;
        result = ((filterBlueprice.value - this.bluFirstPrice) / this.bluFirstPrice) * 100;

        return result.toFixed(2)
      }
      return 0
    },
    async calcInterest() {
      const calc = parseFloat(await this.getBluePriceReturn()) / parseFloat(await this.getBlueTokenDays())
      // const result = (calc*30) * 100
      const result = (calc*30)
      // console.info('result > ', result.toFixed(2))
      return result.toFixed(2)
    },
    calcAllValues() {
      this.calcFutureValue()
      this.calcMonthlyContribution();
      this.calcTotalFutureValue();
      // this.calcTotalInvested();
      this.calcResultGains();
      this.calcResultMessage();

    },
  },
  created(){
    this.getBluePriceReturn();
    this.calcInterest();
    this.getWithScreen();
  },
  watch: {
    investHorizon() {
      this.checkInvestHorizon();
    },
    initialCapital() {
      this.checkToleranceBar()
    },
    avaibleCapital() {
      if (this.avaibleCapital > 0) {
        this.applyPercentInitialCapital()
      }
      // console.info('here > ', this.avaibleCapital)
    }
  }
}
</script>
