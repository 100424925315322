<template>
  <div class="mb-10">

    <SeparateSections
      titleSectionSeparate="faq_home.title_section_separate"
      subTitleSectionSeparate="faq_home.title_section"
      idSelector="scroll_helper_text_5"
    />

    <div class="container-home-faq">

      <div v-for="(i, index) in faqData[0].questionsAndAnswers" :key="index">
        <ExpandPanel :title="i.question">
          <div class="container-text-faq-page">
            <p class="content-text" v-html="$t(`${i.answer}`)"></p>
          </div>
        </ExpandPanel>
      </div>

      <div class="container-faq-button-redirect">
        <button class="faq-button-redirect" @click="redirectToFaq()"> <p> {{ $t('faq_home.faq_button_redirect') }} </p> </button>
      </div>

    </div>

  </div>
</template>
<script>
import { FaqData } from '../configs/faqData'
import SeparateSections from './SeparateSections.vue'
import ExpandPanel from './ExpandPanel.vue'

export default {
  name: 'FaqSection',
  components: {
    SeparateSections,
    ExpandPanel,
  },
  data: () => ({
    faqData: FaqData,
  }),
  methods: {
    redirectToFaq() {
      this.$router.push('/Faqs')
    },
  },
}
</script>
