<template>
  <div class="" :id=" idSelector ? idSelector : '' ">
    <div v-if="titleSectionSeparate" class="container-dash-separate-section" >
      <hr class="default-dash-separate-section ml-a mr-3" />
      <div class="container-default-text-separete-section"> <p class="default-text-separete-section m0" v-html="$t(`${titleSectionSeparate}`)">  </p> </div>
      <hr class="default-dash-separate-section mr-a ml-3" />
    </div>

    <div v-if="subTitleSectionSeparate" class="container-subtitle-separate container-default-900px">
      <h1 class="default-home-title-section" v-html="$t(`${subTitleSectionSeparate}`)"> </h1>
    </div>

  </div>
</template>
<script>
export default {
  name: 'SeparateSections',
  props: ['titleSectionSeparate', 'subTitleSectionSeparate', 'idSelector'],
}
</script>
