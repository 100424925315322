const handleCurrentMonth = () => {
    var currentdate = new Date();
    const currentMonth = parseInt(currentdate.getMonth())

    if (currentdate.getMonth() === 12) {
        return (`${currentMonth < 10 ? `0${currentMonth}` : currentMonth }`)
    } else {
        return (`${currentMonth+1 < 10 ? `0${currentMonth+1}` : currentMonth+1 }`)
    }
}

export const getDateRange = () => {
    var currentdate = new Date();
    var datetime = currentdate.getFullYear()+ "-"+ handleCurrentMonth() + "-"+ (parseInt(currentdate.getDate()) < 10 ? `0${currentdate.getDate()}` : currentdate.getDate())+ "T00:00:09.0Z";

    return datetime
}