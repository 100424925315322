import svg_set from "./svg_set"

export default {
  install(Vue, options) {
      Vue.mixin({
          data: () => ({
              iconSets: {
                ...svg_set,
              }
          })
      });
  }
};
