<template>
  <div class="home">
    <ScrollHelper />
    <HomeBanner />
    <div class="container-default">
      <BlueTokenChart />

      <!-- <HomeChartCompareTest /> -->

      <!-- <HomeStakingHexagon /> -->
      <!-- <CalcHome /> -->
    </div>

    <KnowMoreHome />
    
    <div class="container-default">
      <!-- <HomeCharity /> -->
      <HomeFaq />
      <HomeTutorial />
    </div>
  </div>
</template>

<!-- <HomeMidia /> -->
<script>
// @ is an alias to /src
import HomeBanner from '@/components/HomeBanner.vue'
import BlueTokenChart from '@/components/BlueTokenChart.vue'
import HomeChartCompareTest from '@/components/HomeChartCompareTest.vue'
import HomeStakingHexagon from '@/components/HomeStakingHexagon.vue'
import HomeCharity from '@/components/HomeCharity.vue'
import CalcHome from '@/components/CalcHome'
import KnowMoreHome from '@/components/KnowMoreHome'
import HomeMidia from '@/components/HomeMidia'
import HomeFaq from '@/components/HomeFaq'
import HomeTutorial from '@/components/HomeTutorial'
import ScrollHelper from '@/components/ScrollHelper'

export default {
  name: 'Home',
  components: {
    HomeBanner,
    BlueTokenChart,
    HomeChartCompareTest,
    HomeStakingHexagon,
    HomeCharity,
    CalcHome,
    KnowMoreHome,
    HomeMidia,
    HomeFaq,
    HomeTutorial,
    ScrollHelper,
  }
}
</script>
