<template>
  <div>
    <InitialBanner
      image="bg_banner_fees_page.png"
      imageTitle="feesAndMinimus.titleBanner"
    />

    <SeparateSections
      titleSectionSeparate="feesAndMinimus.title_section_separate"
    />

    <div class="container-default mb-10">
      <WhiteHexagonMultiple 
        :firstData="firstData"
        :secondData="secondData"
      />
        <!-- :hexagonOptions="hexagonOptions"  -->
    </div>

  </div>
</template>

<script>

import InitialBanner from '../components/InitialBanner.vue'
import SeparateSections from '../components/SeparateSections.vue'
import WhiteHexagonMultiple from '../components/WhiteHexagonMultiple.vue'

export default {
  name: 'Fees',
  components: {
    InitialBanner,
    SeparateSections,
    WhiteHexagonMultiple,
  },
  data: () => ({
    hexagonOptions: [],
    firstData: [
      {
        title: 'feesAndMinimus.item_hexagon_title_0',
        mainValue: 'feesAndMinimus.item_hexagon_value_0',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_0'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_1',
        mainValue: 'feesAndMinimus.item_hexagon_value_1',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_1'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_2',
        mainValue: 'feesAndMinimus.item_hexagon_value_2',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_2'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_3',
        mainValue: 'feesAndMinimus.item_hexagon_value_3',
        // mainValueSubtitle: 'feesAndMinimus.item_hexagon_value_subtitle_3',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_3'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_4',
        mainValue: 'feesAndMinimus.item_hexagon_value_4',
        // subTitle: 'feesAndMinimus.item_hexagon_subtitle_4'
      }
    ],
    secondData: [
      {
        title: 'feesAndMinimus.item_hexagon_title_5',
        mainValue: 'feesAndMinimus.item_hexagon_value_5',
        // mainValueSubtitle: 'feesAndMinimus.item_hexagon_value_subtitle_5',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_5'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_6',
        mainValue: 'feesAndMinimus.item_hexagon_value_6',
        // subTitle: 'feesAndMinimus.item_hexagon_subtitle_6'
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_7',
        mainValue: 'feesAndMinimus.item_hexagon_value_7',
        // mainValueSubtitle: 'feesAndMinimus.item_hexagon_value_subtitle_7',
      },
      {
        title: 'feesAndMinimus.item_hexagon_title_8',
        mainValue: 'feesAndMinimus.item_hexagon_value_8',
        subTitle: 'feesAndMinimus.item_hexagon_subtitle_8',
      },
    ]
  }),
}
</script>
