<template>
  <div class="p-bottom-1">

    <!-- <div class="container-dash-separate-section">
      <hr class="default-dash-separate-section ml-a mr-3" />
      <div class="container-default-text-separete-section"> <p class="default-text-separete-section m0"> {{ $t('tutorials_home.title_section_separate') }} </p> </div>
      <hr class="default-dash-separate-section mr-a ml-3" />
    </div> -->

    <!-- <h1 class="default-home-title-section"> {{ $t('tutorials_home.title_section') }} </h1> -->

    <SeparateSections 
      titleSectionSeparate="tutorials_home.title_section_separate"
      subTitleSectionSeparate="tutorials_home.title_section"
      idSelector="scroll_helper_text_6"
    />

    <BlueTutorial calledPage="home"/>
  </div>
</template>
<script>
import SeparateSections from './SeparateSections.vue'
import BlueTutorial from './BlueTutorial.vue'

export default {
  name: 'HomeTutorialSection',
  components: {
    SeparateSections,
    BlueTutorial,
  }

}
</script>
