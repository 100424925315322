<template>
  <div>
    <SeparateSections
      titleSectionSeparate="why_buy_title_section"
      subTitleSectionSeparate="why_buy_description_section"
      idSelector="scroll_helper_text_1"
    />


    <!-- <div class="container-content-why-buy container-default">

      <div class="container-chart-currencies-assets">

        <div class="grid-asset-why-buy">
          <div v-for="(i, index) in savedAvaibleAssets" :key="index" class="container-assets-why-buy">
            <div>
              <img :src="require(`../assets/icons/coins_png/${i.currency.symbol}_icon.png`)" alt="" class="default-icon-why-buy-section" >
              <p class="assets-why-buy-font"> {{ i.assignedOverallWeight }}% </p>
            </div>
          </div>
        </div>

      </div>


      <div class="container-chart-why-buy-section">
        <canvas :id="'myChart'+chartId" ></canvas>
              <div class="mt-5 container-multirange">

                <div>
                  <MultiRangeSlider
                    :min="1"
                    :max="60"
                    :minValue="dayBarMinValue"
                    :maxValue="dayBarMaxValue"
                    :labels="yearNames"
                    :step="1"
                    @input="updateDayValues"
                  />
                </div>

              </div>
        <div class="d-flex-responsive-column custom-container-chart-actions">

          <div class="d-flex">
            <h3 class="description-chart-actions"> {{ $t('home.blueTokenChart.chart_label_button') }} </h3>
          </div>

          <div class="d-flex-wrap custom-buttons-actions">
            <button @click="setNewRangeChart('perYear')" class="button-range-chart-why-buy">{{ $t('home.blueTokenChart.year') }}</button>
            <button @click="setNewRangeChart('currentYear')" class="button-range-chart-why-buy">{{ $t('home.blueTokenChart.current_year') }}</button>
            <button @click="setNewRangeChart('all')" class="button-range-chart-why-buy">{{ $t('home.blueTokenChart.all_year') }}</button>
          </div>
      
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chart from 'chart.js/auto'
import moment from 'moment'
import SeparateSections from './SeparateSections.vue'
// import InputRange from './InputRange.vue'
import MultiRangeSlider from './MultiRangeSlider.vue'
import dayjs from 'dayjs'
import ret from 'bluebird/js/release/util'

export default {
  name: 'BlueTokenChart',
  components: {
    SeparateSections,
    // InputRange,
    MultiRangeSlider,
  },
  data: () => ({
    savedAvaibleAssets: [],
    currentLocale: '',
    limitYearRange: 4,
    startDateChartAll: "2018-12-04T00:00:09.0Z",
    endDateChartAll: "2022-06-06T00:00:09.0Z",
    transformedChartDataState: "",
    transformedChartLabelsState: "",
    gradientStrokeState: "",
    gradientFillState: "",
    blueTokenHistoryPrice: [],

    transformedBlueTokenHistoryPrice: [],

    chartLabel: [],

    barMinValue: 0,
    barMaxValue: 100,
    // inputRangeStep: 0,

    chartId: (Math.random() * 100).toFixed(),

    inputLeft: "",
    inputRight: "",
    thumbLeft: "",
    thumbRight: "",
    range: "",

    myChartState: "",
    

    dayBarMinValue: 0,
    dayBarMaxValue: 57,
    // yearNames: "",
    // dayMinCaption: "",
    // dayMaxCaption: "",
    resultTime: "",

    // dayBarMinDate: "",
    // dayBarMaxDate: "",
    
  }),
  methods: {

    downloadFactSheet() {
      
    },

    async getSavedChartInfo() {
      const savedCharInfo = localStorage.getItem('transformSavedChartInfo')
      if (savedCharInfo === null) {
        const responseChartData = await this.$store.dispatch('getCurrenciesHistory', "")
        this.getChartDataAndLabelsData(responseChartData)
        this.blueTokenHistoryPrice = responseChartData
      }
      this.getChartDataAndLabelsData(JSON.parse(savedCharInfo))
      this.blueTokenHistoryPrice = JSON.parse(savedCharInfo)
    },

    async transformChartDate(unformatedDate) {
      const year = new Date(unformatedDate).getFullYear()
        const month = new Date(unformatedDate).getMonth()

        const resultDate = new Date(year, month+1, 0)
        
        const newDay = resultDate.getDate()
        const newMonth = resultDate.getMonth()
        const newYear = resultDate.getFullYear()

        return `${newDay}/${newMonth < 10 ? (`0`+newMonth) : newMonth}/${newYear}`
    },

    async mountBlueTokeHistoryData(tokenHistory) {
      let chartDatas = []

      for(let i = 0; i < tokenHistory[0].history.length; i++) {
        const year = new Date(tokenHistory[0].history[i].at).getFullYear()
        const month = new Date(tokenHistory[0].history[i].at).getMonth()

        const resultDate = new Date(year, month+1, 0)
        
        // const newDay = resultDate.getDate()
        const newMonth = resultDate.getMonth()
        const newYear = resultDate.getFullYear()

        // if (newMonth > 0) {
          chartDatas.push({
            blueTokenValue: tokenHistory[0].history[i].close.toFixed(2),
            date: `${newMonth < 9 ? (`0`+(newMonth+1)) : (newMonth+1)}/${newYear}`
          })
        // }

      }

      // let uniqueChars = [...new Set(chartDatas)];
      const seen = new Set();
      const uniqueChars = chartDatas.filter(el => {
        const duplicate = seen.has(el.date);
        seen.add(el.date);
        return !duplicate;
      });
      // console.log('uniqueChars => ', uniqueChars)
      this.mountBlueTokeHistoryLabelsAndData(uniqueChars)
    },

    async mountBlueTokeHistoryLabelsAndData(allNewData) {
      let chartDatas = []
      let chartLabels = []

      for(let i = 0; i < allNewData.length; i++) {
        chartDatas.push(allNewData[i].blueTokenValue)
        chartLabels.push(allNewData[i].date)
      }



      // this.transformedChartDataState = transformedChartData
      // this.transformedChartLabelsState = transformedChartLabels

      // console.info('chartDatas => ', chartDatas)
      // console.info('chartLabels => ', chartLabels)

      this.transformedChartDataState = chartDatas
      this.transformedChartLabelsState = chartLabels
    },

    formatDateTimeHistory(value){

      // const dateTime = new Date(value)
      // console.log(dateTime)
      // console.log('getTime =>', dateTime.getHours())

      // if (dateTime > 15) {
      //   console.log('date')
      // }
      // console.log(value)
      // const day = new Date(value).getDate()
      // const month = new Date(value).
      // console.log('month =>', month)
      return moment(value).format('DD/MM/YY')
    },

    formatMonthYear(value){
      return moment(value).format('MM/YYYY')
    },
    async setAvaibleCurrencies() {
      const savedCurrencieInfo = localStorage.getItem('savedAvaibleAssets')
      const transformedSsavedCurrencieInfo = JSON.parse(savedCurrencieInfo)

      if (transformedSsavedCurrencieInfo !== null) {
        this.reorganizeCurrenciesAndSet(transformedSsavedCurrencieInfo)

      } else {
        const localSavedAvaibleAssets = await this.assetsPercent
        this.reorganizeCurrenciesAndSet(localSavedAvaibleAssets)
        localStorage.setItem('savedAvaibleAssets', JSON.stringify(this.savedAvaibleAssets))
      }
    },

    reorganizeCurrenciesAndSet(paramCurrencies) {
      let reorganizeCurrencies = []
      reorganizeCurrencies.push(...paramCurrencies)
      reorganizeCurrencies.sort(function(x, y) {
        return y.overallWeight - x.overallWeight;
      })

      this.savedAvaibleAssets = reorganizeCurrencies
    },

    async filterCorrectCurrencies(currencies) {
      const filterCurrencies = currencies.filter(item => { return item.currency.symbol !== 'MBOX' })
      return filterCurrencies
    },

    createChart() {
      // let ctx = document.getElementById('myChart').getContext('2d');
      var ctx = document.getElementById(`${'myChart'+this.chartId}`).getContext("2d");

      var gradientStroke = ctx.createLinearGradient(0, 0, 0, 450);
      gradientStroke.addColorStop(0, '#F2983A');
      // gradientStroke.addColorStop(0, '#F2983A');
      // gradientStroke.addColorStop(1, '#7117DA');
      gradientStroke.addColorStop(1, '#F2983A');

      var gradientFill = ctx.createLinearGradient(0, 0, 0, 450);
      gradientFill.addColorStop(0, "rgba(242, 152, 58, 0.6)");
      gradientFill.addColorStop(1, "rgba(113, 23, 218, 0.6)");

      this.gradientStrokeState = gradientStroke
      this.gradientFillState = gradientFill

      // if(myChart != null){
      //   myChart.destroy();
      // }

    },
    currentSelectLocale () {
      this.currentLocale = this.$i18n.locale
    },

    getChartObjBuild() {
      const customLabelToolTip = (tooltipItems) => {
        return `$${tooltipItems.formattedValue}`
      }

      return {
          type: 'line',
          data: {
              labels: this.transformedChartLabelsState,
              datasets: [{
                label: "",
                borderColor: this.gradientStrokeState,
                pointBorderColor: this.gradientStrokeState,
                pointBackgroundColor: this.gradientStrokeState,
                pointHoverBackgroundColor: this.gradientStrokeState,
                pointHoverBorderColor: this.gradientStrokeState,
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 50,
                pointRadius: 1,
                fill: true,
                backgroundColor: this.gradientFillState,
                borderWidth: 3,
                // data: [101, 120, 150, 170, 180, 170, 160]
                data: this.transformedChartDataState
              }]
          },
          options: {
            tension: 0.5,
            bezierCurve : true,
              elements: {
                line: {
                  // tension: 0.4,
                  borderWidth: 10
                }
              },
              plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                      display: false,
                  },
                  tooltip: {
                    yAlign: 'bottom',
                    displayColors: false,
                    callbacks: {
                      label: customLabelToolTip,
                    }
                  },
                  afterDraw: chart => {
                    if (chart.tooltip._active.length) {
                      let x = chart.tooltip._active[0].element.x;
                      let yAxis = chart.scales.y;
                      let ctx = chart.ctx;
                      ctx.save();
                      ctx.beginPath();
                      ctx.moveTo(x, yAxis.top);
                      ctx.lineTo(x, yAxis.bottom);
                      ctx.lineWidth = 1;
                      ctx.strokeStyle = '#ff0000';
                      ctx.stroke();
                      ctx.restore();
                    }
                  }
              },
              legend: {
                  display: false,
                  // position: "bottom"
              },
              tooltips: {
                  mode: 'single',
                  callbacks: {
                    label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                  }
              },
              scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      color: 'white',
                      display: false,
                      crossAlign: 'far',
                    },
                    grid: {
                      color: 'transparent',
                      borderColor: 'white',
                      tickColor: 'transparent',
                      borderWidth: 5,
                    }
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      padding: 5,
                      color: '#fff',
                      display: true,
                      crossAlign: 'far',
                    },
                    grid: {
                      color: 'transparent',
                      borderColor: 'white',
                      tickColor: 'white',
                      borderWidth: 5,
                    }
                  },
                  yAxes: {
                    display: false,
                    ticks: {
                      min: 1,
                      max: 2,
                      fontColor: "#CCC",
                      fontStyle: "bold",
                      beginAtZero: true,
                      // maxTicksLimit: 5,
                      padding: 0,
                    },
                    gridLines: {
                      drawTicks: false,
                      display: false
                    }
                  },
                  xAxes: {
                    display: false,
                    gridLines: {
                        display: false,
                        // zeroLineColor: "transparent"
                    },
                    ticks: {
                        padding: 0,
                        fontColor: "#CCC",
                        fontStyle: "bold"
                    }
                  }
              },
          }
      }
    },

    buildChartInfo() {
      if (this.myChartState !== '') {
        this.myChartState.destroy()
      }
      var ctx = document.getElementById(`${'myChart'+this.chartId}`).getContext("2d");
      const chartObjBuild = this.getChartObjBuild()
      this.myChartState = new Chart(ctx, chartObjBuild)
    },

    checkDateRange() {
      var currentdate = new Date();
      var datetime = currentdate.getFullYear()+ "-"+ (`${parseInt(currentdate.getMonth()) < 10 ? `0${currentdate.getMonth()}` : currentdate.getMonth() }`) + "-"+currentdate.getDate()+ "T00:00:09.0Z";

      if (parseInt(this.limitYearRange) === 0) {
        this.endDateChartAll = "2018-12-12T00:00:09.0Z"
        // return "2018-12-12T00:00:09.0Z"
      } else if (parseInt(this.limitYearRange) === 1) {
        this.endDateChartAll = "2019-12-12T00:00:09.0Z"
        // return "2019-12-12T00:00:09.0Z"
      } else if (parseInt(this.limitYearRange) === 2) {
        this.endDateChartAll = "2020-12-12T00:00:09.0Z"
        // return "2020-12-12T00:00:09.0Z"
      } else if (parseInt(this.limitYearRange) === 3) {
        this.endDateChartAll = "2021-12-12T00:00:09.0Z"
        // return "2021-12-12T00:00:09.0Z"
      } else if (parseInt(this.limitYearRange) === 4) {
        this.endDateChartAll = datetime
        // return datetime
      }
    },

    clearChart() {
      var newCtx = document.getElementById(`${'myChart'+this.chartId}`)
      if(newCtx){
        try {
          this.myChartState.destroy();

        } catch (e) {
          console.log(e);
        }
        newCtx.remove()
      }

      // this.chartId = (Math.random() * 100).toFixed();

    },
 
    async changeChartRage() {
      this.clearChart()

      const mainChartData = this.blueTokenHistoryPrice[0].history

      const filterForSavedItens = mainChartData.filter((item) => {
        var date = new Date(item.at);
        return date >= new Date(this.startDateChartAll) && date <= new Date(this.endDateChartAll)
      })
      // const responseChartData = await this.$store.dispatch('getCurrenciesHistory', params)
      let params = {
        history: filterForSavedItens,
        pair: 'usdt',
        period: '720',
        sumbols: 'blu',
      };

      // setTimeout(() => {
      const mainContainerDom = document.querySelector('.container-chart-why-buy-section')

      const newCanvas = document.createElement("canvas")

      const newChartId = (Math.random() * 100).toFixed()

      this.chartId = newChartId

      newCanvas.setAttribute("id",`${'myChart'+newChartId}`);
      mainContainerDom.insertBefore(newCanvas, mainContainerDom.firstChild)
      // }, 100)
      this.getChartDataAndLabelsData([params])
      this.buildChartInfo()

    },

    jumpDates(date) {
      
      const paramDay = Number(date.substr(0, date.indexOf('/')))

      if (paramDay === 30) {
        return date
      }

    },

    getChartDataAndLabelsData(blueTokenHistoryPriceParam) {
      var utc = require('dayjs/plugin/utc')
      dayjs.extend(utc)
      if (blueTokenHistoryPriceParam.length > 0) {
        this.mountBlueTokeHistoryData(blueTokenHistoryPriceParam)
          // if (blueTokenHistoryPriceParam[0].history.length > 0) {
            
          //   let transformedChartData = []
          //   let transformedChartLabels = []
          //   for (let i = 0; i < blueTokenHistoryPriceParam[0].history.length; i++) {
          //     const days = this.formatDateTimeHistory(blueTokenHistoryPriceParam[0].history[i].at)
          //     const jumpDays = Number(days.substr(0, days.indexOf('/'))) // get current day
              
          //     const year = new Date(blueTokenHistoryPriceParam[0].history[i].at).getFullYear()
          //     const month = new Date(blueTokenHistoryPriceParam[0].history[i].at).getMonth()

          //     const resultDate = new Date(year, month+1, 0)
          //     const transformResultDate = moment(resultDate).format('DD')

                  
          //     // console.info('transformResultDate =>', parseFloat(transformResultDate))

          //     if (jumpDays === parseFloat(transformResultDate)) {
          //       transformedChartData.push(blueTokenHistoryPriceParam[0].history[i].close.toFixed(2));
          //       transformedChartLabels.push(this.formatMonthYear(blueTokenHistoryPriceParam[0].history[i].at));
          //     }
          //   }

          //   // let allData = []
          //   // transformedChartData.forEach((item) => {
          //   //   allData.push(`$ ${item}`)
          //   // })

          //   this.transformedChartDataState = transformedChartData
          //   this.transformedChartLabelsState = transformedChartLabels
          // }
      }
    },

    checkAndBuild() {
      if (this.transformedChartLabelsState !== "" && this.transformedChartDataState !== "") {
        this.buildChartInfo()
      }
    },

    setNewRangeChart(selectedRange) {
      const finalDate = moment().format()
      this.endDateChartAll = finalDate

      if (selectedRange === 'perYear') {
        const today = moment();
        const initialDate = moment().subtract(1, 'year').isoWeek(today.isoWeek()).isoWeekday(today.isoWeekday()).format();
        this.startDateChartAll = initialDate;

        // this.dayBarMaxValue = 66
        this.dayBarMinValue = 43

      } else if (selectedRange === 'currentYear') {
        const initialDate = moment().startOf('year').format();
        this.startDateChartAll = initialDate;

        // this.dayBarMaxValue = 66
        this.dayBarMinValue = 49

      } else {
        this.startDateChartAll = "2018-12-04T00:00:09.0Z"

        // this.dayBarMaxValue = 66
        this.dayBarMinValue = 0
      }

      this.changeChartRage();
    },

    checkInputRageValue(rangeValueParam) {
      // const currentdate = new Date();
      // const datetime = currentdate.getFullYear()+ "-"+ (`${parseInt(currentdate.getMonth()) < 10 ? `0${currentdate.getMonth()}` : currentdate.getMonth() }`) + "-"+currentdate.getDate()+ "T00:00:09.0Z";
        // console.log('rangeValueParam => ', rangeValueParam)
        if (rangeValueParam >= 0 && rangeValueParam <= 12) {
          
          const mathValue = rangeValueParam
          const month = new Date('2018', `${mathValue > 9 ? mathValue : '0'+mathValue}`).getDate()
          const resultDate = new Date('2018', month+1, 0)
          const transformFinalDate = moment(resultDate).format('YYYY-MM-DD')
          
          // ============================================================
          return `${transformFinalDate}T00:00:09.0Z`
        
        } else if (rangeValueParam >= 13 && rangeValueParam <= 24) {
          
          const mathValue = (rangeValueParam-12)
          const month = new Date('2019', `${mathValue > 9 ? mathValue : '0'+mathValue}`).getMonth()
          const resultDate = new Date('2019',month === 0 ? '12' : month+1, 0)
          const transformFinalDate = moment(resultDate).format('YYYY-MM-DD')
          
          // ============================================================
          return `${transformFinalDate}T00:00:09.0Z`

        } else if (rangeValueParam >= 25 && rangeValueParam <= 36) {

          const mathValue = (rangeValueParam-24)
          const month = new Date('2020', `${mathValue > 9 ? mathValue : '0'+mathValue}`).getMonth()
          const resultDate = new Date('2020', month+1, 0)
          const transformFinalDate = moment(resultDate).format('YYYY-MM-DD')

          // ============================================================
          return `${transformFinalDate}T00:00:09.0Z`
        
        } else if (rangeValueParam >= 37 && rangeValueParam <= 48) {
          
          const mathValue = (rangeValueParam-36)
          const month = new Date('2021', `${mathValue > 9 ? mathValue+1 : '0'+(mathValue+1)}`).getMonth()
          const resultDate = new Date('2021', month+1, 0)
          const transformFinalDate = moment(resultDate).format('YYYY-MM-DD')

          // ============================================================
          return `${transformFinalDate}T00:00:09.0Z`
        
        } else if (rangeValueParam >= 49 && rangeValueParam <= 60) {
          
          const mathValue = (rangeValueParam-48)
          const month = new Date('2022', `${mathValue > 9 ? mathValue : '0'+mathValue}`).getMonth()
          const resultDate = new Date('2022', month, 0)
          const transformFinalDate = moment(resultDate).format('YYYY-MM-DD')
          
          // ============================================================
          return `${transformFinalDate}T00:00:09.0Z`
        }
    },

    updateValues(e) {
      this.endDateChartAll = this.checkInputRageValue(e.maxValue)
      this.startDateChartAll = this.checkInputRageValue(e.minValue)
      this.changeChartRage();
    },

    updateDayValues(e) {
      // console.log('e.maxValue => ', this.checkInputRageValue(e.maxValue))
      // console.log('e.minValue => ', this.checkInputRageValue(e.minValue))

      this.endDateChartAll = this.checkInputRageValue(e.maxValue)
      this.startDateChartAll = this.checkInputRageValue(e.minValue)

      this.dayBarMinValue = e.minValue;
      this.dayBarMaxValue = e.maxValue;
    
      this.changeChartRage();
    },

    // parseDate(str) {
    //   var mdy = str.split('/');
    //   console.info(mdy)
    //   return new Date(mdy[2], mdy[0]-1, mdy[1]);
    // },

    // dateDifference(date2, date1) {
    //     const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    //     // Discard the time and time-zone information.
    //     const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    //     const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    //     return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    // },

    // countCurrentDay() {
    //   const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));


    //   const currentDate = moment().format('YYYY-MM-DD')
    //   console.info( diffDays(new Date(currentDate), new Date('2022-12-31')) )  // 1839
    // }

  },
  created() {
    // setTimeout(() => {
    //   this.createChart()
    // }, 1000)
    this.currentSelectLocale()
  },
  computed: {
    ...mapGetters({
      assetsPercent: 'assetsPercent',
      // blueTokenHistoryPrice: 'blueTokenHistoryPrice',
    }),
    // dayMinCaption() {
    //     let d = new Date();
    //     let dd = new Date("1-Jan-" + d.getFullYear());
    //     dd.setDate(this.dayBarMinValue);
    //     return dd.toString();
    //     // const maxDate = '2018-12-04T00:00:09.0Z'
    //     // const transformMinDate = moment(maxDate).format('DD/MM/YY')
    //     // return transformMinDate.toString()
    //     // this.minCaption = transformMinDate.toString()
    // },
    // dayMaxCaption() {
    //     var currentdate = new Date();
    //     const transformMinDate = moment(currentdate).format('DD/MM/YY')

    //     // this.maxCaption = transformMinDate.toString();
    //     // return transformMinDate.toString();
    // },
    // dayMinCaption() {
    //   let d = new Date();
    //   let dd = new Date("1-Jan-" + d.getFullYear());
    //   dd.setDate(this.dayBarMinValue);
    //   return dd.toString();
    // },
    // dayMaxCaption() {
    //   let d = new Date();
    //   let dd = new Date("1-Jan-" + d.getFullYear());
    //   dd.setDate(this.dayBarMaxValue);
    //   return dd.toString();
    // },
    yearNames() {
      return [
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023"
      ];
    },
  },
  mounted() {
    this.checkDateRange();
    this.getSavedChartInfo()
    this.createChart()
    this.currentSelectLocale()
    // this.getChartDataAndLabelsData()
    if (this.assetsPercent.length > 0) {
      this.setAvaibleCurrencies()
    }
  },
  watch: {
    assetsPercent() {
      if (this.assetsPercent.length > 0) {
        this.setAvaibleCurrencies()
      }
    },
    // blueTokenHistoryPrice() {
    //   this.getChartDataAndLabelsData()
    // },
    limitYearRange() {
      this.changeChartRage()
      // console.info('limitYearRange ===> ', this.limitYearRange)
    },
    transformedChartDataState() {
      this.checkAndBuild()
    },
    transformedChartLabelsState() {
      // console.info('transformedChartLabelsState => ', this.transformedChartLabelsState)
      // if (this.dayBarMaxValue > 335) {

      //   console.info('dayBarMaxValue => ', this.dayBarMaxValue)
      //   this.dayBarMaxValue = 335;
      // }
    },
    dayBarMaxValue() {
      // console.info('dayBarMaxValue: ', this.dayBarMaxValue)
    },
    dayBarMinValue() {
      // console.info('dayBarMinValue: ', this.dayBarMinValue)
    },
    transformedBlueTokenHistoryPrice() {
      console.info('transformedBlueTokenHistoryPrice => ', this.transformedBlueTokenHistoryPrice)
    },
    chartLabel() {
      console.log('chartLabel ====> ', this.chartLabel)
    }
  }
}
</script>
