<template>
  <div>

    <SeparateSections
      titleSectionSeparate="where_blue_midia_title_section"
      subTitleSectionSeparate="where_blue_midia_description_section"
    />

    <!-- <div class="container-dash-separate-section">
      <hr class="default-dash-separate-section ml-a mr-3" />
      <div class="container-default-text-separete-section"> <p class="default-text-separete-section m0"> {{ $t('where_blue_midia_title_section') }} </p> </div>
      <hr class="default-dash-separate-section mr-a ml-3" />
    </div>

    <div class="p10">
      <p class="color-white txt-alg-center font-size-2-5 bold max-50 margin-center mt-5"> {{ $t('where_blue_midia_description_section') }} </p>
    </div> -->

    <div class="container-blue-midia-hexagon">
      <div v-for="(i, index) in [1,2,3,4,5]" :key="index" class="container-hexagon-itens">
        <div class="block-hexagon-itens">
          <span v-html="iconSets.hexagon_white" class="hexagon-white"></span>
          <img :src="require(`../assets/imgs/midia_${index}.png`)" alt="Bloomberg img" class="image-into-hexagon">
        </div>
      </div>
    </div>

    <div class="mt-10">

    </div>

  </div>
</template>
<script>
import SeparateSections from './SeparateSections.vue'

export default {
    name: "HomeMidia",
    components: {
        SeparateSections
    }
}
</script>
