<template>
  <nav>
    <div class="container-switcher-lang">

      <button @click="visibleDropdown = !visibleDropdown" class="button-switcher-lang-header">
        <p class="m0 selectedLocale">{{ currentSelectLocale }}</p>
        <span v-if="visibleDropdown" v-html="iconSets.chevron_up" class="ml-1 default-icon-staking"> </span>
        <span v-if="!visibleDropdown" v-html="iconSets.chevron_down" class="ml-1 default-icon-staking"> </span>
      </button>

        <div class="container-lang-options" :class="!visibleDropdown ? 'not-visible-dropdown-changeLang' : 'visible-dropdown-changeLang' ">
          <p
            v-for="(locale, index) in availableLocales"
            :key="index"
          >
            <a class="pointer" @click="switchLocalePath(locale)">
              <div class="container-lang-text">
                <span> {{ locale }}</span>
                <!-- <img :src="require(`../../assets/icons/flags/${locale}.png`)" alt="">  -->
              </div>
            </a>
          </p>
        </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'LangSwitcher',

  data: () => ({
    visibleDropdown: false,
    flags: ['bandeira-de-portugal.png', 'bandeira-reino-unido.png', 'flag-el-Salvador.png']
  }),

  methods: {
    switchLocalePath(selectedlocale) {
      this.$i18n.locale = selectedlocale
      localStorage.setItem('savedSelecedLocale', selectedlocale)
      this.visibleDropdown = !this.visibleDropdown
      window.location.reload()
    },
    checkSavedLocale() {
      const savedLocale = localStorage.getItem('savedSelecedLocale')
      if (savedLocale) {
        this.$i18n.locale = savedLocale
      }
    }
  },
  created() {
    this.checkSavedLocale()
  },
  computed: {
    availableLocales () {
      // console.info(this.$i18n)
      // return this.$i18n.locale
      return this.$i18n.availableLocales
    },

    currentSelectLocale () {
      return this.$i18n.locale
    },
  },
  watch: {
    visibleDropdown() {
      // console.log('mudou ? ', this.visibleDropdown)
    }
  }
}
</script>
