import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/scss/main.scss'

Vue.config.productionTip = false

import iconSets from './utils/icons'
Vue.use(iconSets)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import money  from 'v-money'
Vue.use(money, {precision: 4})

Vue.use(VueToast);

//Vue.$toast.open({/* options */});
let instance = Vue.$toast.open('You did it!');

// Force dismiss specific toast
instance.dismiss();

Vue.$toast.clear();

new Vue({
  i18n,
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
