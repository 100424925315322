<template>
    <div>
      <SeparateSections
        titleSectionSeparate="home.chartCompare.title"
        subTitleSectionSeparate="home.chartCompare.description"
        idSelector="scroll_helper_text_2"
      />
  
      <div class="container-default-loading" v-if="loadingAssets">
          <Loading :color="'white'"></Loading>
      </div>
  
  
      <div v-else class="container-home-chart-compare">
          <div class="container-side-comparator">
              <div v-for="(i, index) in transformedChartsAssets" :key="index" class="d-flex flex-content-side-comparator">
                  <p class="currency-name-side-comparator"> {{$t(i.text)}}</p>
                  <p class="value-side-comparator" :class="i.newUpdatedPrice < 0 ? 'negative-value-color' : 'positive-value-color'"><span v-if="i.newUpdatedPrice > 0">+</span>{{i.newUpdatedPrice}}%</p>
                  <img :src="require(`../assets/imgs/coins/${i.name}.png`)" alt="currency" class="image-side-comparator">
              </div>
          </div>
          <div class="container-card-compare-chart">
              <div class="card-compare-chart">
                  <canvas :id="'myChart'+chartId" ></canvas>
              </div>
          </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import SeparateSections from './SeparateSections.vue'
  import Loading from './Loading.vue'
  import Chart from 'chart.js/auto';
  import { mapGetters } from 'vuex'
  
  export default {
      components: {
          SeparateSections,
          Loading,
      },
      computed: {
          ...mapGetters({
              bluetokenReturn: 'bluetokenReturn',
          }),
      },
      data:() => ({
          chartId: (Math.random() * 100).toFixed(),
          loadingAssets: false,
          transformedChartsAssets: [],
          chartData: [],
          chartLabel: [],
          chartColors: [],
          newAssetsArray: [],
          myChartState: '',
          compareCoins: [
              {
                  currency: "BLUE",
                  value: +73.45,
                  image: "blueToken",
                  name: 'blueToken',
                  text: 'home_chart_compare.blueToken',
                  color: '#7B17EC'
              },
              {
                  currency: "CRUDE OIL",
                  value: +68.87,
                  image: "OIL",
                  name: "OIL",
                  text: 'home_chart_compare.oil_crud',
                  color: '#222222',
              },
              // {
                  //     currency: "JSE 40",
                  //     value: +19.36,
                  //     image: "SA",
                  //     name: "SA",
                  //     color: '#6DA544',
                  // },
                  {
                  currency: "S&P 500",
                  value: +19.32,
                  image: "EUA",
                  name: "EUA",
                  text: 'home_chart_compare.sp500',
                  color: '#FCB3DC',
              },
              {
                  currency: "BITCOIN",
                  value: +16.21,
                  image: "BTC",
                  name: "BTC",
                  text: 'home_chart_compare.bitcoin',
                  color: '#F9A23A',
              },
              // {
                  //     currency: "TESLA",
                  //     value: +12.86,
                  //     image: "tesla",
                  //     name: "tesla",
                  //     color: '#CC0000'
                  // },
              {
                  currency: "FTSE 100",
                  value: +12.54,
                  image: "UKG",
                  text: 'home_chart_compare.ftse',
                  name: "UKG",
                  color: '#D70027',
              },
              {
                  currency: "STOXX 50",
                  value: +9.99,
                  image: "UK",
                  name: "UK",
                  text: 'home_chart_compare.stoxx',
                  color: '#0152B3',
              },
              {
                  currency: "NASDAQ",
                  value: +8.45,
                  image: "EUA",
                  name: "EUA2",
                  text: 'home_chart_compare.nasdaq',
                  color: '#F96290',
              },
              // {
              //     currency: "IBOVESPA",
              //     value: -1.75,
              //     image: "BR",
              //     name: "BR",
              //     text: 'home_chart_compare.ibovespa',
              //     color: '#6DA544',
              // },
              {
                  currency: "GOLD",
                  value: -2.75,
                  image: "GOLD",
                  name: "GOLD",
                  text: 'home_chart_compare.gold',
                  color: '#F9A23A',
              },
              // {
              //     currency: "HASH11",
              //     value: -25.74,
              //     image: "BR2",
              //     name: "BR2",
              //     text: 'home_chart_compare.hash11',
              //     color: '#6DA544',
              // },
          ],
      }),
      methods: {
          // ==== It's not working yet, because the atribute image ir to large to be parse in stringfy ====
          // async getOldChartData() {
          //     const getSavedChartData = localStorage.getItem('oldChartData')
  
          //     if (getSavedChartData !== null) {
          //         console.info('saved ?')
          //         const transformedData = JSON.parse(getSavedChartData)
          //         this.transformedChartsAssets = transformedData
          //     } else {
          //         this.getAndSetnewStockAssets()
          //     }
          // },
          async getAndSetnewStockAssets() {
              this.loadingAssets = true
  
              this.compareCoins.forEach( async (element) => {
  
                  // if (element.name === 'blueToken') {
                      
                  // }
  
                  const assetReturn = await await this.$store.dispatch('getStockAssetsReturn', element.name)
                  if (assetReturn.data.returnValue !== null) {
  
                      if (assetReturn.data.currency === 'blueToken') {
                          const classBlueImage = new Image(20, 20);
                          classBlueImage.src = require(`../assets/imgs/coins/${element.name}.png`);
                          element.image = classBlueImage
                          element.newUpdatedPrice = parseFloat(assetReturn.data.returnValue)
  
                          this.newAssetsArray.push(element)
                      } else {
                          
                          const classBlueImage = new Image(30, 30);
                          classBlueImage.src = require(`../assets/imgs/coins/${element.name}.png`);
                          
                          element.image = classBlueImage
                          element.newUpdatedPrice = parseFloat(assetReturn.data.returnValue)
                          
                          this.newAssetsArray.push(element)
                      }
                  }
              })
  
          },
          getChartDataObj() {
              let barColors = this.chartColors;
              let imageArrays = this.transformedChartsAssets;
  
              const barAvatar = {
                  id: 'barAvatar',
                  afterDatasetDraw(chart, args, options) {
                      const { ctx, chartArea: {top, bottom, left, right, width, height},
                          scales: {x, y} } = chart;
                      ctx.save();
  
  
                      var currentWindowSize = 0;
                      var iconHeight = 0;
  
                      if (window.innerWidth < 1000) {
                          currentWindowSize = 20;
                          iconHeight = 10;
  
                      } else {
                          currentWindowSize = 45;
                          iconHeight = 18;
                      }
                      imageArrays.forEach((item, index) => {
                          ctx.drawImage(item.image, x.getPixelForValue(index) - (currentWindowSize / 2), y.getPixelForValue(item.newUpdatedPrice) - iconHeight, currentWindowSize, currentWindowSize);
                      })
                  }
              }
  
              return {
                  type: "bar",
                  data: {
                      labels: this.chartLabel,
                      // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                      datasets: [{
                          label: '',
                          backgroundColor: barColors,
                          // data: [12, 19, 3, 5, 2, 3]
                          data: this.chartData
                      }]
                  },
                  options: {
                      scales: {
                          x: {
                              beginAtZero: true,
                              ticks: {
                                  color: 'white',
                                  display: false,
                                  crossAlign: 'far',
                              },
                          },
                          y: {
                              beginAtZero: true,
                              ticks: {
                                  callback: function(value, index, values) {
                                      return value + ' %';
                                  },
                              }
                          },
                          xAxes: {
                              display: false,
                              ticks: {
                                  display: false,
                              }
                          },
                          yAxes: {
                              display: false,
                              ticks: {
                                  display: false,
                              }
                          }
                      },
                      plugins : {
                          legend: {
                              display: false,
                              fullSize: false
                          },
                          datalabels: {
                              display: false,
                          },
                          tooltip: {
                              yAlign: 'bottom',
                              displayColors: false,
                          }
                      },
                      legend: {
                          display: false,
                      },
                  },
                  plugins: [barAvatar]
              }
          },
  
          createChart() {
              if (this.myChartState !== '') {
                  this.myChartState.destroy()
              } 
              
              let domCtx = document.querySelector(`${'#myChart'+this.chartId}`)
  
              if (domCtx !== null) {
                  var ctx = document.querySelector(`${'#myChart'+this.chartId}`).getContext("2d");
                  this.loadingAssets = false
                  const chartObjBuild = this.getChartDataObj()
                  this.myChartState = new Chart(ctx, chartObjBuild)
              }
  
          },
          filterChartInformation() {
              this.chartData = this.transformedChartsAssets.map(item => { return item.newUpdatedPrice })
              this.chartLabel = this.transformedChartsAssets.map(item => { return item.currency })
              this.chartColors = this.transformedChartsAssets.map(item => { return item.color })
          },
          async reorganizeDataChart() {
               let reorganizeCurrencies = []
  
              reorganizeCurrencies.push(...this.newAssetsArray)
              reorganizeCurrencies.sort(function(x, y) {
                  return y.newUpdatedPrice - x.newUpdatedPrice;
              })
              this.transformedChartsAssets = reorganizeCurrencies
          },
          checkDataAndBuildChart() {
              // console.log('chartData: ', this.chartData.length);
              // console.log('chartLabel: ', this.chartLabel.length);
              // console.log('chartColors: ', this.chartColors.length);
  
              if (this.chartData.length > 0 && this.chartLabel.length > 0 && this.chartColors.length > 0) {
                  setTimeout(() => {
                    this.createChart()
                  }, 1000)
              }       
          }
      },
      created() {
          // this.getOldChartData()
          this.getAndSetnewStockAssets()
      },
      watch: {
          newAssetsArray() {
              // console.log('newAssetsArray: ', this.newAssetsArray.length)
              // console.log('compare: ', this.compareCoins.length)
              if ((this.newAssetsArray.length+1) >= this.compareCoins.length) {
                  this.loadingAssets = false
              }
              this.reorganizeDataChart()
          },
          transformedChartsAssets() {
              // console.log('transformedChartsAssets: ', this.transformedChartsAssets.length)
              // console.log('compareCoins: ', this.compareCoins.length)
              if ((this.transformedChartsAssets.length+1) >= this.compareCoins.length) {
                  localStorage.setItem('oldChartData', JSON.stringify(this.transformedChartsAssets))
                  this.filterChartInformation()
              }
          },
          chartData() {
              this.checkDataAndBuildChart()
          },
          chartLabel() {
              this.checkDataAndBuildChart()
          },
          chartColors() {
              this.checkDataAndBuildChart()
          },
          bluetokenReturn() {
              console.log('bluetokenReturn into Chart compare => ', this.bluetokenReturn)
          }
      }
  }
  </script>
  
  <style>
  
  </style>