<template>
  <div>

    <SeparateSections
      titleSectionSeparate="staking.title_section"
      subTitleSectionSeparate="staking.description_section"
      idSelector="scroll_helper_text_3"
    />

    <div class="container-blue-midia-hexagon">
      <div v-for="(i, index) in avaibleCurrencies" :key="index" class="container-hexagon-itens-staking">
        <div class="block-hexagon-itens">
          <span v-html="iconSets.hexagon_darken_blue" class="hexagon-darken-blue"></span>
          <div class="container-icons-infos-staking">
            <!-- <span v-html="`${iconSets.coins[`${i.currency.symbol}_icon`]}`" class="icon-staking"> </span> -->
            <img :src="require(`../assets/icons/coins_png/${i.currency.symbol}_icon.png`)" class="default-icon-staking" alt="">
            <p class="staking-currency-symbol-text">{{i.currency.symbol}}</p>
            <p v-if="i.assetPercent" class="staking-currency-symbol-percent">{{parseFloat(i.assetPercent).toFixed(2)}}%</p>
          </div>
          <!-- <img :src="require(`../../assets/imgs/midia_${index}.png`)" alt="Bloomberg img" class="image-into-hexagon"> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SeparateSections from './SeparateSections.vue'

export default {
  components: {
    SeparateSections,
  },
  data:() => ({
    avaibleCurrencies: [],
    newAvaibleCurrencies: [],
    resultAvaibleCurrencies: [],
  }),
  computed: {
    ...mapGetters({
      assetsPercentState: 'assetsPercent',
    }),
  },
  created() {
    this.setAvailableCurrencies()
  },
  methods: {

    async getAssetsPercent(assetSerch, allCurrencies) {
      const assetPercent = allCurrencies.filter((asset) => asset.currency.symbol === assetSerch)

      if (assetPercent.length > 0) {
        if (assetPercent[0].apyPercent !== undefined) {
          return assetPercent[0].apyPercent
        }
        return 0
      }

      return 0
    },

    async addStakingPercent(assetInfo, allCurrencies) {
      const getassetPercent = await this.getAssetsPercent(assetInfo.currency.symbol, allCurrencies)
      assetInfo.assetPercent = getassetPercent

      this.avaibleCurrencies.push(assetInfo)
    },

    // async manuallyAddStakingPercent(assetInfo) {

    //   if (assetInfo.currency.symbol === 'AVAX') {
    //     assetInfo.assetPercent = this.getAssetsPercent('AVAX') || '6.28%'
    //     this.newAvaibleCurrencies.push(assetInfo)
      
    //   } 
    //   else if (assetInfo.currency.symbol === 'MATIC') {
    //     assetInfo.assetPercent = this.getAssetsPercent('MATIC') || '5.2%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'CAKE') {
    //     assetInfo.assetPercent = this.getAssetsPercent('CAKE') || '8.79%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'SOL') {
    //     assetInfo.assetPercent = this.getAssetsPercent('SOL') || '7.5%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'ETH') {
    //     assetInfo.assetPercent = '4.05%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'ADA') {
    //     assetInfo.assetPercent = '6.76%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'ALGO') {
    //     assetInfo.assetPercent = '7.45%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    //   else if (assetInfo.currency.symbol === 'DOT') {
    //     assetInfo.assetPercent = '13.95%'
    //     this.newAvaibleCurrencies.push(assetInfo)

    //   }
    // },

    async setAvailableCurrencies() {

      const allCurrencies = await this.$store.dispatch('getCurrencies')

      // console.log('allCurrencies', allCurrencies)

      // const allCurrenciesAvailable = await allCurrencies.filter((item) => { 
      //   return item.currency.symbol !== 'XRP' && 
      //   item.currency.symbol !== 'TUSD' &&
      //   item.currency.symbol !== 'LINK' &&
      //   item.currency.symbol !== 'BTC' &&
      //   item.currency.symbol !== 'BNB' &&
      //   item.currency.symbol !== 'CRV' &&
      //   item.currency.symbol !== 'LUNA' &&
      //   item.currency.symbol !== 'MBOX' &&
      //   item.currency.symbol !== 'TRX' 
      // })


      // allCurrenciesAvailable.map((item) => {this.manuallyAddStakingPercent(item)  })
      
      allCurrencies.forEach(element => {
        if (element.apyPercent !== undefined) {
          this.addStakingPercent(element, allCurrencies)
        }
      });

      // this.avaibleCurrencies = allCurrenciesAvailable
    },
  },
  watch: {
    // assetsPercentState() {
    //   if (this.assetsPercentState.length > 0) {
    //     this.setAvailableCurrencies(this.assetsPercentState)
    //   }
    // },
    resultAvaibleCurrencies() {
      console.info('resultAvaibleCurrencies ===> ', this.resultAvaibleCurrencies)
    }
  }
}
</script>
