<template>
  <div id="app">
    <div id="nav">
      <Header />
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> -->
    </div>
    <CookiesBanner />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Header from './components/HeaderHome/index.vue'
import Footer from './components/Footer.vue'
// import { mapGetters } from 'vuex'
import CookiesBanner from './components/CookiesBanner.vue'

export default {
  name: "app",
  data:() => ({
    startDateChartAll: "2018-12-04T00:00:09.0Z",
    endDateChartAll: "2022-09-06T00:00:09.0Z",
  }),
  components: {
    Header,
    Footer,
    CookiesBanner,
  },
  mounted(){
    this.getAllAssetApi()
    this.requestChartData()
    this.checkAppRoute()
  },
  methods: {
    getAllAssetApi() {
      this.$store.dispatch('getAllAssets')
      this.$store.dispatch('getAssetsPercent')
    },
    async checkAppRoute() {
      if (window.location.pathname === '/') {
        this.$router.push('/homepage')
      }
    },
    async requestChartData() {

      // let date = new Date();
      // let endHour = this.sharedActions.formatDateTimeHistory(date);
      // date.setFullYear(2018);
      // let startHour = this.sharedActions.formatDateTimeHistory(
      //   this.startDateChartAll
      // );

      let params = {
        start: this.startDateChartAll,
        end: this.endDateChartAll,
        pair: "usdt",
        period: 720,
        sumbols: "blu",
      };

     await this.$store.dispatch('getCurrenciesHistory', params)
      
    },
    async getBlueReturn() {
      try {
        await this.$store.dispatch('getBlueReturn')
      } catch(err) {
        console.log(err)
      }
    },
  },
  created() {
    this.getBlueReturn()
    this.getAllAssetApi()
    this.requestChartData()
  },
  computed: {
    // ...mapGetters({
    //   currencies: 'currencies',
    // }),
  },
}
</script>
<style lang="scss">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
