<template>
  <div>

    <div class="container-filds-form">
      <div class="d-flex-responsive-column">
        <input
          v-for="(i, index) in initialFieldsProp" :key="index"
          class="default-input-form" type="text"
          :placeholder="$t(`member_page.form_input_${index}`)"
          v-model="initialInputs[index]"
        >
      </div>

      <div v-if="optionalFields">
          <input
          v-for="(i, index) in optionalFieldsProp" :key="index"
          class="default-input-form" type="text"
          :placeholder="$t(`input_default_subject_placeholder`)"
          v-model="optionalFields[index]"
        >
      </div>

      <div class="d-flex-responsive-column m-20">
        <h3 class="mr-5"> {{ $t('default_form_label_client') }} </h3>
        <div class="d-flex">
          <div class="d-flex mr-3">
            <input
              name="default_radio"
              type="radio" 
              for="yes"
              id="yes-radio"
              class="color-white" 
              @click="changeCheckBoxState('yes')"
            ><p class="checkbox-text-default-form"> {{ $t('default_form_checkbox_yes') }} </p>
          </div>
          <div class="d-flex mr-3">
            <input
              name="default_radio"
              @click="changeCheckBoxState('no')"
              type="radio"
              for="no"
              id="yes-radio"
              class="color-white" 
            ><p class="checkbox-text-default-form"> {{ $t('default_form_checkbox_no') }} </p>
          </div>
        </div>
      </div>

      <div>
        <textarea class="default-textarea-form" name="" id="" cols="30" rows="10" :placeholder="$t('default_form_placeholder')" v-model="texArea"></textarea>
      </div>

      <p class="success-txt" v-if="messageType === 'success' "> {{$t('email_sent_success')}} </p>
      <p class="error-txt" v-if="messageType === 'error' "> {{$t('email_sent_error')}} </p>
      <p v-if="messageType === 'warning-null-form' "> {{$t('email_form_empty')}} </p>

      <button class="default-white-button bold custom-button-form" @click="submitForm"> {{ $t(textButton) }} </button>
    </div>


  </div>
</template>
<script>
export default {
  props: ['initialFieldsProp', 'optionalFieldsProp', 'endpointPath', 'textButton', 'sourcePageProp'],
  name: 'DefaultForm',
  data: () => ({
    // initialInputs: {},
    initialInputs: [],
    texArea: '',
    optionalFields: [],
    messageType: '',
    checkBoxAnswrer: ''
  }),
  methods: {
    checkFields() {
      if (
        this.initialInputs.name === '' ||
        this.initialInputs.email === '' ||
        this.optionalFields.subject === '' ||
        this.texArea === ''
      ) {
       this.messageType = 'warning-null-form' 
      } else {
        this.messageType = ''
      }
    },
    changeCheckBoxState(clickedItemParam) {
      this.checkBoxAnswrer = clickedItemParam
    },
    async submitForm() {
      this.checkFields()
      // const formObj = {
      //   initialInputs: this.initialInputs,
      //   optionalFields: this.optionalFields,
      //   texArea: this.texArea,
      // }

      const body = {
        name: this.initialInputs.name,
        email: this.initialInputs.email,
        subject: this.optionalFields.subject,
        message: this.texArea,
        checkBoxAnswrer: this.checkBoxAnswrer,
        sourcePage: this.sourcePageProp
      }

      if (this.messageType !== 'warning-null-form') {
        const sendEmailResp = await this.$store.dispatch("sendEmail", body)
        if (sendEmailResp.status === 200) {
          this.messageType = 'success'
          this.$toast.open({
            message: this.$t('email_sent_success'),
            type: 'success',
            position: 'top',
            duration: 5000,
          });

          return
        }

          this.$toast.open({
            message: this.$t('email_sent_success'),
            type: 'error',
            position: 'top',
            duration: 5000,
          });
        this.messageType = 'error'
      }
    
    
    
    }
  },
  watch: {
    messageType() {
      if (this.messageType !== '') {
        setTimeout(() => {
          this.messageType = ''
        }, 5000)
      }
    }
  }
}
</script>
