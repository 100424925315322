<template>
  <div>

  <div class="container-knowMoreSection-all-itens">

    <div class="m0 container-knowMoreSection-top">
      <div class="cutom-container-knowMoreSection-top container-default">
        <div class="text-block-knowMoreSection-top">
          <h1 class="text-description-knowMoreSection">{{ $t('home.indicate_description_0_know_section') }}</h1>
        </div>

        <div class="custom-button-knowMoreSection">
          <div class="block-button-buy-now-know-more-home">
            <!-- <button class="default-yellow-button" @click="redirectToSignup()">{{ $t('home.buy_now') }}</button> -->
          </div>
        </div>

        <div class="container-first-image-know-more-home">
            <!-- src="../assets/imgs/bg_people_home_01_big.png"  -->
          <img 
            :src="widthScreen < 700 ? `${require('../assets/imgs/home_know_more_img_01_shadow.png')}` : `${require('../assets/imgs/bg_people_home_01_big.png')}`" 
            alt="" 
            class="bg-people-home-01"
          >
        </div>
      </div>
    </div>

    <div class="container-knowMoreSection-bottom container-default">
      <div class="container-details-text-knowMoreSection">
        <div >
          <h1 class="custom-title-knowMoreSection-bottom">
            {{ $t('home.indicate_description_1_know_section') }}
            <span class="color-yel">{{ $t('home.indicate_description_2_know_section') }}</span>
            {{ $t('home.indicate_description_3_know_section') }}
          </h1>
          <p class="small-txt-description-know-section">{{ $t('txt_indicate_know_section') }} </p>
          <!-- <button @click="redirectToPool" class="default-yellow-button know-more-button-knowMoreSection">{{ $t('txt_button_2_know_section') }}</button> -->
        </div>
      </div>

      <div class="container-second-image-know-more-home">
          <!-- src="../assets/imgs/bg_people_home_02_big.png"  -->
        <img 
          :src="widthScreen < 700 ? `${require('../assets/imgs/home_know_more_img_02_shadow.png')}` : `${require('../assets/imgs/bg_people_home_02_big.png')}`" 
          alt="" class="bg-people-home-02">
      </div>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: 'KnowMoreSection',
  data:() => ({
    widthScreen: 0
  }),
  methods: {
    getWithScreen() {
      this.widthScreen = window.innerWidth;
    },
    redirectToSignup() {
      // window.location.href = 'https://bluetoken.io/app/signup';
       window.open('https://bluetoken.io/app/signup', '_blank');
    },
    redirectToPool() {
      this.$router.push('/pool');
    }
  },
  created(){
    this.getWithScreen();
  },
}
</script>
