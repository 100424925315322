<template>
  <div>
    <InitialBanner
      image="bg_contact_page_banner.png"
      imageTitle="contact_page.banner_title"
    />

    <SeparateSections
      titleSectionSeparate="contact_page.title_section"
      subTitleSectionSeparate="contact_page.content_section"
    />

    <DefaultForm
      :initialFieldsProp="initialFieldsForm"
      :optionalFieldsProp="optionalFieldsForm"
      :endpointPath="endpointPath"
      textButton="default_form_button_send"
      sourcePageProp="Feedback"
    />

  </div>
</template>
<script>
import InitialBanner from '../components/InitialBanner.vue'
import SeparateSections from '../components/SeparateSections.vue'
import DefaultForm from '../components/DefaultForm.vue'

export default {
  name: 'Contact',
  components: {
    InitialBanner,
    SeparateSections,
    DefaultForm,
  },
  data: () => ({
    // initialInputs: {},
    endpointPath: 'contact',
    initialFieldsForm: {
      name: '',
      email: '',
    },

    optionalFieldsForm: {
      subject: '',
    },

  }),
}
</script>
