<template>
  <div>
    <div class="scroll-helper">
        <div v-for="(i, index) in scrollHelperData" :key="index" class="container-scrollheper">
            <p class="text-scroll-helper" :class="selectionScrollHelper === index ? 'active-text-animation' : 'unactive-text-animation' " v-html="$t(i)"></p>
            <button @click="scrollToSection(i)" @mouseover="activeText(index)" @mouseleave="activeText(10)" class="dot-btn-helper"> </button>
        </div>
        <!-- <button class="button-buy-now-header ml-a mt-1" @click="redirectSignup()">{{ $t('header.buy_now_link_header_menu') }}</button> -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'ScrollHelper',
    data:() => ({
        scrollHelperData: [
            'scroll_helper_text_1', 
            // 'scroll_helper_text_2', 
            // 'scroll_helper_text_3', 
            // 'scroll_helper_text_4', 
            'scroll_helper_text_5', 
            'scroll_helper_text_6'
        ],
        selectionScrollHelper: 10
    }),
    methods: {
        activeText(index) {
            this.selectionScrollHelper = index
        },
        scrollToSection(i) {
            let element = document.querySelector("#" + i);
            window.scroll({
                behavior: "smooth",
                left: 0,
                top: element.offsetTop
            });
        },
        redirectSignup() {
            window.location.href = 'https://bluetoken.io/app/signup'
        }
    }
}
</script>

<style>

</style>