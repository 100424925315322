<template>
  <div
    style="
      display: flex;
      justify-content: center;
      background: #fcfcfc;
      height: 100%;
      flex-direction: column;
    "
  >
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <div class="lds-ring" :class="color === 'white' ? 'lds-ring-white' : '' " >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['color']
}
</script>

<style>
.lds-ring-white div {
  border: 4px solid rgb(255, 255, 255) !important;
  border-color: rgb(255, 255, 255) transparent transparent transparent !important;
}


.lds-ring {
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  border: 4px solid rgb(66, 75, 125);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(66, 75, 125) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>