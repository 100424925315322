<template>
  <div>
    <div class="container-home-banner-info">
      <div class="cover-color"></div>
      <div class="container-blocks-home-banner container-default">

        <div class="container-home-banner-col left-content-home-banner">
          <div>
            <h1 class="title-home-banner"> {{ $t('title_home_banner') }} </h1>
            <p class="description-home-banner"> {{ $t('description_home_banner') }} </p>
          </div>
        </div>

        <!-- <div class="container-home-banner-col right-content-home-banner">
          <div>

          <div class="return-percent-home-page">
            <h1 class="return-value-home-banner" v-if="currencies.length > 0">{{ blueReturn }}%</h1>
            <span v-html="iconSets.chart_header" class="icon-home-banner"> </span>
          </div>
          <div>
            <h1 class="subtitle-home-banner">{{ $t('accumulated_banner_home') }} <span class="color-yel"> {{ $t('since_dec_2018') }} </span></h1>
            <div>
              <div class="container-blue-value-home-banner">
                <h5 class="m0" v-if="blueCurrentPrice > 0">1 BLUE = {{ blueCurrentPrice.toFixed(2) }} USDT</h5>
              </div>
              <div class="container-blue-value-message-home-banner">
                <p class="m0 color-blue-darken custom-label-live-price">{{ $t('live_price_home_banner') }}</p>
                <span class="red-dot"></span>
              </div>
            </div>
          </div>

          </div>
        </div> -->

      </div>
    </div>

    <!-- <div class="bg_home">  </div> -->
  </div>
</template>

<script>
import ret from 'bluebird/js/release/util';
import { mapGetters } from 'vuex'

export default {
  name: 'HomeBanner',
  props: {
    msg: String
  },
  data:() => ({
    bluFirstPrice: 39.57,
    blueCurrentPrice: 0,
    blueReturn: "",
  }),
  methods: {
    getBluePriceReturn() {

      const filterBlueprice = this.currencies.filter((item) => { return item.symbol === 'BLU' })

      if (filterBlueprice.length > 0) {
        let result;
        result = ((filterBlueprice[0].value - this.bluFirstPrice) / this.bluFirstPrice) * 100;

        if (result > 0) {
          return `+${result.toFixed(2)}`;
        } else if (result < 0) {
          return `-${result.toFixed(2)}`;
        } else {
          return "--";
        }
      }
    },

    async getPercentChangeAll() {
      const allBlueDates = await this.$store.dispatch('getStockAssetsReturn', 'blueToken')
      if (parseFloat(allBlueDates.data.returnValue) > 0) {
        this.blueReturn = `+${allBlueDates.data.returnValue}`

      } else {
        this.blueReturn = `${allBlueDates.data.returnValue}`
      }
    },
    
    getCurrentBluePrice() {
      const filterBlueprice = this.currencies.filter((item) => { return item.symbol === 'BLU' })
      
      if (filterBlueprice.length > 0) {
        this.blueCurrentPrice = filterBlueprice[0].value;
      }
      
    }
  },
  mounted() {
    // this.getBluePriceReturn();
    this.getPercentChangeAll();
  },
  created() {
    this.getCurrentBluePrice();
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies',
      bluetokenReturn: 'bluetokenReturn',
    }),
  },
  watch: {
    currencies() {
      if (this.currencies.length > 0) {
        this.getCurrentBluePrice();
        // this.getBluePriceReturn();
      }
    },

    // bluetokenReturn() {
    //   console.log('bluetokenReturn into Home Banner => ', this.bluetokenReturn)

    //   if (this.bluetokenReturn) {
    //     this.blueReturn = this.bluetokenReturn.blueTokenReturn
    //   }
    // }
  }
}
</script>