<template>
  <div class="container-midiaLinks">
            <a 
                v-for="(i, index) in footerIcons" 
                :key="index" 
                :href="i.link"
                target="_blank"
            >
                <span v-html="iconSets[i.icon]" class="default-icon-footer"> </span>
            </a>
  </div>
</template>

<script>
export default {
    data:() => ({
        footerIcons: [
            {
                icon: 'linkedin',
                link: 'http://linkedin.com/company/bluetoken',
            },
            {
                icon: 'instagram',
                link: 'https://www.instagram.com/bluetoken.io/',
            },
            {
                icon: 'youtube',
                link: 'https://www.youtube.com/bluetoken',
            },
            {
                icon: 'twitter',
                link: 'https://twitter.com/bluetoken_io',
            },
            // {
            //     icon: 'facebook',
            //     link: 'https://www.facebook.com/bluedigitaltoken',
            // },
            // {
            //     icon: 'spotify',
            //     link: 'https://open.spotify.com/show/0dwfQkTrTW9jV5BP0Wluuz?si=68d24f47e95a451b',
            // },
        ]
    })
}
</script>

<style>

</style>