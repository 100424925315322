<template>
  <div>
    <InitialBanner
      image="bg_banner_faq_page_1.png"
      imageTitle="faq.banner_title"
    />
    <div class="faq-bg-page">
      <div class="container-default">
        <FaqContent />
      </div>
    </div>
  </div>
</template>
<script>

import InitialBanner from '../components/InitialBanner.vue'
import FaqContent from '../components/FaqContent.vue'

export default {
  name: 'Faqs',
  components: {
    InitialBanner,
    FaqContent,
  }
}
</script>
