import en from './locales/en.js'
// import pt from './locales/pt.js'
import es from './locales/es.js'

import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)

export default new VueI18n({
    locale: 'en',
    messages: {
        en: en,
        // pt: pt,
        es:es
    }
})