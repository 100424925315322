<template>
  <div>
    <div class="container-hexagon-blue-tutorial-home mt-20">
      <span v-if="calledPage === 'home' " class="bg_hexagon_blue"></span>
      <!-- <span v-html="iconSets.home_hexagon_blue_tutorial_section" class="home_hexagon_blue_tutorial_section"></span> -->

      <div v-if="calledPage === 'faq' " class="container-tutorialfaq-page">
        <img src="../assets/imgs/token_player_faq_page.png" alt="" class="bg-token-faq-page">
      </div>

      <div class="container-itens-hexagon-blue">

        <div class="player-tutorial-home-section">
          <iframe v-if=" getLocale() === 'es' " src="https://www.youtube.com/embed/8WuEtto9adA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe v-if=" getLocale() === 'en' " src="https://www.youtube.com/embed/eS3ZPDBgsus" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe v-if=" getLocale() === 'pt' " src="https://www.youtube.com/embed/71LsVvQEiYo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="container-button-tutorial-home">
          <button class="button-tutorial-home default-white-button" @click="redirectToTutorial()">
            <span v-html="iconSets.youtube"></span>
            <p class="bold color-purple"> {{ $t('tutorials_home.button_watch_more') }} </p>
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name:"BlueTutorial",
  props: ['calledPage'],
  methods: {
    redirectToTutorial() {
      if (this.getLocale() === 'pt') {
        window.open('https://youtube.com/playlist?list=PL7vxL5_tbHIhllDlERB5qo0UEE0fiuGSN', '_blank');
      } 
      if (this.getLocale() === 'en') {
        window.open('https://youtube.com/playlist?list=PL7vxL5_tbHIhqRmjjAupw1Ryrc_3R7kJh', '_blank');
      }
      if (this.getLocale() === 'es') {
        window.open('https://youtube.com/playlist?list=PL7vxL5_tbHIgTaIt25mwfCX5d_HuJms82', '_blank');
      }
    },
    getLocale() {
      return this.$i18n.locale
    }
  }
}
</script>