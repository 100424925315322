export default {
  welcome_external_file: "Welcome to the redesign-blue-web",

  here: "here",

  cookie_modal_text: `This site uses cookies to improvise your experience while you browse.`,
  learn_more_button: `Learn more.`,

  months: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  },

  download: `Download`,

  email_sent_success: `Success in sending the email!`,
  email_sent_error: `Error sending email!`,
  email_form_empty: `Required Fill in all fields!`,

  last_month_winners: "Last month winners",
  last_month_blue_total: "Blue pool has paid a total of",

  header: {
    welcome_link_header_menu: "Be a Representative",
    access_link_header_menu: "Access your wallet",
    blue_club_link_header_menu: "Blue Club",
    faq_link_header_menu: "FAQs",
    bluePool_link_menu: "Blue Pool",
    blogs_link_header_menu: "Blog",
    buy_now_link_header_menu: "Buy now",
    contact_link_header_menu: "Contact",
  },

  scroll_helper_text_1: `Why to buy?`,
  scroll_helper_text_2: `Compare BLUE`,
  scroll_helper_text_3: `Staking`,
  scroll_helper_text_4: `Investment Calculator`,
  scroll_helper_text_5: `Do you still have doubts?`,
  scroll_helper_text_6: `Tutorials`,

  accumulated_banner_home: 'Accumulated, ',
  since_dec_2018: `since dec. 2018`,
  why_buy_title_section: "Why to buy?",
  why_buy_description_section: "Blue Token allows you to hold several cryptocurrencies at once by holding just one token, providing users convenience and diversification.",
  title_home_banner: "What’s Blue Token?",
  description_home_banner: "Blue Token is the first tokenized smart crypto basket, giving you access to the best cryptocurrencies on the market.",
  txt_button_1_know_section: "Buy Now",
  txt_indicate_know_section: "Every time your referrals purchase BLUE tokens, they earn a bonus and you earn a reward, paid in BLUE.",
  txt_button_2_know_section: "Learn More",
  where_blue_midia_title_section: "Where are we in the media?",
  where_blue_midia_description_section: "We are Reference",
  live_price_home_banner: `Live Price`,

  default_form_label_client: "Are you a Blue Token customer?",
  default_form_checkbox_yes: "Yes",
  default_form_checkbox_no: "No",
  default_form_placeholder: "Type a message",
  default_form_button_send: "Send",

  home_chart_compare: {
    blueToken: 'BLUE',
    oil_crud: 'CRUDE OIL',
    sp500: 'S&P 500',
    bitcoin: 'BITCOIN',
    ftse: 'FTSE 100',
    stoxx: 'STOXX 50',
    nasdaq: 'NASDAQ',
    ibovespa: 'IBOVESPA',
    gold: 'GOLD',
    hash11: 'HASH11',
  },

  home: {

    fact_sheet_download_button:`Fact Sheet`,

    indicate_description_0_know_section: "We help you optimize your cryptocurrency selection.",
    indicate_description_1_know_section: "Refer a friend to Blue Token and",
    indicate_description_2_know_section: "earn rewards",
    indicate_description_3_know_section: "when they purchase tokens.",

    blueTokenChart: {
      chart_label_button: `How do you want to see the graph:`,
      year: `1 Year`,
      current_year: `Year - to - Date`,
      all_year: `All Time`,
    },

    buy_now: `Buy Now`,

    chartCompare: {
      title: "Compare BLUE",
      description: `BLUE vs. <br> (USD adjusted, All time)`,
    },

    calcSection: {

      calc_title_section: "Investment Calculator",
      calc_description_section: "How much and for how long to invest",

      input_avaible_capital: "Available Capital",
      input_tolerance: "Tolerance",
      input_tolerance_0:`Conservative`,
      input_tolerance_1:`Moderate`,
      input_tolerance_2:`Aggressive`,
      input_initial_capital: `Initial Capital`,
      input_initial_capital_warning: "% of availble capital",
      input_monthly_contribution: "Monthly Contribution",
      select_goal: "Goal",
      input_goal_option_1: "New Car",
      goal_option_1_calc_result: "goal to buy a new car",
      input_goal_option_2: "Start a Business",
      goal_option_2_calc_result: "goal to start a business",
      input_goal_option_3: "New Home",
      goal_option_3_calc_result: "goal to buy a new home",
      input_goal_option_4: "School",
      goal_option_4_calc_result: "goal to pay for school",
      input_goal_option_5: "Other",
      goal_option_5_calc_result: "other goal",
      almost_there_negative_calc_result: "Almost there! Change inputs to get to your target.",
      input_target_value: "Target Value",
      input_invesment_horizon: "Invesment Horizon",

      year_range_input: `Year`,
      years_range_input: `Years`,

      congratsMessageCalc: `Congratulations! Your `,
      congratsMessageCalcFinal: `is attainable`,
    
      calc_result_title: `Result`,
      total_invested: `Total Invested: `,
      future_Value: `Future Value: `,
      gain: `Gain: `,
      calc_footer_message: `The result shows an estimate of potential earnings based on our past performance.`,
      calc_footer_description: `This calculator was created for presentation purposes and may not be copied, used, or disclosed as investment advice.`,
      calc_button: `Calculate`
    }
  },

  staking: {
    title_section: "Staking",
    description_section: "Cryptocurrencies we are staking and their average annual rewards.",
  },

  // calc: {
  //   title_section: "Investment Calculator",
  //   description_section: "How much and for how long to invest",
  // },

  support_organization: {
    title: "Blue Token is a proud supporter of nonprofits like BitGive and Blockchain for Humanity",
    description: "A percentage of our sales fees is donated to Bitgive and Blockchain for Humanity.",
    bitgive_description: "Bitgive is a not-for-profit organization which uses the power of Bitcoin and Blockchain technology to improve public health and the environment worldwide.",
    b4h_description: "B4H is an international not-for-profit foundation that recognizes, supports, receives donations and provides funding for organizations which use Blockchain technology to bring a positive change to humanity.",
  },

  faq_home: {
    title_section_separate: "Do you still have doubts?",
    title_section: "Click here and check the FAQ",

    faq_blU_digital_token_q1: `What is BLU?`,
    faq_blU_digital_token_a1: `BLU is a digital token that represents a basket of 14 cryptocurrencies.<br><br>BLU, just as other digital tokens, was created on top of a blockchain with a specific set of rules. BLU uses the NEM blockchain.`,

    faq_blU_digital_token_q2: `Which cryptocurrencies support BLU?`,
    faq_blU_digital_token_a2: `A total of 14 cryptocurrencies, including the top 10 ranked cryptocurrencies by market capitalization, and 4 other cryptos with strong growing potential.`,

    faq_blU_digital_token_q3: `How many BLU tokens have been created?`,
    faq_blU_digital_token_a3: `A BLU token is created at the moment of purchase. You may access our platform and token explorer to view the current amount.`,

    faq_blU_digital_token_q4: `How do you control the BLU supply to avoid dilution?`,
    faq_blU_digital_token_a4: `Currently, they can be stored on our platform and in the Stratum SmartWallet. As the project develops and other exchanges begin to support our token, BLU can be used like any other cryptocurrency, for payments and transfers.`,

    faq_button_redirect: `Go to FAQs`,
  },

  member_page: {
    banner_title: 'Be a representative',
    how_works_title_section: 'How it works',
    how_works_subtitle_section: 'Increase your income by promoting Blue Token.',

    form_input_name: `Name`,
    form_input_email: `Email`,
    form_input_phone: `Phone`,
    want_be_representative: `I want to be a representative`,

    title_form_dc_rep:`Earn income! Become a BLUE DC Rep`,

    content_dc_rep_mean: `DC Rep stands for "Digital Currency Representative". Our DC Rep program allows you to earn extra income by promoting Blue Token. We are currently looking for people with strong networking skills to promote and market our token BLUE. If you think this is the right option for you, please send us your information in the form above. We look forward to having you as a member of our growing team.`,

    our_team_title_section: 'Our team',
    our_team_subtitle_section: 'Meet our DC Rep Leaders!',

    our_representatives_title_section: 'Our representatives',
    our_representatives_subtitle_section: 'Top DC Reps',


    still_have_doubts_title_section: 'Still have doubts?',

    wht_think_title_section: 'What do you think about Blue Token?',
    wht_think_doubts_subtitle_section: 'Your feedback allows us to improve our products and is greatly appreciated.',

    card_title_0: 'Rocelo Lopes',
    card_linkedin_0: ``,
    card_subtitle_0: 'Rocelo Lopes is the founder and CEO of Digital Horizon, a company that specializes in creating blockchain solutions for large and small businesses. Based in El Salvador',
    card_content_0: `since the legalization of Bitcoin, Digital Horizon is quickly growing to become a leader in cryptocurrency services in Latin America. Rocelo is known in the industry for his ingenious cryptocurrency products such as Blue Token and Swapix. Blue Token has experienced tremendous success since its creation in 2018, while SwaPix is currently a major hit in the Brazilian market. Rocelo has a passion for inclusion and financial freedom while maintaining a strict client privacy policy, which is why Digital Horizon products are growing rapidly under his vision and leadership.`,

    card_title_1: 'Carlos Sabogal',
    card_linkedin_1: ``,
    card_subtitle_1: 'Carlo Sabogal is the project manager and chief market analyst at Blue Token. He was the chief architect of the product, being responsible for its launch in 2018.',
    card_content_1: `Carlo is very passionate about cryptocurrencies, and is devoted to crypto education and training. Carlo majored in both Finance and International Business at Florida Atlantic University (FAU) in the United States. He plans to return to FAU in the future as a professor, to educate the next generation on the financial applications of blockchain technology.`,

    card_title_2: 'Matt Brett',
    card_linkedin_2: `https://www.linkedin.com/in/matthew-brett-62b79840/`,
    card_subtitle_2: 'Matt Brett is a self-motivated, and success-driven professional with many years of experience and success in sales and leadership roles in solution selling. His experience',
    // card_subtitle_2: 'Matt Brett is the Chief Sales Officer at Blue - a self-motivated, and success-driven professional with many years of experience and success in sales and leadership roles',
    card_content_2: `with new client acquisition in finance and fractional real estate has made him an asset to our team as he continues to educate himself and others in this exciting new ecosystem.`,

    card_title_3: 'Anilson Moretti',
    card_linkedin_3: `https://www.linkedin.com/in/anilsonmoretti/`,
    // card_subtitle_3: `Moretti has worked for 15 years in top-tier banks in Brazil and has solid knowledge of the financial market, foreign exchange operations and trade finance operations.`,
    card_subtitle_3: `Anilson Moretti has worked for 15 years in top-tier banks in Brazil and has a solid understanding of the financial market, foreign exchange operations, and trade`,
    card_content_3: `finance operations. He is the founder of a financial services company and helps many people with personal development and entering the cryptocurrency market with Blue Token. “The way we handle money will change a lot in the next 30 years!”`,

    card_title_4: 'Adrian Zamora',
    card_linkedin_4: `https://www.linkedin.com/in/adrian-zamora-abb357217/`,
    card_twitter_4: `https://twitter.com/HiroshiCrypto`,
    card_subtitle_4: `Adrian is a cryptocurrency educator and speaker since 2018. He has acted as advisor on cryptocurrency subjects for the Ministry of Technology Costa Rica. Adrian has`,
    card_content_4: `a Public Law and Economics background. In his own words: “Blue Token is the best way to learn about cryptocurrencies and invest at the same time.”`,

    dc_rep_card_title_0: 'Daniel Bennet',
    dc_rep_card_subtitle_0: 'Dan Bennett acredita muito no valor de investir em ativos digitais e na tecnologia blockchain subjacente. Essa tecnologia fornecerá a governança computacional do nosso dinheiro.',
    dc_rep_card_content_0: `Blockchain já está sendo usado em blockchain de cadeia de suprimentos, blockchain imobiliário e toda uma série de registros de armazenamento em registros médicos e outros registros governamentais que precisam ser armazenados de maneira imutável e sem confiança. Há valor real investindo em ativos digitais. Estar no início da curva de adoção. Isso provará ser muito lucrativo para aqueles que optarem por ver isso como uma ferramenta de investimento de longo prazo.`,

    dc_rep_card_title_1: 'Loick Masunda',
    dc_rep_card_subtitle_1: 'Loick Masunda é um ex-jogador de futebol profissional. Ele passou os últimos 4 anos como agente independente no setor da indústria financeira. Sua experiência o levou a ter sucesso na Blue devido à sua',
    dc_rep_card_content_1: `capacidade de liderar equipes, fazer networking e construir riqueza para seus clientes. Em suas próprias palavras: “O Blu Token é um produto de investimento incrível que atende a muitas pessoas, e acredito que o serviço a muitos leva à grandeza”.`,


    title_section_calculator: `Earnings calculator`,
    subtitle_section_calculator: `Calculate your rewards!`,

    calc_reward_title_1: `BLUE Sold`,
    calc_reward_title_2: `Earnings Average`,
    calc_reward_title_3: `Estimated Rewards`,
    calc_reward_title_4: `Current Value`,
    calc_reward_title_5: `Expected future Value`,
    tootip_calc_reward: `Expected value using {calculateInvestment}% monthly average return for 3 years.`,

    calc_reward_content_footer: `Rewards are estimated from past sales; they are not guaranteed.`,
  },

  pool: {
    page_title: `Blue Pool`,
    card_head_description: `Pool is currently active. Join, refer and earn your share.`,
    card_head_description_link: `Learn more.`,
    pool_rewards: `Pool Rewards:`,
    pool_closes_in: `Pool closes in:`,
    pool_leaderboard: `Leaderboard`,
    last_month_winners: `Last month winners`,
    pool_footer_description: `Nobody has qualified yet to earn the pool rewards. Be the first and win!`,
    
    caculating: "calculating...",
    checkAgainSoon: "Pool is being calculated and will be posted soon.",
    activePool: `Pool is currently active. Join, refer and earn your share. <a href=https://youtu.be/NujOX3n7d-g target=_blank style="color:#F7921E; text-decoration:underline">Learn more.</a>`,
    poolRewards: "{month} Pool Rewards:",
    poolCloses: "Pool closes in:",
    leaderboard: "Leaderboard",
    referralVolume: "Referral Volume",
    reward: "Reward %",
    estimated: "Estimated",
    nobodyQualified:
      "Nobody has qualified yet to earn the pool rewards. Be the first and win!"
  },

  tutorials_home: {
    title_section_separate: "Tutorials",
    title_section: "Watch our tutorials and learn",
    button_watch_more: "Watch more",
  },

  footer_sales_email: "Ask questions at support@bluetoken.io",
  sales_email: "support@bluetoken.io",

  feesAndMinimus: {
    titleBanner: "Fees and Minimums",
    title_section_separate: `Our fee schedule is better <br> suited for long term holders.`,

    item_hexagon_title_0: "Custodial Fee",
    item_hexagon_value_0: "6%",
    item_hexagon_subtitle_0: "Annual fee % on total <br> value of cryptocurrencies <br> under custody.",

    item_hexagon_title_1: "Trading Fee",
    item_hexagon_value_1: "2%",
    item_hexagon_subtitle_1: `One-time fee to cover <br> trade and transfer expenses, <br>paid in crypto.`,

    item_hexagon_title_2: "Minimum Purchase",
    item_hexagon_value_2: "20 USDT",
    item_hexagon_subtitle_2: `Minimum amout accepted <br> for purchases.`,

    item_hexagon_title_3: "Redemption <br>Fee",
    item_hexagon_value_3: "1%",
    item_hexagon_value_subtitle_3: "Minimum redemption <br> fee on amounts of 50 USDT <br> or more.",
    item_hexagon_subtitle_3: `
    Minimum 2 USDT on <br>amounts less than 50 USDT.<br> 
    Minimum 50 USDT on<br>amounts larger than <br> 50 USDT.
    `,
    // item_hexagon_subtitle_3: `Minimum redemption <br> fee on amounts less <br>than 50 USDT.`,

    item_hexagon_title_4: "Minimum Holding <br>Period",
    item_hexagon_value_4: "90 days",

    item_hexagon_title_5: "Minimum <br> Redemption",
    item_hexagon_value_5: "20 USDT",
    // item_hexagon_value_subtitle_5: "(Approx. 0.002 BTC)",
    item_hexagon_subtitle_5: `Once every 7 days`,

    item_hexagon_title_6: "Deposit Fees",
    item_hexagon_value_6: "none",
    // item_hexagon_subtitle_6: `Taxa única para cobrir despesas <br> de negociação e transferência, <br> paga em criptomoeda.`,

    item_hexagon_title_7: "Withdraw Fee",
    item_hexagon_value_7: "none",
    // item_hexagon_value_subtitle_7: "(Approx. 4.78 USDT)",

    item_hexagon_title_8: "Off-site <br> Transactions <br> Fee",
    item_hexagon_value_8: "6%",
    item_hexagon_subtitle_8: `Base fee, paid in BLUE, on <br>transactions made <br>outside our <br>platform`,

  },

  blue_club: {
    banner_title: 'Blue Club',
    benefits_title: 'Benefits',
    blue_club_title_section_separate: 'Blue Club',
    blue_club_subtitle_section_separate: 'A Blue Club membership NFT is given to users who purchase 20 tokens or more.',

    blue_club_view_nft: `View NFT`,

    benefits_item_1: 'Unique Blue Club NFT (Non-fungible token).',
    benefits_item_2: 'Exclusive consulting services from our team.',
    benefits_item_3: 'Priority / VIP access to all our events.',
    benefits_item_4: 'Rewards from our partners (airdrops, giveaways, promotions).',
    benefits_item_5: 'Gifts (cool crypto-shirts, hats, and other perks).',
    benefits_item_6: 'Exclusive networking events and parties.',
    button_join_now: 'I want to be Blue',

  },

  faq: {
    banner_title: 'FAQs',
    title: "Frequently asked questions about",
    frequently_asked_questions_about: `Frequently asked questions about`,
    watch_tutorials: `Watch the Tutorials`,
    //faq...............................................

    // ........................................

    // .............................................
    faq_blU_digital_token: `BLUE Token`,
    faq_blU_digital_token_q1: `What is BLUE?`,
    faq_blU_digital_token_a1: `BLUE is a digital token that represents a basket of cryptocurrencies.<br><br>BLUE, just as other digital tokens, was created on top of a blockchain with a specific set of rules. BLUE uses the Binance Smart Chain (BSC) blockchain.
    `,
    faq_blU_digital_token_q2: `Which cryptocurrencies compose BLUE?`,
    faq_blU_digital_token_a2: `BLUE components include the top 10 cryptocurrencies ranked by market capitalization, and other digital assets with strong growth potential.`,
    faq_blU_digital_token_q3: `How many BLUE tokens have been created?`,
    faq_blU_digital_token_a3: `A BLUE token is created at the moment of purchase. You may access our platform and token explorer to view the current amount.`,
    faq_blU_digital_token_q4: `How do you control the BLUE supply to avoid dilution?`,
    faq_blU_digital_token_a4: `Tokens are "burned" (destroyed) or resold to clients and exchanges demanding tokens, thus avoiding dilution.`,
    faq_blU_digital_token_q5: `Where do I store my BLUE tokens?`,
    faq_blU_digital_token_a5: `Currently, they can be stored on our platform and in the Stratum SmartWallet. As the project develops and other exchanges begin to support our token, BLUE can be used like any other cryptocurrency, for payments and transfers.`,
    faq_blU_digital_token_a6: `Currently, they can be stored at the Blue platform and at Stratum's SmartWallet. As the proyect develops and other exchanges start supporting our token, BLUE may be used as any other cryptocurrency, for payments and transfers.`,
    // .............................................

    faq_portfolio: `Basket`,
    faq_portfolio_q1: `What cryptocurrencies form the BLUE basket?`,
    faq_portfolio_a1: `
    - 90% are top 10 cryptocurrencies, ranked by market capitalization.<br>
    - 5% of the portfolio is composed by 2 cryptocurrencies of our choosing.<br>
    - 5% of the portfolio is composed by 2 cryptocurrencies chosen by users through voting.
    <br><br>
    Documentation about our methodology and our portfolio components are always published on our site.`,
    faq_portfolio_q2: `Which cryptocurrencies are in the "top 10"?`,
    faq_portfolio_a2: `Cryptocurrencies are ranked by their market capitalization (their supply x their price). We select the top 10 cryptocurrencies for 90% of our portfolio, and review the index every quarter.`,
    faq_portfolio_q3: `How are holdings spread among these top 10 cryptocurrencies?`,
    faq_portfolio_a3: `Holdings are spread according to each asset market capitalization, and capped at 25% of the index (22.5% of the portfolio), except for Bitcoin, which is capped at 50% of the portfolio.`,
    faq_portfolio_q4: `How often is the portfolio updated?`,
    faq_portfolio_a4: `We update the portfolio components every 90 days. We review the current market valuations and, if deemed necessary, we replace cryptocurrencies in the portfolio.

    <br><br>
    We update the weights of the portfolio every month. For example, if Litecoin appreciates in value, its weight in the portfolio will increase.`,
    faq_portfolio_q5: `How do you choose the 2 cryptocurrencies for 5% of the portfolio?    `,
    faq_portfolio_a5: `We look for cryptocurrencies that offer high profit potential, either by their appreciation or by their network rewards.`,
    faq_portfolio_q6: `How do you choose the remaining 5% of the portfolio?`,
    faq_portfolio_a6: `Two crytocurrencies are choosen by our users through a voting process.`,
    faq_portfolio_q7: `Blue participates on network distributions?`,
    faq_portfolio_a7: `Yes. We participate on forks, staking and airdrops. Participations are on a per-case basis, taken only if they are beneficial for our users.`,
    faq_portfolio_q8: `How do you share network distributions?`,
    faq_portfolio_a8: `We add network distributions to the portfolio. To maximize profits and reduce expenses, the value is added on a per-case basis. Most will be distributed on a daily basis, and reported every quarter.`,
    faq_portfolio_q9: `How do you share the information about the BLUE, such as revenue and holdings reports?`,
    faq_portfolio_a9: `All information is available for our users inside our platform.`,
    // .............................

    faq_purchases: `Purchases`,

    faq_purchases_q1: `How do I purchase BLUE?`,
    faq_purchases_a1: `Access your account and select how many BLUE tokens you wish to buy. Then click on Pay and submit payment to the address shown.`,
    faq_purchases_q2: `I do not have cryptocurrency, how can I purchase BLUE?`,
    faq_purchases_a2: `
    We accept the stablecoin Tether, which is paired 1-to-1 to the US dollar.<br><br>
    In addition, our platform works with SwaPix, a trusted third party that processes fiat-to-crypto conversions, allowing users to exchange Brazilian reais to purchase BLUE tokens. <br><br>
    
    We also offer an over-the-counter option to assist you.
    `,
    faq_purchases_q3: `What is the price of a BLUE token?    `,
    faq_purchases_a3: `Price changes on real-time, always published on our site.`,
    faq_purchases_q4: `Is there a minimum purchase?`,
    faq_purchases_a4: `Yes, the minimum purchase is {minPurchase} USDT`,
    faq_purchases_q5: `What are your fees?`,
    faq_purchases_a5: `There is a one-time trade fee of 2% and an annual custodial fee of 6%. Please access our fees & minimums page for more information <a href="https://bluetoken.io/fees">here</a>.`,
    faq_purchases_q6: `Can anyone purchase tokens?`,
    faq_purchases_a6: `Not everyone. We do not accept nor encourage Chinese, Hong-Kong, and US American, residents or citizens, to purchase BLUE.`,
    faq_purchases_q7: `What happens after I purchase BLUE?`,
    faq_purchases_a7: `Your BLUE balance will reflect the purchase.`,
    faq_purchases_q8: `Is there a minimum holding period?`,
    faq_purchases_a8: `Yes. You must hold your BLUE tokens for at least 90 days.`,
    // .............................

    faq_redemptions: `Redemptions`,

    faq_redemptions_q1: `How do I redeem BLUE?`,
    faq_redemptions_a1: `Redemptions are not available until 90 days after purchase. Once the holding period is over, you may redeem your BLUE for Tether (USDT) using our platform.`,
    faq_redemptions_q2: `Is there a minimum redemption?`,
    faq_redemptions_a2: `Yes, it is currently set at 20 USDT. Please access our fees & minimums page for more information <a href="/fees">here</a>.`,
    faq_redemptions_q3: `What are your fees?    `,
    faq_redemptions_a3: `Redemption fee is currently set at {feePercent}% of the requested amount, with a minimum of:<br>
    -	{feeAbsolute} USDT for large amounts. <br>
    -	{smallFee} USDT for small amounts.<br><br>
    Please access our fees & minimums page for more information <a href="/fees">here</a>.
`,
    faq_redemptions_q4: `What happens after I redeem BLUE?`,
    faq_redemptions_a4: `Your BLUE balance will reflect the redemption, and you will receive the redeemed value in USDT.`,
    // .............................

    faq_account: `Account`,

    faq_account_q1: `How do I create an account?`,
    faq_account_a1: `Register at bluetoken.io using a secure email address and a password. You will receive an email with your User ID and an activation link  (check your spam folder just in case). You must click on the activation link, and then you may access your account using your User ID and password.`,
    faq_account_q2: `How do I recover my User ID?`,
    faq_account_a2: `At the login page, there is a link "Recover User ID". Click on it and follow the instructions.`,
    faq_account_q3: `How do I recover my Password?`,
    faq_account_a3: `At the login page, there is a link "Recover Password". Click on it and follow the instructions.`,
    faq_account_q4: `Can I recover my password information by contacting support?`,
    faq_account_a4: `No. For your security, the support team does not have access to passwords, and will never ask for, nor submit a password. The only way to recover your password is by using our automated system.`,
    faq_account_q5: `Can I recover my User ID by contacting support?`,
    faq_account_a5: `No. For your security, the support team does not share User IDs. The only way to recover a user ID is by using our automated system. `,

    // .............................
    faq_referral_benefits: `Referral Benefits`,
    faq_referral_benefits_q1: `What are the benefits for referrals?`,
    faq_referral_benefits_a1: `When you purchase a token and refer people to purchase BLUE, you get a reward and they get a bonus. Rewards and bonuses are paid in BLUE. Rewards and bonuses are unlimited. No matter how many times your referrals buy BLUE, you always will get a reward, and they will get a bonus.`,
    faq_referral_benefits_q2: `How do I register for the referral program?`,
    faq_referral_benefits_a2: `After creating your account, you will have access to your referral code on the referrals page. Share this code to get rewards on new signups.<br><br>`,
    faq_referral_benefits_q3: `Does the referral program affect the token value?`,
    faq_referral_benefits_a3: `Tokens are issued from proceeds from the sales fee, so the BLUE value is not affected by the referral program.`,
    faq_referral_benefits_q4: `How do I check my referral payouts?`,
    faq_referral_benefits_a4: `Access the referral page, and follow your rewards by date and amount.`,
    faq_referral_benefits_q5: `What is Blue Pool?`,
    faq_referral_benefits_a5: `Blue Pool is an additional referral benefit program that allows you to compete for your share of a large prize pool. The more tokens your friends buy, the higher your share of the pool. Signup, access the pool page, and create your avatar to begin! <a href="https://youtu.be/NujOX3n7d-g" target="_blank">Learn more.</a>`,

    // .............................
    faq_charity: `Charity`,
    faq_charity_q1: `What charities does Blue Token support?`,
    faq_charity_a1: `Blue supports BitGive and B4H (Blockchain for Humanity), two non-profit charitable organizations using blockchain technology for humanitarian causes.`,
    faq_charity_q2: `Why is Blue Token involved with these charities?`,
    faq_charity_a2: `We believe in the power of blockchain technology to improve human life. Our company, Digital Horizon, has and will always support smart blockchain initiatives that benefit society. We believe BitGive and B4H are serious organizations working on issues such as public health, poverty, hunger, education, and the environment.`,
    faq_charity_q3: `How does Blue Token contribute?`,
    faq_charity_a3: `We donate half (50%) of our sales fee on purchases made without a referral.`,
    faq_charity_q4: `Do the donations affect the BLUE value?`,
    faq_charity_a4: `No. We use a percentage of the fees earned on sales.`
  },

  footer: {
    link_title_1: "Fact Sheet",
    link_title_2: "White Paper",
    link_title_3: "Watch Tutorials",
    link_title_4: "Fees & Minimums",
    link_title_5: "Terms & Conditions",
    link_title_6: "Privacy",
    link_title_8: "Contact",

    wacthPlayList: `https://youtube.com/playlist?list=PL7vxL5_tbHIhqRmjjAupw1Ryrc_3R7kJh`,
    
    link_explorer_text: `Explorer`,
    link_explorer: `https://bscscan.com/token/0xE2F3Db52F7065220e113014f23f5DD4c7bafBAc2`,
  },

  contact_page: {
    banner_title:`Contact`,
    title_section: `Let’s talk?`,
    content_section: `Get in touch.`,
  },

  input_default_subject_placeholder: `Subject`,


  privacy_terms: {
    banner_title: `Privacy`,
    
    condition_title_01: `PRIVACY POLICY`,
    condition_description_01: `This website belongs to Digital Horizon Blockchain Technology (''DIGITAL
    HORIZON").<br>
    DIGITAL HORIZON is concerned about your privacy and we are committed
    to preserving it.<br>
    Thus, in addition to accepting the Platform Terms of Use, you need to know
    and comply with our Privacy Policy, which has clear and complete
    information on the collection, use, storage, treatment and protection of your
    PERSONAL DATA.<br>
    Therefore, we strongly recommend that you carefully read the Privacy Policy
    before registering or even browsing the BLUE Platform.<br>
    If you have any questions about your information or any questions about
    the use of your information, please contact support@bluetoken.io.<br>
    `,
    condition_title_02: `Definitions`,
    condition_description_02: `The expressions contained in the DEFINITIONS item of the Terms of Use are
    used in this Privacy Policy with the same meaning:
    <br><br>
    REGISTRATION DATA: The address (including the electronic address), and
    the personal qualification, understood as the User's name, first name,
    marital status and profession.
    <br><br>
    PERSONAL DATA: Data related to the natural person. It is all data that can
    be used directly or indirectly to identify a person, for example: name,
    photos, e-mail, bank details, geolocation, telephones, postings on social
    networks, IP address, etc.
    <br><br>
    TREATMENT OF PERSONAL DATA: Any operation carried out with PERSONAL
    DATA, such as those referring to the collection, production, reception,
    classification, use, access, reproduction, transmission, distribution,
    processing, archiving, storage, disposal, evaluation or control of
    information, modification, communication, transfer, diffusion or extraction.<br><br>`,
    condition_title_03: `Collection of PERSONAL DATA and Information`,
    condition_description_03: `First, it is important to emphasize that it is essential for DIGITAL HORIZON
    the collection, storage and processing of PERSONAL DATA obtained from
    users, in order to enable the provision of services offered through its
    Platform.
    <br><br>
    Thus, it is necessary that you expressly and unequivocally agree with the
    possibility that DIGITAL HORIZON automatically collect, receive and store
    PERSONAL DATA and information on its servers about the activities arising
    from the users' browser, including geolocation, IP addresses, cookies, or
    other strings of identifiers, as well as information about transactions made
    through the platform.
    <br><br>
    It is worth noting that some PERSONAL DATA is necessarily collected by
    DIGITAL HORIZON in order to enable the execution of the service contract
    (Terms of Use) signed with Users, as well as in the legitimate interest in
    allowing the PLATFORM to become operational.
    <br><br>
    In addition, it is worth remembering that in order to take advantage of the
    tools offered by DIGITAL HORIZON, you must register and, for that, you can
    inform your full name, identification document, telephone number and
    e-mail address, as well as create an access password that will be your
    exclusive knowledge.
    <br><br>
    You are aware that it is your responsibility to provide true, accurate, current
    and complete information when registering for access to the Platform. We
    remind you that the indication of incomplete or false data is a crime typified
    by law and can entail severe punishments to those who provide it.<br><br>
    `,
    condition_title_04: `What information do we collect?`,
    condition_description_04: `We may collect the following information from you when you access and use
    this website and register for and use any of our products and services:
    <br><br>
    - Full names,<br><br>
    - Email addresses,<br><br>
    - Mobile phone number,<br><br>
    - Government-issued ID number and date of birth,<br><br>
    - Company name,<br><br>
    - Business and physical address,<br><br>
    - IP address,<br><br>
    - Browser details,<br><br>
    - Credit card details (if applicable to a transaction),<br><br>
    - Bank account details or other financial information (if applicable to a
    transaction),<br><br>
    - Credit information,<br><br>
    - Information about transactions you complete using our services,<br><br>
    Giving us this information is voluntary. However, if you do not give us this
    information, we will not be able to provide any of the products or services
    you request from us.
    `,
    condition_title_05: `Control of REGISTRATION DATA by Users`,
    condition_description_05: `Each user has control over the REGISTRATION DATA contained in the profile
    created at this website, being able to access and change them using their
    login and password. Remember that the login and password are individual
    and known only to the user himself, who must ensure the confidentiality of
    this data. DIGITAL HORIZON is not responsible for how this data is used by
    the User. Users will be able to update their REGISTRATION DATA with
    DIGITAL HORIZON through the Platform.<br><br>
    `,
    condition_title_06: `What do we use your information for?`,
    condition_description_06: `The information we collect from you may be used in one of the following
    ways:
    <br><br>
    - To personalize your experience of our application and services (your
    information helps us to better respond to your individual needs),
    <br><br>
    - To improve our website (we continually strive to improve our website
    offerings based on the information and feedback we receive from
    you),
    <br><br>
    - To improve customer service (your information helps us to more
    effectively respond to your customer service requests and support
    needs),
    <br><br>
    - To process transactions,
    <br><br>
    - To comply with our obligations under any law,
    <br><br>
    - To send periodic emails about our other products and services,
    <br><br>
    - To research matters relating to our business such as security threats
    and vulnerabilities.
    <br><br>
    Please note that we only collect and process your information for one or
    more of the above purposes and for no other purpose unless we have your
    consent beforehand.<br><br>
    `,
    condition_title_07: `Do we only collect information from you via our website?`,
    condition_description_07: `We will only collect information from you when you access our website
    and/or register for one or more of our products or services.<br><br>`,
    condition_title_08: `How long do we keep your information for?`,
    condition_description_08: `We will only keep your information for a limited time until the purpose for
    which we collected your information has been met. After that, we will then
    delete or destroy it, unless we need to keep it for longer for evidential
    reasons or to comply with other legal obligations.
    <br><br>
    We may keep user information for statistical or research purposes, provided
    that we have removed any unique identifiers which can be used to link that
    information to the relevant user.<br><br>`,
    condition_title_09: `How do we keep your information secure?`,
    condition_description_09: `We implement and maintain a variety of security safeguards to keep your
    information in our possession secure against internal and external risks,
    taking into account generally accepted information security practices and
    procedures. We offer the use of a secure server. All supplied sensitive/credit
    information is transmitted via Secure Socket Layer (SSL) technology and
    then encrypted into our Payment Gateway Providers’ database only to be
    accessible by those authorized with special access rights to such systems,
    and who are required to keep the information confidential.
    <br><br>
    We do not store any credit card or other sensitive financial or personal
    information on our servers after a transaction has been completed. If the
    security of our systems or our service providers’ systems is ever breached,
    and your information is leaked or shared with someone who is not
    authorised to have it, we will notify you as soon as it is reasonably possible
    in the quickest way possible to help you avoid or reduce any potential
    losses.
    <br><br>
    Please note that no method of transmission over the Internet or method of
    electronic storage is 100% secure and we are not responsible for the
    security of any data which you are transmitting over the Internet, or any
    data you are storing, posting or providing directly to a third party’s website,
    which is governed by that party’s policies.<br><br>
    `,
    condition_title_10: `How can you access, correct and/or update the information we have
    in our possession?`,
    condition_description_10: `You have the right to ask, free of charge, whether we have a record of any
    of your information and the details of that information.
    <br><br>
    While we take reasonably practical steps to make sure our records
    containing your information are always complete, accurate and updated
    when necessary, you must notify us immediately if you believe any
    information in our possession is inaccurate or out of date. We will then
    investigate the matter and if any information is found to be incorrect,
    incomplete, inaccurate, irrelevant, excessive, out of date, misleading or was
    not collected according to the terms of this privacy notice, we will correct,
    update or delete that information within a reasonable time.
    <br><br>
    If you notify us that any of the information we have about you is not correct
    or accurate, or needs to be updated, we will immediately stop using that
    information until we have either corrected or updated it, or we can show
    that the information is actually correct and up to date.
    <br><br>
    You have the general right to request the erasure of your information if the
    following applies:
    <br><br>
    - It is no longer necessary for the purpose we collected it for,
    <br><br>
    - You withdraw your consent to consent-based processing and no other
    legal justification for processing applies,
    <br><br>
    - We unlawfully processed your information,
    <br><br>
    - You object to processing for direct marketing purposes,
    <br><br>
    - Erasure is required to comply with a legal obligation that applies to
    us.<br><br>
    `,
    condition_title_11: `Do we share your information with anyone else?`,
    condition_description_11: `We share your personal information with our agents and service providers
    who assist us in supplying our products and services. These parties are
    bound by confidentiality and non-disclosure obligations when processing
    your personal information.
    <br><br>
    We may also release your information when we believe it is necessary to
    comply with the law, enforce our site policies, or protect ours or others
    rights, property, or safety.
    <br><br>
    We may also share certain de-identified visitors and other information with
    outside parties for marketing, advertising, or other uses.
    <br><br>
    We do not sell, trade, or otherwise transfer your information to outside
    parties without your consent.<br><br>
    `,
    condition_title_12: `Do we transfer your information to other countries?`,
    condition_description_12: `As an international company that does business in different countries, it
    may be necessary for us to transfer your information to a jurisdiction other
    than your own for the purposes of operating this website and/or providing
    you with the products and services you have requested.
    <br><br>
    When we do need to transfer your information to another country, we will
    only do so if that country has sufficient legal protection available to protect
    your information.<br><br>`,
    condition_title_13: `Do we use cookies?`,
    condition_description_13: `Yes (Cookies are small flies that we use on the website which transfers to
    your computer’s hard drive through your web browser (if you allow it) to
    enable us to recognize your login details, browser and capture and
    remember certain information about your settings and preferences.
    <br><br>
    We use cookies to make our website and our users' experience more
    efficient and to help us improve our services.
    <br><br>
    Our use of cookies may allow us and third parties to collect information
    about your browsing activities over time and across different websites
    following your use of our services.<br><br>`,
    condition_title_14: `Direct marketing`,
    condition_description_14: `We may contact you by mail, telephone, fax, e-mail or other electronic
    messaging services with offers of similar products or services or information
    that may be relevant and useful to you. By registering to use any of our
    products or services and providing us with your contact details, or the
    contact details of your company and other registered users, you consent to
    being contacted by these methods for marketing purposes.
    <br><br>
    If you don’t want to receive any marketing communications from us, please
    contact support@bluetoken.io at any time.
    <br><br>
    If you have previously indicated that you do not want to receive any
    marketing communications, you don’t have to do anything further.
    <br><br>
    If at any time you would like to unsubscribe from receiving future marketing
    communications, we include detailed unsubscribe instructions at the bottom
    of each communication.<br><br>
    `,
    condition_title_15: `Complaints`,
    condition_description_15: `We respect your right to privacy and undertake to only collect and use your
    information when it is necessary and relevant to our business needs and
    only according to the terms set out in this policy.
    <br><br>
    If you are not happy with anything we do in relation to your information,
    you may lodge an objection or complaint by emailing us at
    suppor@bluetoken.io or contacting our designated information officer at
    support@bluetoken.io.
    <br><br>
    If your objection or complaint is not resolved to your satisfaction, you have
    the right to lodge a complaint with the relevant Information Regulator in
    your jurisdiction.<br><br>`,
    condition_title_16: `Changes to this notice`,
    condition_description_16: `This privacy notice may change and you should therefore review it regularly
    before accessing and using the website.
    <br><br><br>
    Date of last review: January 11th, 2022.<br><br><br>`,

  },

  terms_and_conditions: {
    banner_title:`Terms & Conditions`,
    condition_title_01: `TERMS AND CONDITIONS OF SALE OF THE BLUE TOKEN`,
    condition_description_01: `
    These terms and conditions of use govern and apply to the "Blue Token", also
    referred in this document as "BLUE", a product marketed by DIGITAL HORIZON
    BLOCKCHAIN TECHNOLOGY, based at El Salvador, Registration No. 309482-6, with
    address at Calle Cuscatlán y 83 Av. Sur, Colonia Escalón, #375, San Salvador, El
    Salvador, hereinafter referred to simply as "Digital Horizon." <br> <br>

    By accepting these terms, you agree to all of the terms and conditions of use
    described below, being aware that in making the adhesion, all your rights and
    obligations will be bound, becoming such rules and conditions of use indispensable
    for contracting the services. <br> <br>

    Therefore, before accepting, make sure that you have understood and agreed
    to the full contents of these terms and conditions of use.
    This document is not a solicitation of investment and does not in any way
    refer to an offer of securities in any jurisdiction. This document describes the sale of
    BLUE tokens through the Platform called "Blue". <br><br>
    Digital Horizon reserves the right to change the terms and conditions at any
    time and such changes will take effect immediately upon posting on the site. By
    continuing to use the Website, you accept such changes. If you do not agree to
    these terms and conditions, you must refrain from using the site and not acquire
    BLUE tokens.<br> <br>

    By acquiring BLUE tokens, to the extent permitted by law, you agree that
    none of the employees, officers, directors, contractors, consultants, shareholders,
    suppliers, service providers and their respective past, present and future
    employees, controlling companies, subsidiaries , affiliates, agents, representatives,
    predecessors, successors and assigns are liable for any special, incidental or
    consequential losses or damages arising out of or in any way connected with the
    sale of the Blue Token, including losses associated with the terms set forth below.
    <br><br>

    The user declares that he has sufficient knowledge to operate with the Blue
    platform, there being no doubt or fact that may characterize his hypothesis in
    relation to the same, possessing full and total enjoyment of his civil capacity, as
    well as being able to practice any and all necessary acts of validity of operations.
    <br><br>

    You should have sufficient understanding of the functionality, usage, storage,
    transmission mechanisms, and complexities associated with cryptographic tokens
    such as Bitcoin, Ether, Tether as well as Blockchain-based software systems in
    general. If any of the following terms are unacceptable to the user, the user should
    not purchase BLUE tokens.
    <br><br>

    Digital Horizon shall not be liable for any direct or indirect loss of profits, loss
    of data or damages resulting from technical errors, failures, interrupted internet
    connection, or the like.
    <br><br>

    You acknowledge that Blue is not an investment consultant, but only a
    trading facility of the Blue Token, and has no responsibility for the use and
    investments made by Users.
    <br><br>

    THE BUYER AGREES TO BUY, AND THE COMPANY AGREES TO SELL BLUE
    TOKENS ACCORDING TO THE FOLLOWING TERMS:
    `,

    condition_title_02:`Definitions:`,
    condition_description_02:`"Blue Token" or "BLUE" is a digital token marketed by Digital Horizon.
    <br><br>

    "Blue" is the name of the platform where the Blue Token is sold, using the web
    domain www.bluetoken.io.
    <br><br>

    "Bitcoin" or "BTC" means digital currency and payment system using peer to peer
    transactions verified by the network nodes and registering in a public distributed
    ledger called blockchain.
    <br><br>

    "User" is any individual or legal entity that adheres to this Agreement and uses the
    Site to acquire BLUE tokens, as well as other services and information offered by
    Blue.
    <br><br>

    "Digital Currency" or "Cryptocurrency" is a digital currency or digital commodity
    that uses the principle of decentralization based on network technology Blockchain
    traded on the site.
    <br><br>

    "Blockchain" is a public and shared book where all cryptocurrency operations are
    confirmed and recorded.
    <br><br>

    "Loss" means damages, claims, losses, injuries, delays, accidents, costs, business
    interruption, expenses (including, without limitation, legal fees or costs of any claim
    or proceeding), any incidental , indirect, any loss of goodwill, profits or revenue or
    any other economic loss, any loss of crypto or digital assets, any work stoppage, loss of data, failure or malfunction of the computer, or any other commercial or other loss.
    <br><br>

    "Privacy Policy" means the document that describes the methods by which Blue
    collects, uses and releases information collected from donors available on the site at
    www.bluetoken.io/privacy.
    <br><br>

    "White Paper" means the document that describes the Blue Token, available on the
    website at www.bluetoken.io.
    <br><br>

    "Site", "Website" and, or "Platform" means www.bluetoken.io including all
    subdomains and all of its respective pages.
    `,

    condition_title_03: `1. Site`,
    condition_description_03: `1.1 Digital Horizon reserves the right, in its sole and absolute discretion and without prior notice, to change, modify, add or remove features, or change any content on the site, block or restrict access to, or terminate, withdraw or suspend the use of the site or any part of the site. The User will be deemed to have accepted and agreed to any change if he/she accesses or uses the site after we have posted changes to the site. Digital Horizon shall not be liable for any loss that may be incurred as a result of such action.
    <br><br>

    1.2. User hereby acknowledges that he/she is not eligible to purchase BLUE tokens
    if he/she is a citizen, resident (taxed or otherwise), US green card holder of the
    United States, and/or resident or citizen of the People's Republic of China or Hong
    Kong.
    <br><br>

    1.3. The site may contain hyperlinks to sites that are not maintained or controlled
    by Digital Horizon. All hyperlinks to these sites are provided as a convenience to
    you. The use of hyperlinks and access to these sites is entirely at the User's own
    risk. Digital Horizon disclaims any liability and responsibility for any third-party content contained in sites linked to the Site and will not be liable for any loss arising out of your access to or use of such sites.
    <br><br>

    1.4 The inclusion of any hyperlink does not imply endorsement by Digital Horizon of
    such sites. Under no circumstances will Digital Horizon be deemed to be affiliated
    with or affiliated in any way with any trade or service marks, logos, insignia or
    other devices used or displayed on sites to which the Site is linked.`,

    condition_title_04: `2. Conditions for Selling the Blue Token`,
    condition_description_04: `2.1. BLUE is a digital token that represents a basket of cryptocurrencies.
    <br><br>

    2.2. Cryptocurrencies are acquired through the sale of the digital token BLUE.
    Therefore, the value of the BLUE Token is tied to the value of the underlying
    cryptocurrencies.
    <br><br>
    
    2.3. BLUE tokens can be purchased, redeemed and transferred through the Blue
    Platform. BLUE tokens are issued in a public blockchain called Binance Smart Chain
    (BSC).
    <br><br>
    2.4. Blue was designed to bring convenience to cryptocurrency acquisition.
    Individuals can acquire the equivalent of several cryptocurrencies by holding a
    single token. Tasks such as trading, storage, research and analysis, security and
    traceability are delegated to Blue. Custodial costs are shared among all token
    holders to maximize profits, taking advantage of economies of scale and scope as
    the project grows.
    <br><br>

    2.5. As the adoption of tokens and third-party wallet support increases, BLUE
    tokens may be used in daily activities such as bill payments, cell-phone recharges,
    and prepaying bank cards.
    <br><br>

    2.6. User must have legal permission to receive, maintain and make use of the Blue
    Token in the User's jurisdiction and in any other relevant jurisdiction.
    <br><br>

    2.7. User will provide Blue with all the information and documentation necessary to
    enable him/her to accept the purchase of BLUE in the Platform. User declares
    himself / herself fully responsible for the information entered on Blue Website,
    through the Register, Account, Permissions, Passwords and usage instructions.
    Digital Horizon, under no circumstances, will be responsible for the content
    (information, passwords, copies of information, etc.) provided by the User.
    <br><br>

    2.8. The User is obliged civilly and criminally for the supply of all his/her data (in an updated and complete way). The User is also aware that the provision of false information or improper use of third party data in its own name constitutes a crime, and the User is solely and exclusively responsible for responding to the competent authorities and any damages caused, exempting Digital Horizon from all and any liability whatsoever.
    <br><br>

    2.9. Blue may, in its sole discretion, request from Users additional documents and
    information for the purpose of maintaining the Register and which may be
    reasonably necessary for Digital Horizon to comply with its legal, regulatory and
    contractual obligations, including but not limited to, any obligation against money
    laundering.
    <br><br>

    2.10. User claims to be of sufficient age (if the user is an individual) to legally
    obtain the Blue Token, and being aware of no other legal reason that prevents
    him/her from obtaining it.
    <br><br>

    2.11. When acquiring the Blue Token, the User is aware that he/she is purchasing a
    cryptocurrency which is not regulated.
    <br><br>

    2.12. User declares that he/she does not obtain or use the Blue Token for any
    unlawful purpose.
    <br><br>

    2.13. User waives any right he/she may have or obtain to participate in a class
    action or ample arbitration against any entity or individual involved in the sale of the Blue Token.
    <br><br>

    2.14. The acquisition of the Blue Token does not involve the purchase or receipt of
    shares, ownership or any equivalent in any existing or future company, company or
    other public or private entity in any jurisdiction.
    <br><br>

    2.15. To the extent permitted by law, Digital Horizon makes no warranty, express or
    implied, regarding the future success of the Blue Token.
    <br><br>

    2.16. The User is responsible for all tax, fiscal and legal obligations arising out of his/her operations and use of the Blue services. In no event shall Blue be liable for any taxes or obligations of the User.
    <br><br>
    2.17. Blue may, in its sole discretion, refuse any registration request and / or
    suspend / cancel an existing User, especially in the event of: (a) disobedience or
    violation of any provision contained in this Agreement; (b) impossibility of
    verification, incompatibility or inconsistency in the data of the User's identity or finding falsehood in any of the information provided; (c) practice by the User of fraudulent, malicious acts or the adoption of any behavior that, at Blue's discretion, is incompatible with the objectives of the Site; (d) other situations that are at Blue's discretion.
    <br><br>

    2.18. User furthermore is aware that if his/her Registration is suspended or
    canceled for any of the reasons provided in this Terms of Use, all Token Purchases
    or Sales Orders, which have not yet been executed, will be automatically canceled,
    without any liability imputed to Digital Horizon, which will always act for the safety of all Users, including the suspended or canceled User.`,

    condition_title_05: `3. Overview of the Blue Token Sale`,
    condition_description_05: `3.1. BLUE tokens are hosted using the Binance Smart Chain (BSC) public blockchain.
    <br><br>

    3.2. BLUE tokens are generated on demand, which means that a token can only be
    generated with a purchase on the Blue Platform. BLUE tokens have an unlimited
    supply, which means there is no set limit for the number of Users.
    <br><br>

    3.3. Each sale of BLUE incurs a 2% trading fee of the purchase total.
    <br><br>

    3.4. Tokens can only be redeemed on the Blue Platform.
    <br><br>

    3.5. BLUE tokens are burned or resold at redemption, thus avoiding dilution.
    <br><br>

    3.6. To avoid unnecessary turnover costs, which negatively affect the value of the
    token, redemptions fall below a minimum retention period of 90 (ninety) days in
    the Blue wallet. In addition, redemptions are only available once a week, at days
    and times to be posted on the Blue Platform website.
    <br><br>
    3.7. Blue imposes redemption fees and minimums if it deems it necessary to cover
    and minimize trading costs and avoid devaluation of the token. Fees and minimums
    are always posted on the Blue website.
    <br><br>
    
    3.8. BLUE is a public token and therefore can be used freely by its holders and third parties. People and institutions, such as exchanges, trading platforms and
    cryptocurrency wallets, can make transactions using the token.
    <br><br>
    
    3.9. Digital Horizon is not responsible for any issues arising from third party
    transactions involving the Blue Platform and the Blue Token, as they occur outside
    of our control.`,

    condition_title_06: `4. Referral Rewards`,
    condition_description_06: `4.1. Referral rewards are awarded to holders of tokens who refer new buyers on the Blue Platform. These prizes are paid in BLUE.
    <br><br>

    4.2. These rewards are issued from funds collected as a percentage of the initial
    trade fee.
    <br><br>

    4.3. Rewards are not limited to quantity and frequency. Whenever the
    aforementioned buys a token, the referrer will be rewarded.
    <br><br>

    4.4. The reward prize is currently set at 1% of the purchase value.
    <br><br>

    4.5. The individuals who participate in the referral rewards program, pursuant to
    this contract, declare to be aware that they have no employment or responsibility
    bond, on the part of Digital Horizon in relation to the execution of activities to carry out the business, doing so in a voluntary manner. Digital Horizon reserves the right to terminate the rewards program at any time.`,

    condition_title_07: `5. Buyer Bonus`,
    condition_description_07: `5.1. Buyer bonuses are given to token buyers referred by token holders. These prizes are paid in BLUE.
    <br><br>

    5.2. Bonuses are issued from funds collected as a percentage of the initial trade
    fee.
    <br><br>

    5.3. Bonuses are not limited to frequency. Every time the aforementioned buys a
    token, a bonus is given.
    <br><br>

    5.4. The bonus prize is currently set at 0.5% of the purchase value.
    <br><br>

    5.5. Digital Horizon reserves the right to terminate the bonus program at any time.`,

    condition_title_08: `6. Blue Pool`,
    condition_description_08: `6.1. Blue Pool is a program created to further incentivize referrals.
    <br><br>

    6.2. Every month, a "pool" is created to reward users on their referrals' purchases.
    <br><br>

    6.3. Each pool lasts a month. After the month is over, a new pool is created.
    <br><br>

    6.4. To participate, users must join the program and create an avatar.
    <br><br>

    6.5. A minimum referral purchase volume is required, which is displayed in the
    platform.
    <br><br>

    6.6. Referral purchase volume is set back to zero at the end of each pool cycle.
    <br><br>

    6.7. Rewards are paid in BLUE to participants at the end of the month, within 7 to
    10 business days after the pool has reset.
    <br><br>

    6.8. A leaderboard table keeps all participants informed about their ranking and
    estimated rewards.
    <br><br>
    
    6.9. The Blue Pool is funded by a percentage of our custodial fees, determined by
    the Blue management team at its discretion.
    <br><br>

    6.10. The individuals who participate in the Blue Pool program, pursuant to this
    contract, declare to be aware that they have no employment or responsibility bond,
    on the part of Digital Horizon in relation to the execution of activities to carry out the business, doing so in a voluntary manner. Digital Horizon reserves the right to terminate the Blue Pool program at any time.`,
    
    condition_title_09: `7. BLUE Net Value (Token Price)`,
    condition_description_09: `7.1. The net value of BLUE (BNV) or Token Price, is published in real time on the Blue Platform website. Token price is quoted in crypto.
    <br><br>

    7.2. Token price is calculated by dividing the net equity of all the BLUE components by the outstanding BLUE token supply.`,

    condition_title_10: `8. Component Price`,
    condition_description_10: `8.1. Component Price is the price of each specific cryptocurrency composing the BLUE basket.
    <br><br>

    8.2. Each component's price is determined by its last price traded on a selected
    crypto exchange for our intraday trading operations.
    <br><br>

    8.3. The exchange selection is based on trading conditions and costs at the time.
    Blue works on safe exchanges that best represent global market prices, and offer a
    cost-effective fee schedule.
    <br><br>

    8.4. Component prices are always on display on the Blue Website.`,

    condition_title_11: `9. Component Quantity`,
    condition_description_11: `9.1. Component Quantity is the amount of each specific cryptocurrency composing the BLUE basket. This includes current cryptocurrencies and excludes Network Distributions such as hard-forks, staking and airdrops.
    <br><br>

    9.2. Cryptocurrencies arising from hard-forks are included in the basket
      - as a basket component,
      - exchanged for Bitcoin and included in the basket as bitcoin,
      - or exchanged for the original forked crypto, and then included in the basket.
    <br><br>
    
    9.3. Cryptocurrencies earned from staking are accrued and included in the basket
    at the end of the corresponding quarter.
    <br><br>

    9.4. Cryptocurrencies earned on airdrops are accrued, and included in the basket
    once the crypto's accumulated value equals or exceeds 0.01BTC.
    <br><br>

    9.5. Blue reserves the right to retain 50% (fifty percent) of earnings related to
    network distributions (forks, airdrops, starking).
    <br><br>

    9.6 It is at the sole discretion of Blue's management to participate on Network
    Distributions (staking, airdrops, hard-forks). These are evaluated on a per-case
    basis.`,

    condition_title_12: `10. Component Value`,
    condition_description_12: `10.1. The value of each component is calculated by multiplying its Price by its Quantity.`,

    condition_title_13: `11. Gross Value of the Components (GVC)`,
    condition_description_13: `11.1. Gross Value (GVC) is calculated by adding up all component values. This is the gross value of the entire basket.`,

    condition_title_14: `12. Custodial Fee`,
    condition_description_14: `12.1. A custodial fee of 6% (six percent) per year is applied, and is charged on the GVC.
    <br><br>

    12.2. The said custodial fee is divided by 365 (three hundred and sixty-five) days
    and is charged in the rounded percentage of 0.01644% (zero point zero one six
    hundred and forty four percent).
    <br><br>

    12.3. The custodial fee is charged from the GVC every day at midnight (GMT -3).
    The custodial fee is charged in cryptocurrency. Component quantity is updated at
    the time the fee is charged.
    <br><br>

    12.4. The custodial fee is used to cover the development, legal, security, and
    managerial expenses of Blue.
    <br><br>

    12.5. These fees exclude trading expenses during the recomposition and
    rebalancing of the cryptocurrency basket, which are withdrawn from its holdings.
    <br><br>

    12.6. Blue may change the value of the custodial fee at any time by notifying users
    on the Platform.`,

    condition_title_15: `13. Net Equity`,
    condition_description_15: `13.1. Net Equity is calculated by subtracting the custodial fee from GVC.
    <br><br>
    
    13.2. Net Equity is divided by the token supply to arrive at the Blue Token price`,

    condition_title_16: `14. Taxation of the Blue Token`,
    condition_description_16: `14.1. Each User is responsible for all tax, tax and legal obligations arising from its operations on the Blue Platform. Under no circumstances may Digital Horizon be liable for any taxes or obligations of the User.`,

    condition_title_17: `15. Privacy`,
    condition_description_17: `15.1. Users may be contacted via email by Digital Horizon. These emails will only be informative. Digital Horizon will not request User’s personal information via email. See our Privacy Policy available on the Site for additional information.`,

    condition_title_18: `16. Security`,
    condition_description_18: `16.1. User declares that he / she is aware that he / she must take the necessary measures to carry out the operations with the greatest possible safety.
    <br><br>

    16.2. User must use reliable and secure server e-mail, with strong password,
    reliable and of its exclusive knowledge, avoiding to use the same password for
    other operations carried out on the Internet.
    <br><br>

    16.3. In addition, any third party gaining access to such private keys, including accessing the Platform login credentials, may be able to misappropriate the User's BLUE tokens. Any errors or defects caused by or otherwise related to the digital wallet or vault the User chooses to receive and store BLUE, including his/her own failure to maintain or properly use such digital wallet or safe, may also result in the loss of his/her tokens.
    <br><br>

    16.4. Preferably, it is recommended that users have a unique email address to use the Blue Platform.
    <br><br>
    

    16.5. Digital Horizon, in turn, uses the most modern security measures in order to ensure the protection of its data and its Users.
    <br><br>
    
    16.6. Each User is responsible for storing his/her password and PIN. In case of loss of the same, to carry out the recovery it will be necessary for the User to indicate the e-mail registered in the system.
    <br><br>

    16.7. Digital Horizon may suspend all or part of the services without prior notice to registered users on the occasion of theft of assets by hackers and other methods, as well as in cases of force majeure. If Digital Horizon, for its convenience, decides to suspend the provision of this service, will notify in advance the registered users.
    <br><br>
    
    16.8. Hackers or other malicious groups or organizations may attempt to interfere
    with the Blue Platform in a number of ways, including, but not limited to, malware
    attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing, and spoofing. In addition, because the platform is based on open source software, there is a risk that a third party or a member of Digital Horizon's team may intentionally or unintentionally introduce weaknesses in the platform's core
    infrastructure, which could adversely affect the Blue Platform and Blue Token,
    including its utilities.
    <br><br>

    16.9. Digital Horizon assumes no responsibility for damages caused to registered users based on the Terms specified herein.`,

    condition_title_19: `17. Force Majeure`,
    condition_description_19: `17.1. Refers as Force Majeure to events occurring beyond capabilities that may affect the execution of transactions, including, but not limited to: (a) earthquakes, hurricanes, floods, landslides, volcanic eruptions, and other natural disasters; (b) War, demonstrations, riots, terrorism, sabotage, embargo, and a general strike; (c) The Government's economic policy affecting directly; (d) Over the years, Digital Horizon has been performing its obligations in accordance with the laws and regulations that apply in connection with the occurrence of Force Majeure, so Digital Horizon will not indemnify or hold harmless any user or any other party for any risk, liability and any claim that may arise in connection with the delay and can not be implemented due to Force Majeure obligations.`,

    condition_title_20: `18. Disclaimer of Warranties`,
    condition_description_20: `18.1. User agrees and acknowledges that Digital Horizon or any member of the Blue
    team is not liable for any indirect, special, incidental, consequential or other losses of any kind, in connection with any offense, contract or otherwise (including, without limitation, loss of revenue, income or profits, and loss of use or data) arising out of or in connection with any acceptance or reliance on either the White Paper, these Terms and Conditions or any part thereof for you.`,

    condition_title_21: `19. Limitations Disclaimer`,
    condition_description_21: `19.1. Blue does not or intends to make, and hereby disclaims, any representation, warranty or commitment in any manner to any entity or person, including any representation, warranty or commitment regarding the accuracy and completeness of any information contained in White Paper and these Terms and Conditions.`,

    condition_title_22: `20. Severity`,
    condition_description_22: `20.1. The User and Digital Horizon agree that if any part of these Terms is found to be unlawful or unenforceable, in whole or in part, such provision will be, in respect of such jurisdiction, ineffective only to the extent that such invalidity or unenforceability determination without affecting its validity or enforceability in any other form or jurisdiction and without affecting the remaining provisions of the Terms, which shall continue to be in full force and effect.`,

    condition_title_23: `21. Site access issues and restrictions`,
    condition_description_23: `21.1. The services provided by Blue are available exclusively through the Internet, with the User having the necessary and appropriate equipment, such as computers, tablets and smartphones with properly licensed programs and applications (including antivirus).
    <br><br>

    21.2. Access to the Blue Platform is subject to falls, interruptions, delays and
    problems inherent to the use of the Internet, including when it is maintained
    (temporary unavailability, which will be previously informed on the Blue Platform).
    <br><br>

    21.3. Digital Horizon shall not be liable for any such interruptions or for any User access problems, such as limitation of the equipment or the navigation program. Likewise, Digital Horizon will not be liable for any viruses that may attack the Users' equipment.`,

    condition_title_24: `22. Risk`,
    condition_description_24: `22.1. Trading cryptocurrencies is a high risk activity where prices can change significantly over time. With respect to price fluctuations, the book value may be increased or decreased significantly at any time. All virtual currencies may have the potential to drastically change their value or even become meaningless / worthless. There is a high risk of loss as a result of buying, selling or trading in any market. Digital Horizon is not responsible for changes in the exchange variation of the value of the cryptocurrencies.
    <br><br>

    22.2. Cryptocurrency trading also has an additional risk that is not experienced by
    other currencies or other commodities in the market. Unlike most currencies that
    are guaranteed by the government or other legal institutions, or by gold and silver, virtual currency is a "virtual" currency solely guaranteed by technology and trust. There is no central bank that can control, protect the value of crypto in crisis, or print the currency.
    <br><br>

    22.3. Digital Horizon is not liable for any indirect, special, incidental, consequential or other damages of any kind, in civil liability, contract or otherwise (including, but not limited to, loss of revenue, profit or profits, and loss of use or data) arising out of or in connection with any acceptance or reliance on these Terms and Conditions.
    <br><br>

    22.4. As with other decentralized cryptographic tokens, BLUE is susceptible to
    miners' attacks in the course of validation of BLUE transactions at Blockchain,
    including, but not limited to, double-spend attacks, major mining power attacks,
    and selfish mining attacks . Any successful attack poses a risk to the Blue Platform and the Blue Token, including, but not limited to, the accurate execution and registration of transactions involving the Blue Token.
    <br><br>

    22.5. Blue Token's regulatory status of distributed accounting technology is unclear or unstable in many jurisdictions. It is difficult to predict how or whether regulatory agencies can apply existing regulations with respect to this technology and its applications, including the Blue Platform and the BLUE token. Likewise, it is difficult to predict how or whether legislatures or regulatory agencies can implement changes in law and regulation that affect distributed accounting technology and its applications, including the Blue Platform and the Blue Token. Regulatory actions may adversely impact the Blue Platform and the Blue Token in a number of ways, including for illustration purposes only, by determining that the purchase, sale, and delivery of BLUE constitutes illegal activity or that the Blue Token may become a regulated instrument requiring registration or licensing of such instruments or of some or all of the parties involved in the purchase, sale and delivery thereof. Digital Horizon may terminate operations in a jurisdiction in the event of regulatory actions, or changes in the law or regulation, render illegal the operation in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction.
    <br><br>

    22.6. If secondary Blue Token trading is facilitated by third party exchanges, such
    exchanges may be relatively new and subject to little or no regulatory oversight,
    making them more susceptible to fraud or manipulation. In addition, to the extent
    that third parties attribute an exchange value external to the BLUE (for example, as denominated in a digital or fiduciary currency), that value can be extremely volatile.
    <br><br>

    22.7. Unlike bank accounts or accounts in some other financial institutions, the Blue Token does not have insurance unless you specifically get private insurance to
    insure it. Thus, in the case of loss or loss of utility value, there is no public
    insurance or private insurance organized by Blue to offer your appeal.
    <br><br>

    22.8. Cryptographic tokens, such as the BLUE, are a new, untested technology. In
    addition to the risks included in these Terms, there are other risks associated with your purchase, possession and use of the token, including unpredictable risks. Such risks may materialize even more as unforeseen variations or combinations of risks discussed in these Terms.`,

    condition_title_25: `23. Cooperation with legal authorities`,
    condition_description_25: `23.1. Digital Horizon cooperates with all requests, subpoenas or requests of the law, provided that they are fully supported and documented by the law in the relevant jurisdictions.`,

    condition_title_26: `24. Indemnification`,
    condition_description_26: `24.1. To the maximum extent permitted by applicable law, the User will indemnify, defend and hold harmless Digital Horizon against all claims, demands, actions, damages, losses, costs and expenses (including attorneys' fees) arising out of or relating to: (a) user's purchase and use of BLUE token; (b) user's responsibilities or obligations under these Terms; (c) user's violation of these Terms; or (d) user's violation of any rights of any other person or entity.
    <br><br>

    24.2. Digital Horizon reserves the right to exercise exclusive control over the
    defense, at its expense, of any claim subject to indemnification under the terms of
    this section. This indemnity is in addition and not in lieu of any other indemnities established in a written contract between the User and Digital Horizon`,

    condition_title_27: `25. Language`,
    condition_description_27: `24.1. Currently, only the English versions of any Blue communication are
    considered official. The English version will prevail in case of translation differences.`,

    condition_title_28: `26. Governing Law`,
    condition_description_28: `26.1. The Terms, the arbitration clause contained therein and any non-contractual obligations arising in any way arising out of or relating to these Terms are governed, construed and enforced in accordance with the Salvadoran Laws.`,

    condition_title_29: `27. Arbitration`,
    condition_description_29: `27.1. Any dispute or difference arising out of or relating to these Terms or to the legal relationships set forth in these Terms, including any question relating to their existence, validity or termination ("Dispute"), will be submitted and finally settled by the Mediation and Arbitration Centre of the Chamber of Commerce and Industry of El Salvador.`,

    condition_title_30: `28. About technical user support`,
    condition_description_30: `28.1. The technical support to the User will be made through e-mail, which can be obtained through the Blue Platform Site.`,

    condition_title_31: `29. Final provisions`,
    condition_description_31: `29.1. In the event of User's non-compliance with any provision of this "Terms and Conditions of Use", Blue may declare it resolved in relation to the User, regardless of any notice, notification or other formality, immediately interrupting User access to the Blue Platform and its operations, without prejudice to any other rights granted to Digital Horizon, either by law or by this "Terms and Conditions of Use".
    <br><br>

    29.2. User acknowledges that the services provided by the Blue Platform have been
    sufficiently described in the "White Paper'' and in this "Terms and Conditions of
    Use'', and that Digital Horizon Blockchain Technology has duly complied with its
    duty to provide information.
    <br><br>

    29.3. This "Terms and Conditions of Use" does not imply assignment or transfer to
    the User of any rights to the Site, or any part of its content or features. Digital
    Horizon reserves the right to demand, at any time, any and all necessary User
    documentation in order to safeguard the operations performed by the Blue Platform
    Site, so that the User hereby agrees to provide any and all documents when
    requested by Digital Horizon.
    <br><br>

    29.4. Digital Horizon is not responsible for the lack of declaration of operations to the competent authorities, mainly regarding the information that must be provided by the User in the Income Tax Return. In the same way, Digital Horizon is not responsible for any taxes, fees and / or contributions possibly incurred on the
    operations, as well as any possible penalties that the User may suffer from the
    competent authorities.
    <br><br>

    29.5. The User is aware that the Declaration of his assets, profits and / or losses, as well as the reflexes arising from them, is his sole and exclusive responsibility.
    <br><br>

    29.6. The User declares to be responsible, civil and criminal, for all the information and documents that involve the operations in the Blue Platform, stating that the money used for purchases of BLUE tokens, have legal and declared origin, being strictly prohibited the use of the Platform for the practice of crimes such as money laundering and evasion of foreign currency, and the User may be civilly and criminally liable not only to the authorities, but also to Digital Horizon for any damages and / or damages caused, including Digital Horizon's image.
    <br><br>

    29.7. The User hereby declares that there is no regulation on the purchase and sale
    of products and services in cryptocurrencies, and there is no endorsement of the
    rules of the Central Bank. The relationship is based solely on trust between User
    and Digital Horizon.
    <br><br>

    29.8. The user acknowledges that the Blue Token should not be interpreted, treated
    or classified as: any currency other than cryptocurrency; debentures, shares or
    shares issued by any person or entity; rights, options or derivatives relating to such debentures, shares; rights under a contract for differences or under any other
    contract whose intended purpose or purpose is to secure a profit or avoid a loss;
    units in a collective investment scheme; units in a business trust; derivative units in an enterprise trust; any other security or class of securities.
    <br><br>

    29.9. Users are prohibited from modifying or attempting to modify any functionality,
    to produce any derivations, or to access any part of the Blue Platform in order to
    create a competing product or service, or any product or service that contains
    ideas, features or functions similar to the Blue Platform.
    <br><br>

    This document was last updated: May 1st, 2022 <br> <br> <br> <br> <br> <br>`,


  }
  
}
