<template>
  <div>
    <div class="bg-gradient-blue">

    <div class="d-flex shadow-header">
      <div class="container-default d-flex">

        <div class="col-header-common container-image-header">
          <div class="container-img-bar-header">
            <a href="/homepage" class="invisible-link">
              <img src="../../assets/imgs/stratumblue_white_outline_logo.png" alt="stratumblue logo" class="img-bar-home">
            </a>
          </div>
        </div>

        <div class="col-header-common col-links-bar-header">
          <!-- <a class="link-menu-item-header" href="/BeMember"> {{ $t('welcome_link_header_menu') }}  </a> -->

          <div class="d-flex align-items-center">

            <a
              @mouseover="onHoverLink(0)"
              @mouseleave="onLeaveLink()"
              class="custom-bar-link-header"
              href="https://bluetoken.io/app/login"
            >
              <div class="d-block">
                <p class="header-link m0">{{ $t('header.access_link_header_menu') }}</p> 
                <span class="nav-line" :class="hoverLinkActive && hoverLinkIndex === 0 ? 'hover-link-active' : 'hover-link-unactive' "></span>
              </div>
            </a>

            <router-link
              v-for="(i, index) in allHeaderLinks" 
              :key="index+10"
              class="align-items-center bar-item-links faq-link-header" 
              :to="i.route !== '/Signup' ? i.route : '/'" 
              @mouseover.native="onHoverLink(i.index)" 
              @mouseleave.native="onLeaveLink"
            >
              <div class="d-block">
                <p class="header-link">{{ $t(i.text) }}</p> 
                <span class="nav-line" :class="hoverLinkActive && hoverLinkIndex === i.index && selectedRoute !== i.route ? 'hover-link-active' : 'hover-link-unactive' "></span>
              </div>
            </router-link>

            <!-- <a
              @mouseover="onHoverLink(3)"
              @mouseleave="onLeaveLink()"
              class="custom-bar-link-header"
              href="https://bluetoken.blogspot.com/"
              target="_blank"
            >
              <div class="d-block">
                <p class="header-link m0">{{ $t('header.blogs_link_header_menu') }}</p> 
                <span class="nav-line" :class="hoverLinkActive && hoverLinkIndex === 3 ? 'hover-link-active' : 'hover-link-unactive' "></span>
              </div>
            </a> -->


            <router-link
              v-for="(i, index) in allHeaderLinks_second" 
              :key="index" 
              class="align-items-center bar-item-links" 
              :to="i.route !== '/Signup' ? i.route : '/'" 
              @mouseover.native="onHoverLink(i.index)" 
              @mouseleave.native="onLeaveLink"
            >
              <div class="d-block">
                <p class="header-link">{{ $t(i.text) }}</p> 
                <span class="nav-line" :class="hoverLinkActive && hoverLinkIndex === i.index && selectedRoute !== i.route ? 'hover-link-active' : 'hover-link-unactive' "></span>
              </div>
            </router-link>

            <!-- <div>
              <button class="button-buy-now-header" @click="redirectSignup()">{{ $t('header.buy_now_link_header_menu') }}</button>
            </div> -->

            <router-link
              class="align-items-center bar-item-links contact-header-link" 
              to="/Contact" 
              @mouseover.native="onHoverLink(6)" 
              @mouseleave.native="onLeaveLink"
            >
              <div class="d-block">
                <p class="header-link">{{ $t('header.contact_link_header_menu') }}</p> 
                <span class="nav-line" :class="hoverLinkActive && hoverLinkIndex === 6 && selectedRoute !== '/Contact' ? 'hover-link-active' : 'hover-link-unactive' "></span>
              </div>
            </router-link>

          </div>

        </div>

        <div class="container-menu-mobile" @click="visibleMobileMenu = !visibleMobileMenu">
          <span v-html="iconSets.header_icon_menu" class="icon-menu-mobile"></span>
        </div>

        <div class="col-header-common container-chose-lang">
          <div>
            <LangSwitcher />
          </div>
        </div>
      </div>

    </div>

    <div class="menu-mobile" :class="visibleMobileMenu ? 'show-menu-mobile' : 'hide-menu-mobile' ">
      <button @click="visibleMobileMenu = false" class="close-menu-mobile">X</button>
      <div class="container-menu-links-mobile">
        <MenuMobile :activeRoute="selectedRoute" />
      </div>
    </div>

  </div>
  </div>
</template>

<script>
import TopHeaderLinks from './TopHeaderLinks.vue'
import LangSwitcher from './LangSwitcher.vue'
import MenuMobile from './MenuMobile.vue'

export default {

  components: { 
    TopHeaderLinks, 
    LangSwitcher,
    MenuMobile,
  },

  data: () => ({
    message: 'foi ?',
    hoverLinkActive: false,
    visibleMobileMenu: false,
    hoverLinkIndex: 0,
    selectedRoute: '',
    allHeaderLinks: [
      // {
      //   route: '/Wallet',
      //   text: 'header.access_link_header_menu'
      // },
      // {
      //   route: '/BlueClub',
      //   index: 1,
      //   text: 'header.blue_club_link_header_menu'
      // },
      {
        route: '/Faqs',
        index: 2,
        text: 'header.faq_link_header_menu'
      },
      // {
      //   route: '/Blog',
      //   index: 3,
      //   text: 'header.blogs_link_header_menu'
      // },
      // {
      //   route: '/BeMember',
      //   text: 'header.welcome_link_header_menu'
      // },
      // {
      //   route: '/Pool',
      //   text: 'header.bluePool_link_menu'
      // }
    ],
    allHeaderLinks_second: [
      // {
      //   route: '/Wallet',
      //   text: 'header.access_link_header_menu'
      // },
      // {
      //   route: '/BeMember',
      //   index: 4,
      //   text: 'header.welcome_link_header_menu'
      // },
      // {
      //   route: '/Pool',
      //   index: 5,
      //   text: 'header.bluePool_link_menu'
      // }
    ],
    alternativeHeaderLinks: [
      // {
      //   route: '/Wallet',
      //   text: 'header.access_link_header_menu'
      // },
      {
        route: '/Contact',
        text: 'header.contact_link_header_menu'
      },
    ]
  }),
  methods: {
    onHoverLink(index) {
      this.hoverLinkActive = true
      this.hoverLinkIndex = index
    },

    redirectSignup() {
      window.location.href = 'https://bluetoken.io/app/signup'
    },

    onLeaveLink() {
      this.hoverLinkActive = false
    },
  },
  watch: {
      '$route': {
        handler: function() {
          //  console.log('route > ', this.$route.path)
           if (this.visibleMobileMenu) {
             this.visibleMobileMenu = false
           }
           this.selectedRoute = this.$route.path
        },
        deep: true,
        immediate: true
      },
      visibleMobileMenu() {
        if (this.visibleMobileMenu) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      }
  }
}
</script>
