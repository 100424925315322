export const FaqData = [
    {
        subject: "faq.faq_blU_digital_token",
        id: '1',
        questionsAndAnswers: [
            {
                question: "faq.faq_blU_digital_token_q1",
                answer: "faq.faq_blU_digital_token_a1"
            },
            {
                question: "faq.faq_blU_digital_token_q2",
                answer: "faq.faq_blU_digital_token_a2"
            },
            {
                question: "faq.faq_blU_digital_token_q3",
                answer: "faq.faq_blU_digital_token_a3"
            },
            {
                question: "faq.faq_blU_digital_token_q4",
                answer: "faq.faq_blU_digital_token_a4"
            },
        ]
    },
    {
			subject: "faq.faq_account",
			id: '1',
			questionsAndAnswers: [
				{
					question: "faq.faq_account_q1",
					answer: "faq.faq_account_a1"
				},
				{
					question: "faq.faq_account_q2",
					answer: "faq.faq_account_a2"
				},
				{
					question: "faq.faq_account_q3",
					answer: "faq.faq_account_a3"
				},
				{
					question: "faq.faq_account_q4",
					answer: "faq.faq_account_a4"
				},
				{
					question: "faq.faq_account_q5",
					answer: "faq.faq_account_a5"
				},
				// {
				// 	question: "faq.faq_account_q6",
				// 	answer: "faq.faq_account_a6"
				// },

			]
		},
    {
			subject: "faq.faq_purchases",
			id: '1',
			questionsAndAnswers: [
				{
					question: "faq.faq_purchases_q1",
					answer: "faq.faq_purchases_a1"
				},
				{
					question: "faq.faq_purchases_q2",
					answer: "faq.faq_purchases_a2"
				},
				{
					question: "faq.faq_purchases_q3",
					answer: "faq.faq_purchases_a3"
				},
				{
					question: "faq.faq_purchases_q4",
					answer: "faq.faq_purchases_a4",
					notSimple: true,
				},
				{
					question: "faq.faq_purchases_q5",
					answer: "faq.faq_purchases_a5"
				},
				{
					question: "faq.faq_purchases_q6",
					answer: "faq.faq_purchases_a6"
				},
				{
					question: "faq.faq_purchases_q7",
					answer: "faq.faq_purchases_a7"
				},
				{
					question: "faq.faq_purchases_q8",
					answer: "faq.faq_purchases_a8"
				},


			]
		},
		{
			subject: "faq.faq_redemptions",
			id: '1',
			questionsAndAnswers: [
				{
					question: "faq.faq_redemptions_q1",
					answer: "faq.faq_redemptions_a1"
				},
				{
					question: "faq.faq_redemptions_q2",
					answer: "faq.faq_redemptions_a2"
				},
				{
					question: "faq.faq_redemptions_q3",
					answer: "faq.faq_redemptions_a3"
				},
				{
					question: "faq.faq_redemptions_q4",
					answer: "faq.faq_redemptions_a4"
				},

			]
		},
		{
			subject: "faq.faq_referral_benefits",
			id: '1',
			questionsAndAnswers: [
				{
					question: "faq.faq_referral_benefits_q1",
					answer: "faq.faq_referral_benefits_a1"
				},
				{
					question: "faq.faq_referral_benefits_q2",
					answer: "faq.faq_referral_benefits_a2"
				},
				{
					question: "faq.faq_referral_benefits_q3",
					answer: "faq.faq_referral_benefits_a3"
				},
				{
					question: "faq.faq_referral_benefits_q4",
					answer: "faq.faq_referral_benefits_a4"
				},
				// {
				// 	question: "faq.faq_referral_benefits_q5",
				// 	answer: "faq.faq_referral_benefits_a5"
				// },

			]
		},

		// {
		// 	subject: "faq.faq_charity",
		// 	id: '1',
		// 	questionsAndAnswers: [
		// 		{
		// 			question: "faq.faq_charity_q1",
		// 			answer: "faq.faq_charity_a1"
		// 		},
		// 		{
		// 			question: "faq.faq_charity_q2",
		// 			answer: "faq.faq_charity_a2"
		// 		},
		// 		{
		// 			question: "faq.faq_charity_q3",
		// 			answer: "faq.faq_charity_a3"
		// 		},
		// 		{
		// 			question: "faq.faq_charity_q4",
		// 			answer: "faq.faq_charity_a4"
		// 		},

		// 	]
		// },
];
