<template>
  <div>

    <div class="d-flex container-all-itens-form-member">
      <!-- <div class="container-bemember-image-side">
        <img src="../assets/imgs/img_be_member_form3.png" alt="" class="image-side-form-be-member">
      </div> -->

      <div class="container-form-member">
        <div class="container-title-form-inputs">
          <h1 v-html="$t('member_page.title_form_dc_rep')"> </h1>
        </div>

        <div class="container-form-inputs-member">
          <input
            v-for="(i, index) in formFields" :key="index"
            class="default-input-form custom-input-form-dc-rep" type="text"
            :class="index !== 'sourcePage' ? '' : 'display-none' "
            :placeholder="$t(`member_page.form_input_${index}`)"
            v-model="formFields[index]"
          >

          <div v-if="messageType === 'warning-null-form'">
            <p>{{$t('email_form_empty')}}</p>
          </div>

          <button 
            :class="`
              ${messageType === 'success' ? 'success-bg' : '' }
              ${messageType === 'error' ? 'error-bg' : '' }
            `
            "
            class="btn-outlined-default btn-form-member-page" 
            @click="sendEmailSale()"
          >
          
            <p v-if="messageType === 'success'">{{$t('email_sent_success')}}</p>
            <p v-if="messageType === 'error'">{{$t('email_sent_error')}}</p>
            <p v-if="messageType === '' || messageType === 'warning-null-form' ">{{$t('member_page.want_be_representative')}}</p>
          
          </button>
        </div>

        <div v-if="messageType !== '' ">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name:"BeMemberForm",
  data:() => ({
    formFields: {
      name: '',
      email: '',
      phone: '',
      sourcePage: 'DC Rep Inquiry'
    },
    messageType: '',
  }),
  methods: {
    checkFields() {
      if (
        this.formFields.name === '' ||
        this.formFields.email === '' ||
        this.formFields.phone === ''
      ) {
       this.messageType = 'warning-null-form' 
      } else {
        this.messageType = ''
      }
    },
    async sendEmailSale() {
      this.checkFields()

      if (this.messageType !== 'warning-null-form') {

        const sendEmailResp = await this.$store.dispatch('sendEmail', this.formFields)
        if (sendEmailResp.status === 200) {
          this.$toast.open({
              message: this.$t('email_sent_success'),
              type: 'success',
              position: 'top',
              duration: 5000,
          });

          this.messageType = 'success'
          return
        }

        this.messageType = 'error'
            this.$toast.open({
              message: this.$t('email_sent_error'),
              type: 'error',
              position: 'top',
              duration: 5000,
          });
      }
    }
  },
  watch: {
    messageType() {
      if (this.messageType !== '') {
        setTimeout(() => {
          this.messageType = ''
        }, 5000)
      }
    }
  }
}
</script>
