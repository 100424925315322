<template>
  <div>
        <div class="container-chose-lang-mobile">
          <div>
            <LangSwitcher />
          </div>
        </div>

        <a
            class="custom-bar-link-header"
            href="https://bluetoken.io/app/login"
        >
            <div class="d-block">
                <p class="acess-menu-mobile header-link-mobile m0">{{ $t('header.access_link_header_menu') }}</p>
            </div>
        </a>

        <router-link
            v-for="(i, index) in allHeaderLinks" 
            :key="index" 
            class="align-items-center bar-item-links" 
            :to="i.route !== '/Signup' ? i.route : '/'" 
        >
            <div class="d-block">
                <p class="header-link-mobile">{{ $t(i.text) }}</p>
                <!-- <span class="nav-line" :class="activeRoute === i.route ? 'router-link-exact-active' : 'hover-link-unactive' "></span> -->
            </div>
        </router-link>

        <!-- <a
            class="custom-bar-link-header"
            href="https://bluetoken.blogspot.com/"
            target="_blank"
        >
            <div class="d-block">
                <p class="header-link-mobile m0">{{ $t('header.blogs_link_header_menu') }}</p>
            </div>
        </a> -->


        <router-link
            v-for="(i, index) in allHeaderLinks_second" 
            :key="index" 
            class="align-items-center bar-item-links" 
            :to="i.route !== '/Signup' ? i.route : '/'" 
        >
            <div class="d-block">
                <p class="header-link-mobile">{{ $t(i.text) }}</p>
                <!-- <span class="nav-line" :class="activeRoute === i.route ? 'router-link-exact-active' : 'hover-link-unactive' "></span> -->
            </div>
        </router-link>

        <!-- <div>
            <button class="button-buy-now-header" @click="redirectSignup()">{{ $t('header.buy_now_link_header_menu') }}</button>
        </div> -->

        <router-link
            class="align-items-center bar-item-links contact-header-link" 
            to="/Contact"
        >
            <div class="d-block">
                <p class="header-link-mobile">{{ $t('header.contact_link_header_menu') }}</p>
                <!-- <span class="nav-line" :class="activeRoute === '/Contact' ? 'router-link-exact-active' : 'hover-link-unactive' "></span> -->
            </div>
        </router-link>


        <div class="d-flex">
            <SocialMidiaNav />
        </div>
  </div>
</template>

<script>
import SocialMidiaNav from '../SocialMidiaNav.vue'
import LangSwitcher from './LangSwitcher.vue'
export default {
    name: 'MenuMobile',
    props: ['activeRoute'],
    components: {
        SocialMidiaNav,
        LangSwitcher,
    },
    data:() => ({
        allHeaderLinks: [
        // {
        //   route: '/Wallet',
        //   text: 'header.access_link_header_menu'
        // },
            // {
            //     route: '/BlueClub',
            //     text: 'header.blue_club_link_header_menu'
            // },
            {
                route: '/Faqs',
                text: 'header.faq_link_header_menu'
            },
            // {
            //     route: '/Blog',
            //     text: 'header.blogs_link_header_menu'
            // },
        ],
        allHeaderLinks_second: [
        // {
        //   route: '/Wallet',
        //   text: 'header.access_link_header_menu'
        // },
            // {
            //     route: '/BeMember',
            //     text: 'header.welcome_link_header_menu'
            // },
            // {
            //     route: '/Pool',
            //     text: 'header.bluePool_link_menu'
            // }
        ],
    }),
    methods: {
        redirectSignup() {
            window.location.href = 'https://bluetoken.io/app/signup'
        },
        checkRoute() {
            // console.info('activeRoute', this.activeRoute)
        }
    },
    created() {
        this.checkRoute()
        this.blockScroll()
    },
    watch: {
        activeRoute() {
            this.checkRoute()
        }
    }
}
</script>

<style>

</style>