<template>
    <div class="white-card custom-card-member-page">
      <div class="container-member-card-image">
        <div>
          <img :src="require(`../assets/imgs/${image}`)" alt="" class="img-member-card-image">
          <span @click="redirectTo(linkedinLink)" class="linkedin-card-photo" v-html="iconSets.linkedinHexagon"></span>
          <span v-if="twitterLink" @click="redirectTo(twitterLink)" class="linkedin-card-photo" v-html="iconSets.twitterHexagon"></span>
        </div>
      </div>

      <h3 class="card-title-members"> {{ $t(`${cardTitle}`) }} </h3>
      <p class="mb-0 mt-1"> {{ $t(`${cardSubTitle}`) }} </p>

      <div v-if="selectedCardExpand == index && expandCard">
        <p class="m0" v-html="$t(`${cardContent}`)"></p>
      </div>

      <button class="default-button-transparent" @click="expandCardFunc(index)">
        <span v-html="iconSets.chevron_down" class="chevron-down-icon mt-4"></span>
      </button>
    </div>
</template>
<script>
export default {
  name: 'CardPhoto',
  props: ['image', 'cardTitle', 'cardSubTitle', 'cardContent', 'index', 'linkedinLink', 'twitterLink'],
  data: () => ({
    expand: false,
    selectedCardExpand: 0,
    expandCard: false,
  }),
  methods: {
    expandCardFunc(selectedIndex) {
      this.expandCard = !this.expandCard;
      this.selectedCardExpand = selectedIndex;
    },
    redirectTo(linkedinLinkParam) {
      const getText = this.$t(`${linkedinLinkParam}`);
      window.open(getText, '_blank');
    },
  },
}
</script>
