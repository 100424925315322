<template>
  <div>
    <InitialBanner
      image="bg_be_representative_banner.png"
      imageTitle="member_page.banner_title"
    />

    <BeMemberForm />

    <div class="container-default">
      <SeparateSections
        titleSectionSeparate="member_page.how_works_title_section"
        subTitleSectionSeparate="member_page.how_works_subtitle_section"
      />

      <div class="m-center custom-container-message-member">
        <div class="container-text-member">
          <p> {{ $t('member_page.content_dc_rep_mean') }} </p>
        </div>

        <div class="d-flex">
          <img src="../assets/imgs/image_dc_rep_mean.png" alt="">
        </div>
      </div>

      <SeparateSections
        titleSectionSeparate="member_page.our_team_title_section"
        subTitleSectionSeparate="member_page.our_team_subtitle_section"
      />

      <div class="container-cards-member">
        <div v-for="(i, index) in cardsContent" :key="index" class="conatiner-card-photos">
          <CardPhoto
            :image='i.image'
            :cardTitle='i.cardTitle'
            :cardSubTitle='i.cardSubTitle'
            :cardContent='i.cardContent'
            :index='index'
            :linkedinLink="i.linkedinLink"
            :twitterLink="i.twitterLink"
          />
        </div>
      </div>

      <SeparateSections
        titleSectionSeparate="member_page.title_section_calculator"
        subTitleSectionSeparate="member_page.subtitle_section_calculator"
      />
      <CalcReward />
      <div>
          <button class="button-tutorial-home default-white-button custom-learn-more-btn-yellow" @click="redirectToTutorial()">
            <p class="bold color-purple"> {{ $t('txt_button_2_know_section') }} </p>
            <span v-html="iconSets.youtube"></span>
          </button>
      </div>
      <!-- <SeparateSections
        titleSectionSeparate="member_page.our_representatives_title_section"
        subTitleSectionSeparate="member_page.our_representatives_subtitle_section"
      />

      <div class="container-cards-member mt-10">
        <div v-for="(i, index) in cardsContentDcReps" :key="index" class="conatiner-card-photos custom-c-top-dc-rep">
          <CardPhoto
            :image='i.image'
            :cardTitle='i.cardTitle'
            :cardSubTitle='i.cardSubTitle'
            :cardContent='i.cardContent'
            :index='index'
          />
        </div>
      </div> -->


      <!-- <SeparateSections
        titleSectionSeparate="member_page.still_have_doubts_title_section"
      />

      <div>
        <WhiteHexagon
          :hexagonOptions="hexagonOptions"
        />
      </div> -->

      <SeparateSections
        titleSectionSeparate="member_page.wht_think_title_section"
        subTitleSectionSeparate="member_page.wht_think_doubts_subtitle_section"
      />

      <DefaultForm
        :initialFieldsProp="initialFieldsForm"
        :endpointPath="endpointPath"
        textButton="default_form_button_send"
        sourcePageProp="Contact"
      />
    </div>
  </div>
</template>
<script>
import InitialBanner from '../components/InitialBanner.vue'
import BeMemberForm from '../components/BeMemberForm.vue'
import SeparateSections from '../components/SeparateSections.vue'
import CardPhoto from '../components/CardPhoto.vue'
import WhiteHexagon from '../components/whiteHexagon.vue'
import DefaultForm from '../components/DefaultForm.vue'
import CalcReward from '../components/CalcReward.vue'

export default {
  name: 'BeRepresentative',
  components: {
    InitialBanner,
    BeMemberForm,
    SeparateSections,
    CardPhoto,
    WhiteHexagon,
    DefaultForm,
    CalcReward,
  },
  data:() => ({
    cardsContent: [
      // {
      //   image: 'member_card_0.png',
      //   cardTitle: 'member_page.card_title_0',
      //   cardSubTitle: 'member_page.card_subtitle_0',
      //   cardContent: 'member_page.card_content_0'
      // },
      // {
      //   image: 'member_card_1.png',
      //   cardTitle: 'member_page.card_title_1',
      //   cardSubTitle: 'member_page.card_subtitle_1',
      //   cardContent: 'member_page.card_content_1'
      // },
      {
        image: 'member_card_2.png',
        cardTitle: 'member_page.card_title_2',
        cardSubTitle: 'member_page.card_subtitle_2',
        linkedinLink: 'member_page.card_linkedin_2',
        cardContent: 'member_page.card_content_2'
      },
      {
        image: 'member_card_3.png',
        cardTitle: 'member_page.card_title_3',
        linkedinLink: 'member_page.card_linkedin_3',
        cardSubTitle: 'member_page.card_subtitle_3',
        cardContent: 'member_page.card_content_3'
      },
      {
        image: 'member_card_4.png',
        cardTitle: 'member_page.card_title_4',
        linkedinLink: 'member_page.card_linkedin_4',
        twitterLink: 'member_page.card_twitter_4',
        cardSubTitle: 'member_page.card_subtitle_4',
        cardContent: 'member_page.card_content_4',
      },
    ],

    cardsContentDcReps: [
      {
        image: 'dc_rep_member_page_card_0.png',
        cardTitle: 'member_page.dc_rep_card_title_0',
        cardSubTitle: 'member_page.dc_rep_card_subtitle_0',
        cardContent: 'member_page.dc_rep_card_content_0'
      },
      {
        image: 'dc_rep_member_page_card_1.png',
        cardTitle: 'member_page.dc_rep_card_title_1',
        cardSubTitle: 'member_page.dc_rep_card_subtitle_1',
        cardContent: 'member_page.dc_rep_card_content_1'
      },
    ],

    hexagonOptions: [
      {
        image: 'svg_hexagon_white_be_member_page_0',
        title: 'Information Sheet',
      },
      {
        image: 'svg_hexagon_white_be_member_page_0',
        title: 'Prospect',
      },
      {
        image: 'svg_hexagon_white_be_member_page_1',
        title: 'Fees and minimums',
      },
      {
        image: 'svg_hexagon_white_be_member_page_2',
        title: 'Tutorials',
      },
    ],

    initialFieldsForm: {
      name: '',
      email: '',
    },

    optionalFieldsForm: {
      subject: '',
    },

    endpointPath: '/api/contact',

  }),
  methods: {
    redirectToTutorial() {
      if (this.getLocale() === 'pt') {
        window.open('https://youtu.be/D8P1_01-uF4', '_blank');
      } 
      if (this.getLocale() === 'en') {
        window.open('https://youtu.be/NujOX3n7d-g', '_blank');
      }
      if (this.getLocale() === 'es') {
        window.open('https://youtu.be/pGuhBIfGSQM', '_blank');
      }
    },
    getLocale() {
      return this.$i18n.locale
    }
  }
}
</script>
