<template>
  <div>

    <div class="container-faq-content-page">
      <div class="left-container-faq-page">
        <div class="custom-container-left-faq-page">

          <div class="d-flex mt-10 mb-5">
            <h3 class="left-title-faq-page title-separate-faq text-separete-section-faq" v-html="$t('faq.frequently_asked_questions_about')"> </h3>
            <div class="container-dash-separate-section-faq-page">
              <hr class="default-dash-separate-section ml-2">
            </div>
          </div>

          <div>
            <div v-for="(i, index) in faqData" :key="index">
              <button class="button-side-itens" @click="selectSideItem(i)"> <p> {{ $t(`${i.subject}`) }} </p> </button>
              <hr class="bg-white">
            </div>
          </div>

          <div class="container-blue-tutorial-faq-page">

          <div class="d-flex mt-10 mb-5">
            <h3 class="left-title-faq-page title-separate-faq text-separete-section-faq" v-html="$t('faq.watch_tutorials')"> </h3>
            <div class="container-dash-separate-section-faq-page">
              <hr class="default-dash-separate-section ml-2">
            </div>
          </div>
            
            <BlueTutorial calledPage="faq"/>
          </div>
        </div>
      </div>

      <div class="rigth-container-faq-page">
        <div v-for="(i, index) in faqData" :key="index">
          
          <div class="d-flex mt-10 mb-5">
            <h3 :ref="i.subject" class="title-separate-faq text-separete-section-faq"> {{ $t(`${i.subject}`) }} </h3>
            <div class="container-dash-separate-section-faq-page">
                <hr class="default-dash-separate-section ml-2">
            </div>
          </div>
          
          <div v-for="(j, index) in i.questionsAndAnswers" :key="index">
              <ExpandPanel :title="j.question">

              <!-- j.question !== 'faq.faq_redemptions_q2' &&  -->
              <div
                class="container-text-faq-page" 
                v-if="
                j.question !== 'faq.faq_redemptions_q3' && 
                j.question !== 'faq.faq_purchases_q4'">
                <p class="content-text" v-html="$t(`${j.answer}`)"></p>
              </div>

              <!-- <div v-if="j.question === 'faq.faq_redemptions_q2'">
                <p
                  class="content-text"
                  v-html="
                    $t(`${j.answer}`, {
                      minimunRedemption: feeRedeemAbsolute,
                    })
                  "
                ></p>
              </div> -->

              <div 
                class="container-text-faq-page"
                v-if="j.question === 'faq.faq_redemptions_q3'"
              >
                <p
                  class="content-text"
                  v-html="
                    $t(`${j.answer}`, {
                      feePercent: feeRedeemPercent,
                      feeAbsolute: feeRedeemAbsolute,
                      smallFee: 2,
                    })
                  "
                ></p>
              </div>

              <div 
                class="container-text-faq-page"
                v-if="j.question === 'faq.faq_purchases_q4'"
              >
                <p
                  class="content-text"
                  v-html="
                    $t(`${j.answer}`, {
                      minPurchase: minPurchaseState,
                    })
                  "
                ></p>
              </div>
            </ExpandPanel>
            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { FaqData } from '../configs/faqData'
import BlueTutorial from './BlueTutorial.vue'
import ExpandPanel from './ExpandPanel.vue'

export default {
  components: {
    BlueTutorial,
    ExpandPanel,
  },
  data: () => ({
    feeRedeemPercent: "",
    feeRedeemAbsolute: "",

    faqData: FaqData,
    showFaq: false,
    showFaqText: false,
    clickedTitle: '',

    showFaqContainerDelayed: false,
    showFaqTextDelayed: false,

    selectedFaqItemState: 0,
    minPurchaseState: 0,
    blueInfo: []
  }),
  methods: {

    async selectSideItem(i) {
      var element = this.$refs[i.subject];
      var top = await element[0].offsetTop;
      window.scrollTo({ top, behavior: 'smooth' });
    },
    async getBlueInfo() {
        const blueInfo = await this.$store.dispatch('getBlueTokenInfo')
        this.minPurchaseState = blueInfo.tradeMinimal
    },
    async getFee() {
      let resp = await this.$store.dispatch("getFees");
      let fees = resp.data;
      let arrayFeePercent = [];
      let arrayFeeAbsolute = [];
      let arrayFeePurchase = [];

      for (let i in fees) {
        if (fees[i].type == "redeem" && fees[i].math == "percent") {
          arrayFeePercent.push(fees[i]);
        }
      }

      for (let i in fees) {
        if (fees[i].type == "redeem" && fees[i].math == "absolut") {
          arrayFeeAbsolute.push(fees[i]);
        }
      }

      for (let i in fees) {
        if (fees[i].type == "purchase" && fees[i].math == "percent") {
          arrayFeePurchase.push(fees[i]);
        }
      }

      arrayFeePercent.sort((a, b) => {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        return 0;
      });


      this.feeRedeemPercent = arrayFeePercent[0].fee;
      this.feeRedeemAbsolute = arrayFeeAbsolute[0].fee;
    },
  },
  created() {
    this.getFee();
    this.getBlueInfo()
  },
  mounted() {
    this.getFee();
  },
  computed: {
    ...mapGetters({
      assetsPercent: 'assetsPercent',
      // blueTokenHistoryPrice: 'blueTokenHistoryPrice',
    }),
  },
  watch: {}
}
</script>
