<template>
  <div class="">
    <div>
      <div class="bg-gradient-blue">
        <div class="m0 container-footer container-default">

          <div class="col-header-common container-image-footer">
            <div class="container-img-bar-footer p-20">
              <router-link to="/homepage">
                <img src="../assets/imgs/stratumblue_white_outline_logo.png" alt="stratumblue logo" class="img-link-footer">
              </router-link>
            </div>
          </div>

          <div class="col-links-bar-footer">
              <!-- <TopHeaderLinks /> -->
              <div>
                <div class="col-links-bar-footer">
                  <!-- <a class="footer-item bar-item-links mr-1" :href="`./docs/factsheet_${currentLocale}.pdf`" target="_blank"> {{ $t('footer.link_title_1') }}  </a> -->
                  <!-- <a class="footer-item bar-item-links mr-1" :href="`./docs/whitepaper_may2022.pdf`" target="_blank">{{ $t('footer.link_title_2') }}</a> -->
                  <a class="footer-item bar-item-links mr-1" :href="$t('footer.wacthPlayList')" target="_blank">{{ $t('footer.link_title_3') }}</a>
                  <router-link class="footer-item bar-item-links mr-1" to="/Fees">{{ $t('footer.link_title_4') }}</router-link>
                  <router-link class="footer-item bar-item-links mr-1" to="/Terms">{{ $t('footer.link_title_5') }}</router-link>
                  <router-link class="footer-item bar-item-links mr-1" to="/Privacy">{{ $t('footer.link_title_6') }}</router-link>
                  <router-link class="footer-item bar-item-links" to="/contact">{{ $t('footer.link_title_8') }}</router-link>
                  <a class="footer-item bar-item-links mr-1" :href="$t('footer.link_explorer')" target="_blank">{{ $t('footer.link_explorer_text') }}</a>
                </div>

                <a class="bar-item-links color-orange email-footer" :href="`mailto:${$t('sales_email')}`">{{ $t('footer_sales_email') }}</a>
              </div>
          </div>

          <div class="container-social-midia-icons-footer align-items-center justify-space-center">
            <div class="d-flex container-icons-footer align-items-center justify-space-center">
              <a 
                v-for="(i, index) in footerIcons" 
                :key="index" 
                :href="i.link"
                target="_blank"
              >
                <span v-html="iconSets[i.icon]" class="default-icon-footer"> </span>
              </a>
              <!-- <span v-html="iconSets.linkedin" class="default-icon-footer"> </span>
              <span v-html="iconSets.instagram" class="default-icon-footer"> </span>
              <span v-html="iconSets.youtube" class="default-icon-footer"> </span>
              <span v-html="iconSets.twitter" class="default-icon-footer"> </span>
              <span v-html="iconSets.facebook" class="default-icon-footer"> </span> -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Footer',
  data:() => ({
    currentLocale: '',
    footerIcons: [
      {
        icon: 'linkedin',
        link: 'http://linkedin.com/company/bluetoken',
      },
      {
        icon: 'instagram',
        link: 'https://www.instagram.com/bluetoken.io/',
      },
      {
        icon: 'youtube',
        link: 'https://www.youtube.com/bluetoken',
      },
      {
        icon: 'twitter',
        link: 'https://twitter.com/bluetoken_io',
      },
      // {
      //   icon: 'facebook',
      //   link: 'https://www.facebook.com/bluedigitaltoken',
      // },
      // {
      //   icon: 'spotify',
      //   link: 'https://open.spotify.com/show/0dwfQkTrTW9jV5BP0Wluuz?si=68d24f47e95a451b',
      // },
    ]
  }),
  methods: {
    currentSelectLocale () {
      this.currentLocale = this.$i18n.locale
    },
  },
  mounted() {
    this.currentSelectLocale()
  }
}
</script>
