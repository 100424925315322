<template>
  <div class="mb-5 mt-15">
    <!-- <h1 class="container-header-support-organization"> {{ $t('support_organization.title') }} </h1> -->
      <!-- titleSectionSeparate="member_page.title_section_calculator" -->
    <SeparateSections
      subTitleSectionSeparate="support_organization.title"
    />

    <p class="container-header-support-organization"> {{ $t('support_organization.description') }} </p>

    <!-- <SeparateSections 
      titleSectionSeparate="support_organization.title"
      subTitleSectionSeparate="support_organization.description"
    /> -->

    <div class="container-cards-support-organization">

      <div class="flex white-card title-card custom-card-charity custom-card-charity-left-spacing">
        <div class="container-image-card-support-organization">
          <img src="../assets/imgs/bitgive.png" class="image-card-support-organization-section" alt="bitgive image">
        </div>
        <!-- <div class="bg_bitgive"></div> -->
        <div class="container-texts-charity-card">
          <div>
            <h1 class="color-blue-darken bold m0">BitGive</h1>
            <p class="font-size-1-2 mb-0 mt-1">{{ $t('support_organization.bitgive_description') }}</p>
          </div>
        </div>
      </div>

      <div class="flex white-card title-card custom-card-charity custom-card-charity-right-spacing">
        <div class="container-image-card-support-organization">
          <img src="../assets/imgs/b4h.png" class="image-card-support-organization-section" alt="B4H image">
        </div>
        <!-- <div class="bg_b4h"></div> -->
        <div class="container-texts-charity-card">
          <div>
            <h1 class="color-blue-darken bold m0">B4H</h1>
            <p class="font-size-1-2 mb-0 mt-1">{{ $t('support_organization.b4h_description') }}</p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import SeparateSections from './SeparateSections.vue'

export default {
  name: 'SupportOrganizationSection',
  components: {
    SeparateSections
  }
}
</script>
