<template>
  <div>
    <div v-if="cookiesNeed" class="container-cookie-banner">
        <h1 class="content-Text"> {{ $t('cookie_modal_text') }} <a target="_blank" href="./docs/cookies_information.pdf" class="content-link">{{$t('learn_more_button')}}</a></h1>
        <button @click="acceptCookies()" class="default-yellow-button content-btn">Accept cookies</button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CookiesBanner',
    data:() => ({
      cookiesNeed: true
    }),
    methods: {
      acceptCookies() {
        this.cookiesNeed = false;
        document.cookie = "accepted_cookies=yes";
        localStorage.setItem('blueCookie', 'accepted_cookies=yes')
      },
      checkCookies() {
        const savedCookie = localStorage.getItem('blueCookie')
        if (savedCookie !== null) {
          this.cookiesNeed = false;
          document.cookie = "accepted_cookies=yes";
        }
      }
    },
    created() {
      this.checkCookies();
    }
}
</script>

<style>

</style>