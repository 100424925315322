var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-faq-content-page"},[_c('div',{staticClass:"left-container-faq-page"},[_c('div',{staticClass:"custom-container-left-faq-page"},[_c('div',{staticClass:"d-flex mt-10 mb-5"},[_c('h3',{staticClass:"left-title-faq-page title-separate-faq text-separete-section-faq",domProps:{"innerHTML":_vm._s(_vm.$t('faq.frequently_asked_questions_about'))}}),_vm._m(0)]),_c('div',_vm._l((_vm.faqData),function(i,index){return _c('div',{key:index},[_c('button',{staticClass:"button-side-itens",on:{"click":function($event){return _vm.selectSideItem(i)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(("" + (i.subject))))+" ")])]),_c('hr',{staticClass:"bg-white"})])}),0),_c('div',{staticClass:"container-blue-tutorial-faq-page"},[_c('div',{staticClass:"d-flex mt-10 mb-5"},[_c('h3',{staticClass:"left-title-faq-page title-separate-faq text-separete-section-faq",domProps:{"innerHTML":_vm._s(_vm.$t('faq.watch_tutorials'))}}),_vm._m(1)]),_c('BlueTutorial',{attrs:{"calledPage":"faq"}})],1)])]),_c('div',{staticClass:"rigth-container-faq-page"},_vm._l((_vm.faqData),function(i,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex mt-10 mb-5"},[_c('h3',{ref:i.subject,refInFor:true,staticClass:"title-separate-faq text-separete-section-faq"},[_vm._v(" "+_vm._s(_vm.$t(("" + (i.subject))))+" ")]),_vm._m(2,true)]),_vm._l((i.questionsAndAnswers),function(j,index){return _c('div',{key:index},[_c('ExpandPanel',{attrs:{"title":j.question}},[(
              j.question !== 'faq.faq_redemptions_q3' && 
              j.question !== 'faq.faq_purchases_q4')?_c('div',{staticClass:"container-text-faq-page"},[_c('p',{staticClass:"content-text",domProps:{"innerHTML":_vm._s(_vm.$t(("" + (j.answer))))}})]):_vm._e(),(j.question === 'faq.faq_redemptions_q3')?_c('div',{staticClass:"container-text-faq-page"},[_c('p',{staticClass:"content-text",domProps:{"innerHTML":_vm._s(
                  _vm.$t(("" + (j.answer)), {
                    feePercent: _vm.feeRedeemPercent,
                    feeAbsolute: _vm.feeRedeemAbsolute,
                    smallFee: 2,
                  })
                )}})]):_vm._e(),(j.question === 'faq.faq_purchases_q4')?_c('div',{staticClass:"container-text-faq-page"},[_c('p',{staticClass:"content-text",domProps:{"innerHTML":_vm._s(
                  _vm.$t(("" + (j.answer)), {
                    minPurchase: _vm.minPurchaseState,
                  })
                )}})]):_vm._e()])],1)})],2)}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-dash-separate-section-faq-page"},[_c('hr',{staticClass:"default-dash-separate-section ml-2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-dash-separate-section-faq-page"},[_c('hr',{staticClass:"default-dash-separate-section ml-2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-dash-separate-section-faq-page"},[_c('hr',{staticClass:"default-dash-separate-section ml-2"})])}]

export { render, staticRenderFns }