import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BlueClub from '../views/BlueClub.vue'
import Faqs from '../views/Faqs.vue'
import Fees from '../views/Fees.vue'
import Contact from '../views/Contact.vue'
import BeMember from '../views/BeMember.vue'
import Pool from '../views/Pool.vue'
import Terms from '../views/TermsConditions.vue'
import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/homepage',
    name: 'Home',
    component: Home,
  },
  {
    path: '/BlueClub',
    name: 'BlueClub',
    component: BlueClub
  },
  {
    path: '/Faqs',
    name: 'Faqs',
    component: Faqs
  },
  {
    path: '/Fees',
    name: 'Fees',
    component: Fees
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/BeMember',
    name: 'BeMember',
    component: BeMember
  },
  // {
  //   path: '/Pool',
  //   name: 'Pool',
  //   component: Pool
  // },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/' || process.env.BASE_URL,
  routes
})

export default router
