<template>
  <div>
    <div class="white-card container-custom-card-calc-reward container-table-calc-reward">
        <div>
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('member_page.calc_reward_title_1') }}</th>
                        <th>{{ $t('member_page.calc_reward_title_2') }}</th>
                        <th>{{ $t('member_page.calc_reward_title_3') }}</th>
                        <th>{{ $t('member_page.calc_reward_title_4') }}</th>
                        <th class="tooltip" >{{ $t('member_page.calc_reward_title_5') }}
                            <span class="interrogation-icon" v-html="iconSets.interrogation"></span>

                             <span class="tooltiptext">
                                {{ $t('member_page.tootip_calc_reward', 
                                    {
                                        calculateInvestment: interestCalcResultTransformed
                                    }) 
                                }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <money
                                class="input-calc-reward"
                                v-model="blueTokenQuantity" 
                                v-bind="money"
                                @keydown="execCalc()"
                            ></money>
                        </td>
                        <td>
                            <p>{{ bluePoolAverage }} BLUE</p>
                        </td>
                        <td>
                            <p>{{ estimatedRewards.toFixed(2) }} BLUE</p>
                        </td>
                        <td>
                            <p> {{ currentValueCalc.toFixed(2) }} USDT</p>
                        </td>
                        <td>
                            <p>{{ expectedFutureValueState.toFixed(2) }} USDT</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="container-footer-calc-reward">
            <p>{{ $t('member_page.calc_reward_content_footer') }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import { Money } from 'v-money'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    components: {
        Money
    },
    data:() => ({
        money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 2,
            masked: false
        },
        bluFirstPrice: 39.57,
        blueTokenQuantity: 1,
        bluePoolAverage: 0,
        estimatedRewards: 0,
        currentValueCalc: 0,
        expectedFutureValueState: 0,
        interestCalcResult: 0,
        interestCalcResultTransformed: 0,
        bluePrice: '',
    }),
    created() {
        this.requestBluePoolAverage();
        this.getCurrencies();
        this.getBluePriceReturn()
        this.calcInterest();

        setTimeout(() => {
            this.execCalc();
        }, 1000)
    },
    methods: {
        async requestBluePoolAverage() {
            this.bluePoolAverage = await this.$store.dispatch('getBluePoolAverage')
        },
        calculateBlueReward() {
            this.estimatedRewards = (this.blueTokenQuantity * 0.01) + this.bluePoolAverage;
            return (this.blueTokenQuantity * 0.01) + this.bluePoolAverage;
        },
        async calculateCurrentValue() {
            if (this.bluePrice.value !== undefined) {
                this.currentValueCalc = this.estimatedRewards * this.bluePrice.value;
                return this.estimatedRewards * this.bluePrice.value;

            } else {
                const bluePrice = await this.getBluePriceReturn()
                this.currentValueCalc = this.estimatedRewards * bluePrice;
                return this.estimatedRewards * bluePrice;
            }
        },
        async getBlueTokenDays() {
            const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

            const currentDay = moment().format('YYYY-MM-DD')
            const diffDaysResult = diffDays(new Date('2018-12-04'), new Date(currentDay)); 

            return diffDaysResult
        },
        async getBluePriceReturn() {
            const filterBlueprice = await this.$store.dispatch('getBlueTokenInfo')
            if (filterBlueprice) {
                let result;
                result = ((filterBlueprice.value - this.bluFirstPrice) / this.bluFirstPrice) * 100;

                return result.toFixed(2)
            }
            return 0
        },
        async calcInterest() {
            const calc = parseFloat(await this.getBluePriceReturn()) / parseFloat(await this.getBlueTokenDays())
            const result = (calc*30)
            this.interestCalcResultTransformed = parseFloat(result.toFixed(2))
            // this.interestCalcResult = result/100
            return result/100
        },
        async calcExpectedFutureValue() {
            const interestCalcResult = await this.calcInterest()
            const resultFirstOperation = (1+interestCalcResult)
            const fv1 = Math.pow(resultFirstOperation, 36)

            // thiscurrentValueCalc can't be less than 0 (?)
            if (this.currentValueCalc !== 0) {
                const totalFv1 = parseFloat(this.currentValueCalc.toFixed(2))*fv1
                this.expectedFutureValueState = parseFloat(totalFv1.toFixed(2))

            } else {
                const getCurrentValueCalc = await this.calculateCurrentValue();
                const totalFv1 = parseFloat(getCurrentValueCalc.toFixed(2))*fv1
                this.expectedFutureValueState = parseFloat(totalFv1.toFixed(2))
            }            
        },
        execCalc() {
            this.checkBlueSoldInput();
            this.requestBluePoolAverage();
            this.calculateBlueReward();
            this.calculateCurrentValue();
            this.calcExpectedFutureValue();

            // if (this.bluePrice !== '') {
            //     this.calculateBlueReward();
            //     this.calculateCurrentValue();
            // }
        },
        async getCurrencies() {  
            this.bluePrice = await this.$store.dispatch('getBlueTokenInfo')
        },
        checkBlueSoldInput() {
            if (this.blueTokenQuantity < 1) {
                this.blueTokenQuantity = 1
            }
        }
    },
    mounted() {
        this.execCalc()
    },
    computed: {
        ...mapGetters({
            currencies: 'currencies',
        }),
    },
    watch: {
        blueTokenQuantity() {
            this.execCalc();
        },
        bluePrice() {
            this.calculateCurrentValue()
            this.execCalc();
        },
        currentValueCalc() {
            if (this.currentValueCalc !== 0) {   
                this.execCalc();
            }
        }
    }
}
</script>

<style>

</style>