<template>
  <div>
    <div
      class="bg-initial-banner"
      :style="image !== 'bg_banner_blue_club_page.png' ? `background: linear-gradient(to top, #0000004d, #00000014), url(${require(`../assets/imgs/${image}`)}) no-repeat top center;` : `background: url(${require(`../assets/imgs/${image}`)}) no-repeat top center;`"
    >
      <h1 v-if="imageTitle" class="custom-title-initial-banner"> {{ $t(`${imageTitle}`) }} </h1>
    </div>
    <!-- <img class="bg-faq-page-banner" src="../../assets/imgs/bg_banner_faq_page_1.png" alt=""> -->
  </div>
</template>
<script>
export default {
  props: ['image', 'imageTitle'],
  name: 'InitialBanner'
}
</script>
