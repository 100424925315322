export default {
  welcome_external_file: "Welcome to the redesign-blue-web",

  cookie_modal_text: `Este sitio utiliza cookies para mejorar su experiencia mientras navega.`,
  learn_more_button: `Sepa más.`,

  months: {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre"
  },

  download: `Descargar`,

  email_sent_success: `¡Éxito en el envío del email!`,
  email_sent_error: `¡Error al enviar el email!`,
  email_form_empty: `Necesario Llenar todos los campos!`,

  last_month_winners: "Ganadores del mes pasado",
  last_month_blue_total: "Blue pool ha pagado un total de",

  header: {
    welcome_link_header_menu: "Sea un Representante",
    access_link_header_menu: "Acceda su Cartera",
    blue_club_link_header_menu: "Blue Club",
    faq_link_header_menu: "Preguntas Frecuentes",
    bluePool_link_menu: "Blue Pool",
    blogs_link_header_menu: "Blog",
    buy_now_link_header_menu: "Compre Ahora",
    contact_link_header_menu: "Contacto",
  },

  scroll_helper_text_1: `¿Por qué comprar Blue Token?`,
  scroll_helper_text_2: `Comparacion de BLUE`,
  scroll_helper_text_3: `Staking`,
  scroll_helper_text_4: `Calculadora de Inversion`,
  scroll_helper_text_5: `¿Aún tiene preguntas ?`,
  scroll_helper_text_6: `Tutoriales`,

  input_default_subject_placeholder: `Tema`,
  accumulated_banner_home: 'Acumulado, ',
  since_dec_2018: `desde dic. 2018`,
  title_home_banner: "¿Qué es Blue Token?",
  description_home_banner:
    "Blue Token es la primera canasta tokenizada inteligente de criptomonedas, que le brinda acceso a las mejores criptomonedas del mercado.",
  live_price_home_banner: `En Tiempo real`,

  why_buy_title_section: "¿Por qué comprar Blue Token?",
  why_buy_description_section:
    "Blue Token permite tener varias criptomonedas a la vez con solo un token, lo que brinda conveniencia y diversificación a los usuarios.",

  where_blue_midia_title_section: "Where are we in the media?",
  where_blue_midia_description_section: "We are Reference",

  default_form_label_client: "¿Es cliente de Blue Token?",
  default_form_checkbox_yes: "Sí",
  default_form_checkbox_no: "No",
  default_form_placeholder: "Escriba un mensaje",
  default_form_button_send: "Enviar",

  home_chart_compare: {
    oil_crud: 'Petróleo Crudo',
    blueToken: 'BLUE',
    sp500: 'S&P 500',
    bitcoin: 'BITCOIN',
    ftse: 'FTSE 100',
    stoxx: 'STOXX 50',
    nasdaq: 'NASDAQ',
    ibovespa: 'IBOVESPA',
    gold: 'ORO',
    hash11: 'HASH11',
  },


  home: {
    fact_sheet_download_button: `Hoja Informativa`,

    indicate_description_0_know_section:
      "Le ayudamos a optimizar su selección de Criptomonedas.",
    indicate_description_1_know_section: "Refiera a sus amigos a Blue Token y",
    indicate_description_2_know_section: "reciba bonos",
    indicate_description_3_know_section: "cuando compren tokens.",

    blueTokenChart: {
      chart_label_button: `Personalize su gráfico:`,
      year: `1 año`,
      current_year: `Desde inicio del año`,
      all_year: `Desde creación`,
    },

    buy_now: `Compre Ahora`,

    chartCompare: {
      title: "Comparación de BLUE",
      description: `BLUE vs. <br> (Ajustado en USD, 1 año)`,
    },

    calcSection: {

      calc_title_section: "Calculadora de Inversión",
      calc_description_section: "Cuánto y por cuánto tiempo invertir.",

      input_avaible_capital: "Capital Disponible",

      input_tolerance: "Tolerancia",
      input_tolerance_0: `Conservadora`,
      input_tolerance_1: `Moderada`,
      input_tolerance_2: `Agresiva`,

      year_range_input: `año`,
      years_range_input: `años`,

      input_initial_capital: "Inversión Inicial",

      input_initial_capital_warning: "% of availble capital",

      input_monthly_contribution: "Contribución Mensual",

      select_goal: "Objetivo de Valor",
      input_goal_option_1: "Carro nuevo",
      goal_option_1_calc_result: "Su objetivo de comprar un carro nuevo",
      input_goal_option_2: "Iniciar un negocio",
      goal_option_2_calc_result: "Su objetivo de iniciar un negocio",
      input_goal_option_3: "Casa nueva",
      goal_option_3_calc_result: "Tu objetivo de comprar una casa nueva",
      input_goal_option_4: "Estudios",
      goal_option_4_calc_result: "Su objetivo de pagar sus estudios",
      
      input_goal_option_5: "Otro",
      goal_option_5_calc_result: "Su otro objetivo",
      
      input_target_value: "Meta",
      input_invesment_horizon: "Horizonte de Inversión",

      congratsMessageCalc: `¡Felicitaciones! `,
      congratsMessageCalcFinal: ` es alcanzable.`,

      calc_footer_description: `Esta calculadora se creó con fines de presentación y no puede copiarse, usarse ni divulgarse como asesoramiento de inversión.`,
      calc_button: `Calcular`,

      calc_result_title: `Resultado`,
      total_invested: `Inversión total: `,
      future_Value: `Valor final: `,
      gain: `Ganancias: `,
      calc_footer_message: `El resultado muestra ganancias estimadas basadas en el desempeño anterior de BLUE.`,
      almost_there_negative_calc_result:
        "¡Casi ahí! Cambie las entradas para llegar a su objetivo.",
    },
  },

  staking: {
    title_section: "Staking",
    description_section:
      "Criptomonedas en “Staking” y sus recompensas anuales promedio.",
  },

  // calc: {
  //   title_section: "Calculadora de Inversion",
  //   description_section: "Aprenda cuánto y por cuánto tiempo invertir.",
  // },

  txt_button_1_know_section: "Compre Ahora",
  txt_indicate_know_section:
    "Cada vez que sus referidos compren BLUE tokens, ambos recibirán bonos y recompensas en BLUE",
  txt_button_2_know_section: "Aprenda más",

  support_organization: {
    title:
      "Blue Token es un orgulloso patrocinador de Organizaciones sin fines de lucro como Bitgive y Blockchain for Humanity",
    description:
      "Un porcentaje de nuestras ventas es donado a estos nobles proyectos.",
    bitgive_description:
      "Bitgive es una organización sin fines de lucro que usa el poder de Bitcoin y la tecnología blockchain para el mejoramiento de la salud pública y el ambiente a nivel mundial.",
    b4h_description:
      "B4H es una organización internacional sin fines de lucro que da apoyo y provee de recursos a organizaciones que utilizan la tecnología blockchain para traer cambios positivos a la Humanidad",
  },

  faq_home: {
    title_section_separate: "¿Aún tiene preguntas ?",
    title_section: "Dale click a nuestras preguntas frecuentes.",

    faq_blU_digital_token_q1: `¿Qué es BLUE?`,
    faq_blU_digital_token_a1: `BLUE es un token digital que representa una cesta de criptomonedas. <br> <br>BLUE, al igual que otros tokens digitales, se creó sobre un blockchain con un conjunto específico de reglas. BLUE usa el blockchain Binance Smart Chain (BSC).`,

    faq_blU_digital_token_q2: `¿Qué criptomonedas respaldan BLUE?`,
    faq_blU_digital_token_a2: `Los componentes BLUE incluyen las 10 principales criptomonedas clasificadas por capitalización de mercado y otros activos digitales con un fuerte potencial de crecimiento.`,

    faq_blU_digital_token_q3: `¿Cuántos tokens BLUE se han creado?`,
    faq_blU_digital_token_a3: `BLUE es creado en el momento de la compra. Puede acceder a nuestra plataforma y el explorador de tokens para ver la cantidad actual.`,

    faq_blU_digital_token_q4: `¿Cómo se controla el suministro de BLUE para evitar la dilución?`,
    faq_blU_digital_token_a4: `Los tokens se "queman" (destruyen) o se revenden a los clientes o exchanges con ordenes de tokens, evitando así la dilución.`,

    faq_button_redirect: `Ir a Preguntas Frecuentes`,
  },

  member_page: {
    banner_title: "Sea un Representante",
    how_works_title_section: "Como Funciona",
    how_works_subtitle_section: "Incremente sus ingresos promocionando Blue Token.",

    form_input_name: `Nombre`,
    form_input_email: `Email`,
    form_input_phone: `Mobile`,
    want_be_representative: `Quiero ser un representante`,

    title_form_dc_rep: `Genere ingresos! Conviértase en uno de nuestros BLUE DC REP`,

    content_dc_rep_mean: `DC Rep “Digital Currency Representative.” Nuestro programa le asiste para que pueda tener herramientas para generar ingresos extra promoviendo Blue Token. Estamos buscando personas con fuertes habilidades que busquen promover y mercadear BLUE. Si usted considera que esto se adapta a su perfil, envíenos su información en el formulario de arriba. Esperamos que sea parte nuestro creciente equipo.`,

    our_team_title_section: "Nuestro Equipo",
    our_team_subtitle_section: "Conozca a nuestros  líderes DC Rep!",

    our_representatives_title_section: "Our representatives",
    our_representatives_subtitle_section: "Top DC Reps",

    still_have_doubts_title_section: "Still have doubts?",

    wht_think_title_section: "¿Qué piensa de Blue Token?",
    wht_think_doubts_subtitle_section:
      "Sus comentarios nos permiten mejorar nuestros productos y son muy apreciados.",

    card_title_0: "Rocelo Lopes",
    card_linkedin_0: ``,
    card_subtitle_0:
      "Rocelo Lopes is the founder and CEO of Digital Horizon, a company that specializes in creating blockchain solutions for large and small businesses. Based in El Salvador",
    card_content_0: `since the legalization of Bitcoin, Digital Horizon is quickly growing to become a leader in cryptocurrency services in Latin America. Rocelo is known in the industry for his ingenious cryptocurrency products such as Blue Token and Swapix. Blue Token has experienced tremendous success since its creation in 2018, while SwaPix is currently a major hit in the Brazilian market. Rocelo has a passion for inclusion and financial freedom while maintaining a strict client privacy policy, which is why Digital Horizon products are growing rapidly under his vision and leadership.`,

    card_title_1: "Carlos Sabogal",
    card_linkedin_1: ``,
    card_subtitle_1:
      "Carlo Sabogal is the project manager and chief market analyst at Blue Token. He was the chief architect of the product, being responsible for its launch in 2018.",
    card_content_1: `Carlo is very passionate about cryptocurrencies, and is devoted to crypto education and training. Carlo majored in both Finance and International Business at Florida Atlantic University (FAU) in the United States. He plans to return to FAU in the future as a professor, to educate the next generation on the financial applications of blockchain technology.`,

    card_title_2: "Matt Brett",
    card_linkedin_2: `https://www.linkedin.com/in/matthew-brett-62b79840/`,
    // card_subtitle_2:"Matt es un profesional auto motivado y orientado al éxito con muchos años de experiencia y éxito en roles de ventas y liderazgo. Su experiencia en la adquisición",
    card_subtitle_2:"Matt Brett es un profesional motivado e impulsado al éxito con muchos años de experiencia y éxito en puestos de ventas y liderazgo en la venta de soluciones. Su experiencia",
    card_content_2: `con la adquisición de nuevos clientes en finanzas y bienes raíces fraccionarios lo ha convertido en un activo para nuestro equipo a medida que continúa educándose a sí mismo y a otros en este nuevo y emocionante ecosistema.`,

    card_title_3: "Anilson Moretti",
    card_linkedin_3: `https://www.linkedin.com/in/anilsonmoretti/`,
    // card_subtitle_3: `Moretti has worked for 15 years in top-tier banks in Brazil and has solid knowledge of the financial market, foreign exchange operations and trade finance operations.`,
    card_subtitle_3: `Anilson Moretti ha trabajado durante 15 años en bancos de primer nivel en Brasil y tiene un sólido conocimiento del mercado financiero, operaciones de cambio de divisas `,
    card_content_3: `y operaciones de financiamiento comercial. Es el fundador de una empresa de servicios financieros y ayuda a muchas personas con el desarrollo personal y el ingreso al mercado de criptomonedas con Blue Token. “¡La forma en que manejamos el dinero cambiará mucho en los próximos 30 años!”`,

    card_title_4: 'Adrian Zamora',
    card_linkedin_4: `https://www.linkedin.com/in/adrian-zamora-abb357217/`,
    card_twitter_4: `https://twitter.com/HiroshiCrypto`,
    card_subtitle_4: `Adrián es educador y conferencista en criptomonedas desde 2018. Se ha desempeñado como asesor en temas de criptomonedas para el Ministerio de Tecnología de`,
    card_content_4: `Costa Rica. Adrian tiene experiencia en Derecho Público y Economía. En sus propias palabras: "Blue Token es la mejor manera de aprender sobre las criptomonedas e invertir al mismo tiempo".`,

    dc_rep_card_title_0: "Daniel Bennet",
    dc_rep_card_subtitle_0:
      "Dan Bennett acredita muito no valor de investir em ativos digitais e na tecnologia blockchain subjacente. Essa tecnologia fornecerá a governança computacional do nosso dinheiro.",
    dc_rep_card_content_0: `Blockchain já está sendo usado em blockchain de cadeia de suprimentos, blockchain imobiliário e toda uma série de registros de armazenamento em registros médicos e outros registros governamentais que precisam ser armazenados de maneira imutável e sem confiança. Há valor real investindo em ativos digitais. Estar no início da curva de adoção. Isso provará ser muito lucrativo para aqueles que optarem por ver isso como uma ferramenta de investimento de longo prazo.`,

    dc_rep_card_title_1: "Loick Masunda",
    dc_rep_card_subtitle_1:
      "Loick Masunda é um ex-jogador de futebol profissional. Ele passou os últimos 4 anos como agente independente no setor da indústria financeira. Sua experiência o levou a ter sucesso na Blue devido à sua",
    dc_rep_card_content_1: `capacidade de liderar equipes, fazer networking e construir riqueza para seus clientes. Em suas próprias palavras: “O Blu Token é um produto de investimento incrível que atende a muitas pessoas, e acredito que o serviço a muitos leva à grandeza”.`,
  
    title_section_calculator: `Calculadora de ganancias`,
    subtitle_section_calculator: `¡Calcula tus recompensas!`,


    calc_reward_title_1: `BLUE Vendido`,
    calc_reward_title_2: `Promedio de Ganos`,
    calc_reward_title_3: `Recompensas estimadas`,
    calc_reward_title_4: `Valor atual`,
    calc_reward_title_5: `Valor futuro esperado`,

    tootip_calc_reward: `Valor esperado usando {calculateInvestment}% de rendimiento promedio mensual durante 3 años.`,

    calc_reward_content_footer: `Las recompensas se estiman a partir de ventas anteriores; no están garantizadas.`,
  },

  pool: {
    page_title: `Blue Pool`,
    card_head_description: `El pool está actualmente activo. Participe, refiera y gane su parte.`,
    card_head_description_link: `Learn more.`,
    pool_rewards: `Recompensas del Pool de {month}`,
    pool_closes_in: `EL POOL CIERRA EN:`,
    pool_leaderboard: `Tabla de líderes`,
    referralVolume: "Volumen de referencias",
    last_month_winners: `Ganadores del mes pasado`,
    reward: "% de Recompensa",
    estimated: "Estimado",
    pool_footer_description: `Nobody has qualified yet to earn the pool rewards. Be the first and win!`,

    caculating: "calculating...",
    checkAgainSoon: "Pool is being calculated and will be posted soon.",
    activePool: `El pool está actualmente activo. Participe, refiera y gane su parte. <a href=https://youtu.be/NujOX3n7d-g target=_blank style="color:#F7921E; text-decoration:underline">Aprenda más.</a>`,
    poolRewards: "{month} Pool Rewards:",
    poolCloses: "Pool closes in:",
    leaderboard: "Leaderboard",
    nobodyQualified:
      "Nobody has qualified yet to earn the pool rewards. Be the first and win!",
  },

  tutorials_home: {
    title_section_separate: "Tutoriales",
    title_section: "Vea nuestros tutoriales y aprenda más",
    button_watch_more: "Quiero ver mas tutoriales",
  },

  footer_sales_email: "Haga preguntas en support@bluetoken.io",
  sales_email: "support@bluetoken.io",

  feesAndMinimus: {
    titleBanner: "Tarifas y Mínimos",
    title_section_separate: `Nuestro programa de tarifas es más adecuado para titulares a largo plazo.`,

    item_hexagon_title_0: "Tarifa de Custodia",
    item_hexagon_value_0: "6%",
    item_hexagon_subtitle_0:
      "Tarifa % anual sobre el valor total de <br> criptomonedas bajo custodia.",

    item_hexagon_title_1: "Tarifa de Exchange",
    item_hexagon_value_1: "2%",
    item_hexagon_subtitle_1: `Tarifa única para cubrir costos de <br>exchange y transferencia, pagada <br> en cripto.`,

    item_hexagon_title_2: "Compra mínima",
    item_hexagon_value_2: "20 USDT",
    item_hexagon_subtitle_2: `Cantidad mínima aceptada<br> para compras.`,

    item_hexagon_title_3: "Tarifa de Redención",
    item_hexagon_value_3: "1%",
    item_hexagon_value_subtitle_3:
      "Tarifa mínima de redención en montos de 50 USDT o más.",
    item_hexagon_subtitle_3: `
    Mínimo de 2 USDT em valores <br> inferiores a 50 USDT.<br>
    Mínimo de 50 USDT em valores <br> superiores a 50 USDT.

    `,

    item_hexagon_title_4: "Período mínimo <br>de participación",
    item_hexagon_value_4: "90 días",

    item_hexagon_title_5: "Redención Mínima",
    item_hexagon_value_5: "20 USDT",
    // item_hexagon_value_subtitle_5: "(Approx. 0.002 BTC)",
    item_hexagon_subtitle_5: `Una vez cada 7 días`,

    item_hexagon_title_6: "Tarifa de Depósito",
    item_hexagon_value_6: "ninguno",
    // item_hexagon_subtitle_6: `Taxa única para cobrir despesas <br> de negociação e transferência, <br> paga em criptomoeda.`,

    item_hexagon_title_7: "Tarifa de Retiro",
    item_hexagon_value_7: "ninguno",
    // item_hexagon_value_subtitle_7: "(Approx. 4.78 USDT)",

    item_hexagon_title_8: "Tarifa de <br> transacciones <br> externas",
    item_hexagon_value_8: "6%",
    item_hexagon_subtitle_8: `Tarifa base, pagada en BLUE, <br> en transacciones realizadas <br> fuera de nuestra <br> plataforma`,
  },

  blue_club: {
    banner_title: "Blue Club",
    benefits_title: "Beneficios:",
    blue_club_title_section_separate: "Blue Club",
    blue_club_subtitle_section_separate: "Un  NFT de membresía al Blue Club es dado a usuarios con una compra de 20 tokens o más.",

    blue_club_view_nft: `Ver NFT`,

    benefits_item_1: "NFT del Blue Club (Token no fungible).",
    benefits_item_2:
      "Exclusivo servicios de consultoría por parte de nuestro equipo.",
    benefits_item_3: "Prioridad/ Acceso VIP a todos nuestros eventos",
    benefits_item_4:
      "Recompensas de Nuestras asociaciones (airdrops, rifas, promociones).",
    benefits_item_5: "Regalos (Camisetas, Gorras y demás artículos).",
    benefits_item_6: "Fiestas y eventos de networking exclusivos.",
    button_join_now: "Quiero ser parte",
  },

  privacy_terms: {
    banner_title: `Privacidad`,
  },

  faq: {
    banner_title: "FAQs",
    title: "Preguntas Frecuentes",
    frequently_asked_questions_about: `Preguntas Frecuentes`,
    watch_tutorials: `Ver Tutoriales`,
    //faq...............................................

    // ........................................

    // .............................................
    faq_blU_digital_token: `Token BLUE`,
    faq_blU_digital_token_q1: `¿Qué es BLUE?`,
    faq_blU_digital_token_a1: `BLUE es un token digital que representa una cesta de criptomonedas. <br><br>BLUE, al igual que otros tokens digitales, se creó sobre un blockchain con un conjunto específico de reglas. BLUE usa el blockchain Binance Smart Chain (BSC).`,
    faq_blU_digital_token_q2: `¿Qué criptomonedas respaldan BLUE?`,
    faq_blU_digital_token_a2: `Los componentes BLUE incluyen las 10 principales criptomonedas clasificadas por capitalización de mercado y otros activos digitales con un fuerte potencial de crecimiento.`,
    faq_blU_digital_token_q3: `¿Cuántos tokens BLUE se han creado?`,
    faq_blU_digital_token_a3: `BLUE es creado en el momento de la compra. Puede acceder a nuestra plataforma y el explorador de tokens para ver la cantidad actual.`,
    faq_blU_digital_token_q4: `¿Cómo se controla el suministro de BLUE para evitar la dilución?`,
    faq_blU_digital_token_a4: `Los tokens se "queman" (destruyen) o se revenden a los clientes o exchanges con ordenes de tokens, evitando así la dilución.`,
    faq_blU_digital_token_q5: `¿Dónde guardo mis tokens BLUE?`,
    faq_blU_digital_token_a5: `Actualmente, se pueden almacenar en la plataforma Blue y en la SmartWallet de Stratum. A medida que se desarrolla el proyecto y otros exchanges comienzan a respaldar nuestro token, BLUE podrá usarse como cualquier otra criptomoneda, para pagos y transferencias.`,
    // .............................................

    faq_portfolio: `Basket`,
    faq_portfolio_q1: `¿Cómo se compone el portafolio?`,
    faq_portfolio_a1: `
     - El 90% del portafolio está compuesto por las 10 principales criptomonedas, clasificadas por capitalización de mercado.<br>
     - El 5% del portafolio está compuesto por 2 criptomonedas de nuestra elección.<br>
     - El 5% del portafolio está compuesto por 2 criptomonedas elegidas por los usuarios mediante votación.
     <br><br>
     La documentación sobre nuestra metodología y los componentes de nuestra cartera siempre se publican en nuestro website.`,
    faq_portfolio_q2: `¿Qué criptomonedas están en el "top 10"?`,
    faq_portfolio_a2: `Las criptomonedas se clasifican según su capitalización de mercado (su oferta x su precio). Seleccionamos las 10 principales criptomonedas para el 90% de nuestro portafolio, y revisamos el índice cada trimestre.`,
    faq_portfolio_q3: `¿Cómo se reparten los fondos entre estas 10 principales criptomonedas?`,
    faq_portfolio_a3: `Fondos se distribuyen de acuerdo con la capitalización de mercado de cada activo y se limitan al 25% del índice (22.5% del portafolio, excepto Bitcoin, que se limita al 50% del portafolio.`,
    faq_portfolio_q4: `¿Con qué frecuencia se actualiza el portafolio?`,
    faq_portfolio_a4: `Actualizamos los componentes de la cartera cada 90 días. Revisamos las valoraciones actuales del mercado y, si se considera necesario, reemplazamos las criptomonedas en el portafolio.

     <br><br>
     Actualizamos los pesos de la cartera cada mes. Por ejemplo, si Litecoin se aprecia en valor, su peso en la cartera aumentará.`,
    faq_portfolio_q5: `¿Cómo eliges las 2 criptomonedas para el 5% del portafolio? `,
    faq_portfolio_a5: `Buscamos criptomonedas que ofrezcan un alto potencial de ganancias, ya sea por su apreciación o por sus recompensas de red.`,
    faq_portfolio_q6: `¿Cómo elige el 5% restante de la cartera?`,
    faq_portfolio_a6: `Nuestros usuarios eligen dos criptomonedas a través de un proceso de votación.`,
    faq_portfolio_q7: `¿Blue participa en distribuciones de red?`,
    faq_portfolio_a7: `Sí. Participamos en forks, staking y airdrops. Las participaciones son evaluadas caso por caso, tomadas solo si son beneficiosas para nuestros usuarios.`,
    faq_portfolio_q8: `¿Cómo comparten distribuciones de red?`,
    faq_portfolio_a8: `Agregamos distribuciones de red al portafolio. Para maximizar las ganancias y reducir los gastos, el valor se agrega caso por caso. La mayoría se distribuirá diariamente y se informará trimestralmente.`,
    faq_portfolio_q9: `¿Cómo comparte la información sobre el BLUE, como los informes de ingresos y manejo de fondos?`,
    faq_portfolio_a9: `Toda la información está disponible para nuestros usuarios dentro de nuestra plataforma.`,
    // .............................

    faq_purchases: `Compras`,

    faq_purchases_q1: `¿Cómo compro BLUE?`,
    faq_purchases_a1: `Acceda a su cuenta y seleccione cuántos tokens BLUE desea comprar. Luego haga clic en Pagar y envíe el pago a la dirección mostrada.`,
    faq_purchases_q2: `No tengo Bitcoin, ¿puedo usar efectivo?`,
    faq_purchases_a2: `Solo aceptamos criptomonedas. Sin embargo, ofrecemos opciones de pago con tarjetas de crédito via terceros. Simplemente siga las instrucciones en el momento del pago. También puedes comprar crypto en <a href="https://pix.bluetoken.io" target="_blank"> pix.bluetoken.io</a> y luego enviarlo a la plataforma BLUE para adquirir los tokens.`,
    faq_purchases_q3: `¿Cuál es el precio de un token BLUE?   `,
    faq_purchases_a3: `El precio cambia en tiempo real, siempre publicado en nuestra plataforma.`,
    faq_purchases_q4: `¿Hay una compra mínima?`,
    faq_purchases_a4: `Sí, la compra mínima es {minPurchase} USDT`,
    faq_purchases_q5: `¿Cuáles son sus tarifas?`,
    faq_purchases_a5: `Hay una tarifa única de exchange del 2% y una tarifa de custodia anual del 6%. Acceda a nuestra página de tarifas y mínimos para obtener más información <a href="https://bluetoken.io/fees">aquí</a>.`,
    faq_purchases_q6: `¿Cualquiera puede comprar tokens?`,
    faq_purchases_a6: `No todos. No aceptamos ni alentamos a residentes o ciudadanos chinos, hongkoneses y estadounidenses a comprar BLUE.`,
    faq_purchases_q7: `¿Qué sucede después de comprar BLUE?`,
    faq_purchases_a7: `Su saldo BLUE reflejará la compra.`,
    faq_purchases_q8: `¿Hay un período mínimo de retención?`,
    faq_purchases_a8: `Sí. Debe retener sus tokens BLUE durante al menos 90 días.`,
    // .............................

    faq_redemptions: `Redenciones`,

    faq_redemptions_q1: `¿Cómo redimo BLUE?`,
    faq_redemptions_a1: `Redenciones no están disponibles hasta 90 días después de la compra. Una vez que finaliza el período de retención, puede cambiar sus BLUE por bitcoin utilizando nuestra plataforma`,
    faq_redemptions_q2: `¿Hay una redención mínima? `,
    faq_redemptions_a2: `Sí, actualmente es 20 USDT. Acceda a nuestra página de <a href="/Fees"> tarifas y mínimos</a> para obtener más información`,
    faq_redemptions_q3: `Cuales son las tarifas?    `,
    faq_redemptions_a3: `
    La tarifa de redención es actualmente {feePercent}% de la cantidad solicitada, con un mínimo de:<br>
    {feeAbsolute} USDT para grandes cantidades.<br>
    {smallFee} USDT para cantidades pequeñas.<br>
    Acceda a nuestro enlace a la página de tarifas y mínimos para obtener más información`,
    faq_redemptions_q4: `¿Qué sucede después de redimir BLUE?`,
    faq_redemptions_a4: `Su saldo BLUE reflejará la redención, y recibirá el valor redimido en USDT.`,
    // .............................

    faq_account: `Cuenta`,

    faq_account_q1: `¿Cómo creo una cuenta?`,
    faq_account_a1: `Regístrese en bluetoken.io usando una dirección de correo electrónico segura y una contraseña. Recibirá un correo electrónico con su ID de usuario y un enlace de activación (revise su carpeta de correo no deseado por si acaso). Debe hacer clic en el enlace de activación, y luego puede acceder a su cuenta utilizando su ID de usuario y contraseña.`,
    faq_account_q2: `¿Cómo recupero mi ID de usuario?`,
    faq_account_a2: `En la página de entrada hay un enlace para recuperar su ID. Haga clic en él y siga las instrucciones.`,
    faq_account_q3: `¿Cómo recupero mi contraseña?`,
    faq_account_a3: `En la página de entrada hay un enlace para recuperar su contraseña. Haga clic en él y siga las instrucciones.`,
    faq_account_q4: `¿Puedo recuperar mi contraseña poniéndome en contacto con soporte?`,
    faq_account_a4: `No. Para su seguridad, el equipo de soporte no tiene acceso a las contraseñas y nunca solicitará ni enviará una contraseña. La única forma de recuperar su contraseña es mediante nuestro sistema automatizado.`,
    faq_account_q5: `¿Puedo recuperar mi ID de usuario poniéndome en contacto con soporte`,
    faq_account_a5: `No. Por su seguridad, el equipo de soporte no comparte ID de usuario. La única forma de recuperar una identificación de usuario es utilizando nuestro sistema automatizado.`,

    // .............................
    faq_referral_benefits: `Beneficios de Referencia`,
    faq_referral_benefits_q1: `¿Cuáles son los beneficios de las referencias? `,
    faq_referral_benefits_a1: `Cuando compras un token y recomiendes a las personas que compren BLUE, obtienes una recompensa y ellos obtienen un bono. Las recompensas y bonificaciones se pagan en BLUE. Las recompensas y bonificaciones son ilimitadas. No importa cuántas veces sus referidos compren BLUE, siempre obtendrá una recompensa y ellos recibirán una bonificación.`,
    faq_referral_benefits_q2: `¿Cómo me registro en el programa de referidos? `,
    faq_referral_benefits_a2: `Después de crear su cuenta, tendrá acceso a su código de referencia en la página de referencias. Comparte este código para obtener recompensas en nuevos registros.<br><br>Para unirse a Blue Pool, cree un avatar en la página de Blue Pool.`,
    faq_referral_benefits_q3: `¿El programa de referencia afecta el valor del token?`,
    faq_referral_benefits_a3: `Los tokens se emiten a partir de los ingresos de la tarifa de venta, por lo que el valor de BLUE no se ve afectado por el programa de referencia.`,
    faq_referral_benefits_q4: `¿Cómo verifico mis pagos de referencias?`,
    faq_referral_benefits_a4: `Acceda a la página de referencias y siga sus recompensas por fecha y cantidad`,
    faq_referral_benefits_q5: `¿Qué es Blue Pool?`,
    faq_referral_benefits_a5: `Blue Pool es un programa adicional de beneficios de referencia que le permite competir por su parte de un gran premio acumulado. Cuantas más tokens compren sus amigos, mayor será su participación en el grupo. ¡Regístrese, acceda a la página del grupo y cree su avatar para comenzar! <a href="https://youtu.be/NujOX3n7d-g" target="_blank">Aprenda más</a>.`,

    // .............................
    faq_charity: `Caridad`,
    faq_charity_q1: `¿Qué organizaciones benéficas apoyamos?`,
    faq_charity_a1: `Blue Token apoya  BitGive y B4H (Blockchain for Humanity), dos organizaciones benéficas sin fines de lucro que utilizan la tecnología blockchain para causas humanitarias.`,
    faq_charity_q2: `¿Por qué Blue Token está involucrado con estas organizaciones benéficas?`,
    faq_charity_a2: `Creemos en el poder de la tecnología blockchain para mejorar la vida humana. Nuestra compañía, Digital Horizon, ha apoyado y siempre apoyará iniciativas inteligentes de blockchain que beneficien a la sociedad. Creemos que BitGive y B4H son organizaciones serias que trabajan en temas como la salud pública, la pobreza, el hambre, la educación y el medio ambiente.`,
    faq_charity_q3: `¿Cómo contribuye Blue Token?`,
    faq_charity_a3: `Donamos la mitad (50%) de nuestra tarifa de venta en compras realizadas sin una referencia.`,
    faq_charity_q4: `¿Las donaciones afectan el valor BLUE?`,
    faq_charity_a4: `No. Usamos un porcentaje de las tarifas ganadas en las ventas.`
  },

  footer: {
    link_title_1: "Hoja Informativa",
    link_title_2: "Libro Blanco",
    link_title_3: "Ver Tutoriales",
    link_title_4: "Tarifas y Mínimos",
    link_title_5: "Términos y Condiciones",
    link_title_6: "Política de Privacidad",
    link_title_8: "Contacto",

    wacthPlayList: `https://youtube.com/playlist?list=PL7vxL5_tbHIgTaIt25mwfCX5d_HuJms82`,
    
    link_explorer_text: `Explorador`,
    link_explorer: `https://bscscan.com/token/0xE2F3Db52F7065220e113014f23f5DD4c7bafBAc2`,
  },

  contact_page: {
    banner_title: `Contacto`,
    title_section: `¿Hablemos?`,
    content_section: `Póngase en contacto.`,
  },

  terms_and_conditions: {
    banner_title: `Términos y Condiciones`,
    condition_title_01: `TERMS AND CONDITIONS OF SALE OF THE BLUE TOKEN`,
    condition_description_01: `
    These terms and conditions of use govern and apply to the "Blue Token", also
    referred in this document as "BLUE", a product marketed by DIGITAL HORIZON
    BLOCKCHAIN TECHNOLOGY, based at El Salvador, Registration No. 309482-6, with
    address at Calle Cuscatlán y 83 Av. Sur, Colonia Escalón, #375, San Salvador, El
    Salvador, hereinafter referred to simply as "Digital Horizon." <br> <br>

    By accepting these terms, you agree to all of the terms and conditions of use
    described below, being aware that in making the adhesion, all your rights and
    obligations will be bound, becoming such rules and conditions of use indispensable
    for contracting the services. <br> <br>

    Therefore, before accepting, make sure that you have understood and agreed
    to the full contents of these terms and conditions of use.
    This document is not a solicitation of investment and does not in any way
    refer to an offer of securities in any jurisdiction. This document describes the sale of
    BLUE tokens through the Platform called "Blue". <br><br>
    Digital Horizon reserves the right to change the terms and conditions at any
    time and such changes will take effect immediately upon posting on the site. By
    continuing to use the Website, you accept such changes. If you do not agree to
    these terms and conditions, you must refrain from using the site and not acquire
    BLUE tokens.<br> <br>

    By acquiring BLUE tokens, to the extent permitted by law, you agree that
    none of the employees, officers, directors, contractors, consultants, shareholders,
    suppliers, service providers and their respective past, present and future
    employees, controlling companies, subsidiaries , affiliates, agents, representatives,
    predecessors, successors and assigns are liable for any special, incidental or
    consequential losses or damages arising out of or in any way connected with the
    sale of the Blue Token, including losses associated with the terms set forth below.
    <br><br>

    The user declares that he has sufficient knowledge to operate with the Blue
    platform, there being no doubt or fact that may characterize his hypothesis in
    relation to the same, possessing full and total enjoyment of his civil capacity, as
    well as being able to practice any and all necessary acts of validity of operations.
    <br><br>

    You should have sufficient understanding of the functionality, usage, storage,
    transmission mechanisms, and complexities associated with cryptographic tokens
    such as Bitcoin, Ether, Tether as well as Blockchain-based software systems in
    general. If any of the following terms are unacceptable to the user, the user should
    not purchase BLUE tokens.
    <br><br>

    Digital Horizon shall not be liable for any direct or indirect loss of profits, loss
    of data or damages resulting from technical errors, failures, interrupted internet
    connection, or the like.
    <br><br>

    You acknowledge that Blue is not an investment consultant, but only a
    trading facility of the Blue Token, and has no responsibility for the use and
    investments made by Users.
    <br><br>

    THE BUYER AGREES TO BUY, AND THE COMPANY AGREES TO SELL BLUE
    TOKENS ACCORDING TO THE FOLLOWING TERMS:
    `,

    condition_title_02: `Definitions:`,
    condition_description_02: `"Blue Token" or "BLUE" is a digital token marketed by Digital Horizon.
    <br><br>

    "Blue" is the name of the platform where the Blue Token is sold, using the web
    domain www.bluetoken.io.
    <br><br>

    "Bitcoin" or "BTC" means digital currency and payment system using peer to peer
    transactions verified by the network nodes and registering in a public distributed
    ledger called blockchain.
    <br><br>

    "User" is any individual or legal entity that adheres to this Agreement and uses the
    Site to acquire BLUE tokens, as well as other services and information offered by
    Blue.
    <br><br>

    "Digital Currency" or "Cryptocurrency" is a digital currency or digital commodity
    that uses the principle of decentralization based on network technology Blockchain
    traded on the site.
    <br><br>

    "Blockchain" is a public and shared book where all cryptocurrency operations are
    confirmed and recorded.
    <br><br>

    "Loss" means damages, claims, losses, injuries, delays, accidents, costs, business
    interruption, expenses (including, without limitation, legal fees or costs of any claim
    or proceeding), any incidental , indirect, any loss of goodwill, profits or revenue or
    any other economic loss, any loss of crypto or digital assets, any work stoppage, loss of data, failure or malfunction of the computer, or any other commercial or other loss.
    <br><br>

    "Privacy Policy" means the document that describes the methods by which Blue
    collects, uses and releases information collected from donors available on the site at
    www.bluetoken.io/privacy.
    <br><br>

    "White Paper" means the document that describes the Blue Token, available on the
    website at www.bluetoken.io.
    <br><br>

    "Site", "Website" and, or "Platform" means www.bluetoken.io including all
    subdomains and all of its respective pages.
    `,

    condition_title_03: `1. Site`,
    condition_description_03: `1.1 Digital Horizon reserves the right, in its sole and absolute discretion and without prior notice, to change, modify, add or remove features, or change any content on the site, block or restrict access to, or terminate, withdraw or suspend the use of the site or any part of the site. The User will be deemed to have accepted and agreed to any change if he/she accesses or uses the site after we have posted changes to the site. Digital Horizon shall not be liable for any loss that may be incurred as a result of such action.
    <br><br>

    1.2. User hereby acknowledges that he/she is not eligible to purchase BLUE tokens
    if he/she is a citizen, resident (taxed or otherwise), US green card holder of the
    United States, and/or resident or citizen of the People's Republic of China or Hong
    Kong.
    <br><br>

    1.3. The site may contain hyperlinks to sites that are not maintained or controlled
    by Digital Horizon. All hyperlinks to these sites are provided as a convenience to
    you. The use of hyperlinks and access to these sites is entirely at the User's own
    risk. Digital Horizon disclaims any liability and responsibility for any third-party content contained in sites linked to the Site and will not be liable for any loss arising out of your access to or use of such sites.
    <br><br>

    1.4 The inclusion of any hyperlink does not imply endorsement by Digital Horizon of
    such sites. Under no circumstances will Digital Horizon be deemed to be affiliated
    with or affiliated in any way with any trade or service marks, logos, insignia or
    other devices used or displayed on sites to which the Site is linked.`,

    condition_title_04: `2. Conditions for Selling the Blue Token`,
    condition_description_04: `2.1. BLUE is a digital token that represents a basket of cryptocurrencies.
    <br><br>

    2.2. Cryptocurrencies are acquired through the sale of the digital token BLUE.
    Therefore, the value of the BLUE Token is tied to the value of the underlying
    cryptocurrencies.
    <br><br>
    
    2.3. BLUE tokens can be purchased, redeemed and transferred through the Blue
    Platform. BLUE tokens are issued in a public blockchain called Binance Smart Chain
    (BSC).
    <br><br>
    2.4. Blue was designed to bring convenience to cryptocurrency acquisition.
    Individuals can acquire the equivalent of several cryptocurrencies by holding a
    single token. Tasks such as trading, storage, research and analysis, security and
    traceability are delegated to Blue. Custodial costs are shared among all token
    holders to maximize profits, taking advantage of economies of scale and scope as
    the project grows.
    <br><br>

    2.5. As the adoption of tokens and third-party wallet support increases, BLUE
    tokens may be used in daily activities such as bill payments, cell-phone recharges,
    and prepaying bank cards.
    <br><br>

    2.6. User must have legal permission to receive, maintain and make use of the Blue
    Token in the User's jurisdiction and in any other relevant jurisdiction.
    <br><br>

    2.7. User will provide Blue with all the information and documentation necessary to
    enable him/her to accept the purchase of BLUE in the Platform. User declares
    himself / herself fully responsible for the information entered on Blue Website,
    through the Register, Account, Permissions, Passwords and usage instructions.
    Digital Horizon, under no circumstances, will be responsible for the content
    (information, passwords, copies of information, etc.) provided by the User.
    <br><br>

    2.8. The User is obliged civilly and criminally for the supply of all his/her data (in an updated and complete way). The User is also aware that the provision of false information or improper use of third party data in its own name constitutes a crime, and the User is solely and exclusively responsible for responding to the competent authorities and any damages caused, exempting Digital Horizon from all and any liability whatsoever.
    <br><br>

    2.9. Blue may, in its sole discretion, request from Users additional documents and
    information for the purpose of maintaining the Register and which may be
    reasonably necessary for Digital Horizon to comply with its legal, regulatory and
    contractual obligations, including but not limited to, any obligation against money
    laundering.
    <br><br>

    2.10. User claims to be of sufficient age (if the user is an individual) to legally
    obtain the Blue Token, and being aware of no other legal reason that prevents
    him/her from obtaining it.
    <br><br>

    2.11. When acquiring the Blue Token, the User is aware that he/she is purchasing a
    cryptocurrency which is not regulated.
    <br><br>

    2.12. User declares that he/she does not obtain or use the Blue Token for any
    unlawful purpose.
    <br><br>

    2.13. User waives any right he/she may have or obtain to participate in a class
    action or ample arbitration against any entity or individual involved in the sale of the Blue Token.
    <br><br>

    2.14. The acquisition of the Blue Token does not involve the purchase or receipt of
    shares, ownership or any equivalent in any existing or future company, company or
    other public or private entity in any jurisdiction.
    <br><br>

    2.15. To the extent permitted by law, Digital Horizon makes no warranty, express or
    implied, regarding the future success of the Blue Token.
    <br><br>

    2.16. The User is responsible for all tax, fiscal and legal obligations arising out of his/her operations and use of the Blue services. In no event shall Blue be liable for any taxes or obligations of the User.
    <br><br>
    2.17. Blue may, in its sole discretion, refuse any registration request and / or
    suspend / cancel an existing User, especially in the event of: (a) disobedience or
    violation of any provision contained in this Agreement; (b) impossibility of
    verification, incompatibility or inconsistency in the data of the User's identity or finding falsehood in any of the information provided; (c) practice by the User of fraudulent, malicious acts or the adoption of any behavior that, at Blue's discretion, is incompatible with the objectives of the Site; (d) other situations that are at Blue's discretion.
    <br><br>

    2.18. User furthermore is aware that if his/her Registration is suspended or
    canceled for any of the reasons provided in this Terms of Use, all Token Purchases
    or Sales Orders, which have not yet been executed, will be automatically canceled,
    without any liability imputed to Digital Horizon, which will always act for the safety of all Users, including the suspended or canceled User.`,

    condition_title_05: `3. Overview of the Blue Token Sale`,
    condition_description_05: `3.1. BLUE tokens are hosted using the Binance Smart Chain (BSC) public blockchain.
    <br><br>

    3.2. BLUE tokens are generated on demand, which means that a token can only be
    generated with a purchase on the Blue Platform. BLUE tokens have an unlimited
    supply, which means there is no set limit for the number of Users.
    <br><br>

    3.3. Each sale of BLUE incurs a 2% trading fee of the purchase total.
    <br><br>

    3.4. Tokens can only be redeemed on the Blue Platform.
    <br><br>

    3.5. BLUE tokens are burned or resold at redemption, thus avoiding dilution.
    <br><br>

    3.6. To avoid unnecessary turnover costs, which negatively affect the value of the
    token, redemptions fall below a minimum retention period of 90 (ninety) days in
    the Blue wallet. In addition, redemptions are only available once a week, at days
    and times to be posted on the Blue Platform website.
    <br><br>
    3.7. Blue imposes redemption fees and minimums if it deems it necessary to cover
    and minimize trading costs and avoid devaluation of the token. Fees and minimums
    are always posted on the Blue website.
    <br><br>
    
    3.8. BLUE is a public token and therefore can be used freely by its holders and third parties. People and institutions, such as exchanges, trading platforms and
    cryptocurrency wallets, can make transactions using the token.
    <br><br>
    
    3.9. Digital Horizon is not responsible for any issues arising from third party
    transactions involving the Blue Platform and the Blue Token, as they occur outside
    of our control.`,

    condition_title_06: `4. Referral Rewards`,
    condition_description_06: `4.1. Referral rewards are awarded to holders of tokens who refer new buyers on the Blue Platform. These prizes are paid in BLUE.
    <br><br>

    4.2. These rewards are issued from funds collected as a percentage of the initial
    trade fee.
    <br><br>

    4.3. Rewards are not limited to quantity and frequency. Whenever the
    aforementioned buys a token, the referrer will be rewarded.
    <br><br>

    4.4. The reward prize is currently set at 1% of the purchase value.
    <br><br>

    4.5. The individuals who participate in the referral rewards program, pursuant to
    this contract, declare to be aware that they have no employment or responsibility
    bond, on the part of Digital Horizon in relation to the execution of activities to carry out the business, doing so in a voluntary manner. Digital Horizon reserves the right to terminate the rewards program at any time.`,

    condition_title_07: `5. Buyer Bonus`,
    condition_description_07: `5.1. Buyer bonuses are given to token buyers referred by token holders. These prizes are paid in BLUE.
    <br><br>

    5.2. Bonuses are issued from funds collected as a percentage of the initial trade
    fee.
    <br><br>

    5.3. Bonuses are not limited to frequency. Every time the aforementioned buys a
    token, a bonus is given.
    <br><br>

    5.4. The bonus prize is currently set at 0.5% of the purchase value.
    <br><br>

    5.5. Digital Horizon reserves the right to terminate the bonus program at any time.`,

    condition_title_08: `6. Blue Pool`,
    condition_description_08: `6.1. Blue Pool is a program created to further incentivize referrals.
    <br><br>

    6.2. Every month, a "pool" is created to reward users on their referrals' purchases.
    <br><br>

    6.3. Each pool lasts a month. After the month is over, a new pool is created.
    <br><br>

    6.4. To participate, users must join the program and create an avatar.
    <br><br>

    6.5. A minimum referral purchase volume is required, which is displayed in the
    platform.
    <br><br>

    6.6. Referral purchase volume is set back to zero at the end of each pool cycle.
    <br><br>

    6.7. Rewards are paid in BLUE to participants at the end of the month, within 7 to
    10 business days after the pool has reset.
    <br><br>

    6.8. A leaderboard table keeps all participants informed about their ranking and
    estimated rewards.
    <br><br>
    
    6.9. The Blue Pool is funded by a percentage of our custodial fees, determined by
    the Blue management team at its discretion.
    <br><br>

    6.10. The individuals who participate in the Blue Pool program, pursuant to this
    contract, declare to be aware that they have no employment or responsibility bond,
    on the part of Digital Horizon in relation to the execution of activities to carry out the business, doing so in a voluntary manner. Digital Horizon reserves the right to terminate the Blue Pool program at any time.`,

    condition_title_09: `7. BLUE Net Value (Token Price)`,
    condition_description_09: `7.1. The net value of BLUE (BNV) or Token Price, is published in real time on the Blue Platform website. Token price is quoted in crypto.
    <br><br>

    7.2. Token price is calculated by dividing the net equity of all the BLUE components by the outstanding BLUE token supply.`,

    condition_title_10: `8. Component Price`,
    condition_description_10: `8.1. Component Price is the price of each specific cryptocurrency composing the BLUE basket.
    <br><br>

    8.2. Each component's price is determined by its last price traded on a selected
    crypto exchange for our intraday trading operations.
    <br><br>

    8.3. The exchange selection is based on trading conditions and costs at the time.
    Blue works on safe exchanges that best represent global market prices, and offer a
    cost-effective fee schedule.
    <br><br>

    8.4. Component prices are always on display on the Blue Website.`,

    condition_title_11: `9. Component Quantity`,
    condition_description_11: `9.1. Component Quantity is the amount of each specific cryptocurrency composing the BLUE basket. This includes current cryptocurrencies and excludes Network Distributions such as hard-forks, staking and airdrops.
    <br><br>

    9.2. Cryptocurrencies arising from hard-forks are included in the basket
      - as a basket component,
      - exchanged for Bitcoin and included in the basket as bitcoin,
      - or exchanged for the original forked crypto, and then included in the basket.
    <br><br>
    
    9.3. Cryptocurrencies earned from staking are accrued and included in the basket
    at the end of the corresponding quarter.
    <br><br>

    9.4. Cryptocurrencies earned on airdrops are accrued, and included in the basket
    once the crypto's accumulated value equals or exceeds 0.01BTC.
    <br><br>

    9.5. Blue reserves the right to retain 50% (fifty percent) of earnings related to
    network distributions (forks, airdrops, starking).
    <br><br>

    9.6 It is at the sole discretion of Blue's management to participate on Network
    Distributions (staking, airdrops, hard-forks). These are evaluated on a per-case
    basis.`,

    condition_title_12: `10. Component Value`,
    condition_description_12: `10.1. The value of each component is calculated by multiplying its Price by its Quantity.`,

    condition_title_13: `11. Gross Value of the Components (GVC)`,
    condition_description_13: `11.1. Gross Value (GVC) is calculated by adding up all component values. This is the gross value of the entire basket.`,

    condition_title_14: `12. Custodial Fee`,
    condition_description_14: `12.1. A custodial fee of 6% (six percent) per year is applied, and is charged on the GVC.
    <br><br>

    12.2. The said custodial fee is divided by 365 (three hundred and sixty-five) days
    and is charged in the rounded percentage of 0.01644% (zero point zero one six
    hundred and forty four percent).
    <br><br>

    12.3. The custodial fee is charged from the GVC every day at midnight (GMT -3).
    The custodial fee is charged in cryptocurrency. Component quantity is updated at
    the time the fee is charged.
    <br><br>

    12.4. The custodial fee is used to cover the development, legal, security, and
    managerial expenses of Blue.
    <br><br>

    12.5. These fees exclude trading expenses during the recomposition and
    rebalancing of the cryptocurrency basket, which are withdrawn from its holdings.
    <br><br>

    12.6. Blue may change the value of the custodial fee at any time by notifying users
    on the Platform.`,

    condition_title_15: `13. Net Equity`,
    condition_description_15: `13.1. Net Equity is calculated by subtracting the custodial fee from GVC.
    <br><br>
    
    13.2. Net Equity is divided by the token supply to arrive at the Blue Token price`,

    condition_title_16: `14. Taxation of the Blue Token`,
    condition_description_16: `14.1. Each User is responsible for all tax, tax and legal obligations arising from its operations on the Blue Platform. Under no circumstances may Digital Horizon be liable for any taxes or obligations of the User.`,

    condition_title_17: `15. Privacy`,
    condition_description_17: `15.1. Users may be contacted via email by Digital Horizon. These emails will only be informative. Digital Horizon will not request User’s personal information via email. See our Privacy Policy available on the Site for additional information.`,

    condition_title_18: `16. Security`,
    condition_description_18: `16.1. User declares that he / she is aware that he / she must take the necessary measures to carry out the operations with the greatest possible safety.
    <br><br>

    16.2. User must use reliable and secure server e-mail, with strong password,
    reliable and of its exclusive knowledge, avoiding to use the same password for
    other operations carried out on the Internet.
    <br><br>

    16.3. In addition, any third party gaining access to such private keys, including accessing the Platform login credentials, may be able to misappropriate the User's BLUE tokens. Any errors or defects caused by or otherwise related to the digital wallet or vault the User chooses to receive and store BLUE, including his/her own failure to maintain or properly use such digital wallet or safe, may also result in the loss of his/her tokens.
    <br><br>

    16.4. Preferably, it is recommended that users have a unique email address to use the Blue Platform.
    <br><br>
    

    16.5. Digital Horizon, in turn, uses the most modern security measures in order to ensure the protection of its data and its Users.
    <br><br>
    
    16.6. Each User is responsible for storing his/her password and PIN. In case of loss of the same, to carry out the recovery it will be necessary for the User to indicate the e-mail registered in the system.
    <br><br>

    16.7. Digital Horizon may suspend all or part of the services without prior notice to registered users on the occasion of theft of assets by hackers and other methods, as well as in cases of force majeure. If Digital Horizon, for its convenience, decides to suspend the provision of this service, will notify in advance the registered users.
    <br><br>
    
    16.8. Hackers or other malicious groups or organizations may attempt to interfere
    with the Blue Platform in a number of ways, including, but not limited to, malware
    attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing, and spoofing. In addition, because the platform is based on open source software, there is a risk that a third party or a member of Digital Horizon's team may intentionally or unintentionally introduce weaknesses in the platform's core
    infrastructure, which could adversely affect the Blue Platform and Blue Token,
    including its utilities.
    <br><br>

    16.9. Digital Horizon assumes no responsibility for damages caused to registered users based on the Terms specified herein.`,

    condition_title_19: `17. Force Majeure`,
    condition_description_19: `17.1. Refers as Force Majeure to events occurring beyond capabilities that may affect the execution of transactions, including, but not limited to: (a) earthquakes, hurricanes, floods, landslides, volcanic eruptions, and other natural disasters; (b) War, demonstrations, riots, terrorism, sabotage, embargo, and a general strike; (c) The Government's economic policy affecting directly; (d) Over the years, Digital Horizon has been performing its obligations in accordance with the laws and regulations that apply in connection with the occurrence of Force Majeure, so Digital Horizon will not indemnify or hold harmless any user or any other party for any risk, liability and any claim that may arise in connection with the delay and can not be implemented due to Force Majeure obligations.`,

    condition_title_20: `18. Disclaimer of Warranties`,
    condition_description_20: `18.1. User agrees and acknowledges that Digital Horizon or any member of the Blue
    team is not liable for any indirect, special, incidental, consequential or other losses of any kind, in connection with any offense, contract or otherwise (including, without limitation, loss of revenue, income or profits, and loss of use or data) arising out of or in connection with any acceptance or reliance on either the White Paper, these Terms and Conditions or any part thereof for you.`,

    condition_title_21: `19. Limitations Disclaimer`,
    condition_description_21: `19.1. Blue does not or intends to make, and hereby disclaims, any representation, warranty or commitment in any manner to any entity or person, including any representation, warranty or commitment regarding the accuracy and completeness of any information contained in White Paper and these Terms and Conditions.`,

    condition_title_22: `20. Severity`,
    condition_description_22: `20.1. The User and Digital Horizon agree that if any part of these Terms is found to be unlawful or unenforceable, in whole or in part, such provision will be, in respect of such jurisdiction, ineffective only to the extent that such invalidity or unenforceability determination without affecting its validity or enforceability in any other form or jurisdiction and without affecting the remaining provisions of the Terms, which shall continue to be in full force and effect.`,

    condition_title_23: `21. Site access issues and restrictions`,
    condition_description_23: `21.1. The services provided by Blue are available exclusively through the Internet, with the User having the necessary and appropriate equipment, such as computers, tablets and smartphones with properly licensed programs and applications (including antivirus).
    <br><br>

    21.2. Access to the Blue Platform is subject to falls, interruptions, delays and
    problems inherent to the use of the Internet, including when it is maintained
    (temporary unavailability, which will be previously informed on the Blue Platform).
    <br><br>

    21.3. Digital Horizon shall not be liable for any such interruptions or for any User access problems, such as limitation of the equipment or the navigation program. Likewise, Digital Horizon will not be liable for any viruses that may attack the Users' equipment.`,

    condition_title_24: `22. Risk`,
    condition_description_24: `22.1. Trading cryptocurrencies is a high risk activity where prices can change significantly over time. With respect to price fluctuations, the book value may be increased or decreased significantly at any time. All virtual currencies may have the potential to drastically change their value or even become meaningless / worthless. There is a high risk of loss as a result of buying, selling or trading in any market. Digital Horizon is not responsible for changes in the exchange variation of the value of the cryptocurrencies.
    <br><br>

    22.2. Cryptocurrency trading also has an additional risk that is not experienced by
    other currencies or other commodities in the market. Unlike most currencies that
    are guaranteed by the government or other legal institutions, or by gold and silver, virtual currency is a "virtual" currency solely guaranteed by technology and trust. There is no central bank that can control, protect the value of crypto in crisis, or print the currency.
    <br><br>

    22.3. Digital Horizon is not liable for any indirect, special, incidental, consequential or other damages of any kind, in civil liability, contract or otherwise (including, but not limited to, loss of revenue, profit or profits, and loss of use or data) arising out of or in connection with any acceptance or reliance on these Terms and Conditions.
    <br><br>

    22.4. As with other decentralized cryptographic tokens, BLUE is susceptible to
    miners' attacks in the course of validation of BLUE transactions at Blockchain,
    including, but not limited to, double-spend attacks, major mining power attacks,
    and selfish mining attacks . Any successful attack poses a risk to the Blue Platform and the Blue Token, including, but not limited to, the accurate execution and registration of transactions involving the Blue Token.
    <br><br>

    22.5. Blue Token's regulatory status of distributed accounting technology is unclear or unstable in many jurisdictions. It is difficult to predict how or whether regulatory agencies can apply existing regulations with respect to this technology and its applications, including the Blue Platform and the BLUE token. Likewise, it is difficult to predict how or whether legislatures or regulatory agencies can implement changes in law and regulation that affect distributed accounting technology and its applications, including the Blue Platform and the Blue Token. Regulatory actions may adversely impact the Blue Platform and the Blue Token in a number of ways, including for illustration purposes only, by determining that the purchase, sale, and delivery of BLUE constitutes illegal activity or that the Blue Token may become a regulated instrument requiring registration or licensing of such instruments or of some or all of the parties involved in the purchase, sale and delivery thereof. Digital Horizon may terminate operations in a jurisdiction in the event of regulatory actions, or changes in the law or regulation, render illegal the operation in such jurisdiction, or commercially undesirable to obtain the necessary regulatory approval(s) to operate in such jurisdiction.
    <br><br>

    22.6. If secondary Blue Token trading is facilitated by third party exchanges, such
    exchanges may be relatively new and subject to little or no regulatory oversight,
    making them more susceptible to fraud or manipulation. In addition, to the extent
    that third parties attribute an exchange value external to the BLUE (for example, as denominated in a digital or fiduciary currency), that value can be extremely volatile.
    <br><br>

    22.7. Unlike bank accounts or accounts in some other financial institutions, the Blue Token does not have insurance unless you specifically get private insurance to
    insure it. Thus, in the case of loss or loss of utility value, there is no public
    insurance or private insurance organized by Blue to offer your appeal.
    <br><br>

    22.8. Cryptographic tokens, such as the BLUE, are a new, untested technology. In
    addition to the risks included in these Terms, there are other risks associated with your purchase, possession and use of the token, including unpredictable risks. Such risks may materialize even more as unforeseen variations or combinations of risks discussed in these Terms.`,

    condition_title_25: `23. Cooperation with legal authorities`,
    condition_description_25: `23.1. Digital Horizon cooperates with all requests, subpoenas or requests of the law, provided that they are fully supported and documented by the law in the relevant jurisdictions.`,

    condition_title_26: `24. Indemnification`,
    condition_description_26: `24.1. To the maximum extent permitted by applicable law, the User will indemnify, defend and hold harmless Digital Horizon against all claims, demands, actions, damages, losses, costs and expenses (including attorneys' fees) arising out of or relating to: (a) user's purchase and use of BLUE token; (b) user's responsibilities or obligations under these Terms; (c) user's violation of these Terms; or (d) user's violation of any rights of any other person or entity.
    <br><br>

    24.2. Digital Horizon reserves the right to exercise exclusive control over the
    defense, at its expense, of any claim subject to indemnification under the terms of
    this section. This indemnity is in addition and not in lieu of any other indemnities established in a written contract between the User and Digital Horizon`,

    condition_title_27: `25. Language`,
    condition_description_27: `24.1. Currently, only the English versions of any Blue communication are
    considered official. The English version will prevail in case of translation differences.`,

    condition_title_28: `26. Governing Law`,
    condition_description_28: `26.1. The Terms, the arbitration clause contained therein and any non-contractual obligations arising in any way arising out of or relating to these Terms are governed, construed and enforced in accordance with the Salvadoran Laws.`,

    condition_title_29: `27. Arbitration`,
    condition_description_29: `27.1. Any dispute or difference arising out of or relating to these Terms or to the legal relationships set forth in these Terms, including any question relating to their existence, validity or termination ("Dispute"), will be submitted and finally settled by the Mediation and Arbitration Centre of the Chamber of Commerce and Industry of El Salvador.`,

    condition_title_30: `28. About technical user support`,
    condition_description_30: `28.1. The technical support to the User will be made through e-mail, which can be obtained through the Blue Platform Site.`,

    condition_title_31: `29. Final provisions`,
    condition_description_31: `29.1. In the event of User's non-compliance with any provision of this "Terms and Conditions of Use", Blue may declare it resolved in relation to the User, regardless of any notice, notification or other formality, immediately interrupting User access to the Blue Platform and its operations, without prejudice to any other rights granted to Digital Horizon, either by law or by this "Terms and Conditions of Use".
    <br><br>

    29.2. User acknowledges that the services provided by the Blue Platform have been
    sufficiently described in the "White Paper'' and in this "Terms and Conditions of
    Use'', and that Digital Horizon Blockchain Technology has duly complied with its
    duty to provide information.
    <br><br>

    29.3. This "Terms and Conditions of Use" does not imply assignment or transfer to
    the User of any rights to the Site, or any part of its content or features. Digital
    Horizon reserves the right to demand, at any time, any and all necessary User
    documentation in order to safeguard the operations performed by the Blue Platform
    Site, so that the User hereby agrees to provide any and all documents when
    requested by Digital Horizon.
    <br><br>

    29.4. Digital Horizon is not responsible for the lack of declaration of operations to the competent authorities, mainly regarding the information that must be provided by the User in the Income Tax Return. In the same way, Digital Horizon is not responsible for any taxes, fees and / or contributions possibly incurred on the
    operations, as well as any possible penalties that the User may suffer from the
    competent authorities.
    <br><br>

    29.5. The User is aware that the Declaration of his assets, profits and / or losses, as well as the reflexes arising from them, is his sole and exclusive responsibility.
    <br><br>

    29.6. The User declares to be responsible, civil and criminal, for all the information and documents that involve the operations in the Blue Platform, stating that the money used for purchases of BLUE tokens, have legal and declared origin, being strictly prohibited the use of the Platform for the practice of crimes such as money laundering and evasion of foreign currency, and the User may be civilly and criminally liable not only to the authorities, but also to Digital Horizon for any damages and / or damages caused, including Digital Horizon's image.
    <br><br>

    29.7. The User hereby declares that there is no regulation on the purchase and sale
    of products and services in cryptocurrencies, and there is no endorsement of the
    rules of the Central Bank. The relationship is based solely on trust between User
    and Digital Horizon.
    <br><br>

    29.8. The user acknowledges that the Blue Token should not be interpreted, treated
    or classified as: any currency other than cryptocurrency; debentures, shares or
    shares issued by any person or entity; rights, options or derivatives relating to such debentures, shares; rights under a contract for differences or under any other
    contract whose intended purpose or purpose is to secure a profit or avoid a loss;
    units in a collective investment scheme; units in a business trust; derivative units in an enterprise trust; any other security or class of securities.
    <br><br>

    29.9. Users are prohibited from modifying or attempting to modify any functionality,
    to produce any derivations, or to access any part of the Blue Platform in order to
    create a competing product or service, or any product or service that contains
    ideas, features or functions similar to the Blue Platform.
    <br><br>

    This document was last updated: May 1st, 2022 <br> <br> <br> <br> <br> <br>`,
  },
};
