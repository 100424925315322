export const endpoints = {
  // backendUrl: "172.16.17.124:84",
  // backendUrl: "localhost:8080",
  // backendUrl: "rc.bluetoken.io",
  backendUrl: "bluetoken.io",
  // backendUrl: "devel-aza.stratum.local",
  urlSuffix: "api",
  // protocol: 'http:',
  protocol: 'https:',
}
