<template>
  <div class="container-links-header">
      <!-- <a class="align-items-center bar-item-links" to="/BeMember"> {{ $t('header.welcome_link_header_menu') }}  </a> -->
      <a class="align-items-center bar-item-links" to="/Wallet">{{ $t('header.access_link_header_menu') }}</a>
      <!-- <a class="align-items-center bar-item-links" to="/BlueClub">{{ $t('header.blue_club_link_header_menu') }}</a> -->
      <a class="align-items-center bar-item-links" to="/Faqs">{{ $t('header.faq_link_header_menu') }}</a>
      <a class="align-items-center bar-item-links" to="/Blog">{{ $t('header.blogs_link_header_menu') }}</a>
      <a class="align-items-center bar-item-links" to="/Buy">{{ $t('header.buy_now_link_header_menu') }}</a>
  </div>
</template>

<style>
.container-links-header {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
}

.block-item-link-header {
  display: block;
}

</style>

<script>
export default {
  // layout: 'default',
  name: 'TopHeaderLinks',
  data: () => ({})
}
</script>
