<template>
  <div class="modal_mask" @click="toggleModalCurrencies()">
    <div class="wrapper_modal_other_currencies">
      <div class="content_other_currencies">
        <div class="column table_pool" v-if="membersOnPoolProgram.length > 0">
          <div class="container-close-modal">
            <span @click="toggleModalCurrencies()">X</span>
          </div>
          <div class="header_title">
            <h3>
              {{ $t("last_month_winners") }}:
              {{ $t(`months.${month}`) }}
            </h3>
            <h3>
              {{ $t("last_month_blue_total") }}: {{ totalValue() }} BLUE
            </h3>
          </div>
          <div class="table_container_pool_modal">
            <table>
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th style="text-align: right">
                    {{ $t("pool.referralVolume") }}
                  </th>
                  <th style="text-align: right">
                    {{ $t("pool.reward") }}
                  </th>
                  <th style="text-align: right">
                    {{ $t("pool.estimated") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in membersOnPoolProgram" :key="index">
                  <td>
                    <div class="icon_table">
                      <img :src="renderImage(item.avatar)" :alt="item.avatar" />
                      <span>{{ item.nickname }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="icon_table" style="justify-content: end">
                      <!-- <img
                        src="../assets/images/blue_icon.svg"
                        alt="blue_token"
                      /> -->
                      <span>{{
                        item.referralVolume && item.referralVolume.toFixed(2)
                      }}</span>
                    </div>
                  </td>
                  <td style="text-align: right">
                    {{ item.rewardPercent && item.rewardPercent.toFixed(2) }}%
                  </td>
                  <td>
                    <div class="icon_table" style="justify-content: end">
                      <!-- <img
                        src="../assets/images/blue_icon.svg"
                        alt="blue_token"
                      /> -->
                      {{ item.estimated && item.estimated.toFixed(2) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import avatar1 from "../../assets/imgs/avatar_pool/avatar1.svg";
import avatar2 from "../../assets/imgs/avatar_pool/avatar2.svg";
import avatar3 from "../../assets/imgs/avatar_pool/avatar3.svg";
import avatar4 from "../../assets/imgs/avatar_pool/avatar4.svg";
import avatar5 from "../../assets/imgs/avatar_pool/avatar5.svg";
import avatar6 from "../../assets/imgs/avatar_pool/avatar6.svg";
import avatar7 from "../../assets/imgs/avatar_pool/avatar7.svg";
import avatar8 from "../../assets/imgs/avatar_pool/avatar8.svg";
import avatar9 from "../../assets/imgs/avatar_pool/avatar9.svg";
import avatar10 from "../../assets/imgs/avatar_pool/avatar10.svg";

export default {
  props: ["membersOnPoolProgram", "month"],
  created() {},
  data: () => ({
    pairs: [],
    pairSymbs: ["BTC", "ETH", "USD", "BRL", "GBP", "ZAR", "EUR"],
    blue: {},
    fundTails: {},
    pools: [],
  }),
  methods: {
    toggleModalCurrencies() {
      this.$emit("toggleModalCurrencies");
    },

    totalValue() {
      let total = 0;

      // console.log(this.membersOnPoolProgram)

      for (let i in this.membersOnPoolProgram) {
        total += this.membersOnPoolProgram[i].estimated;
      }

      if (total > 0) {
        return total.toFixed(2);
      }
    },
    renderImage(e) {
      if (e == "avatar1") {
        return avatar1;
      } else if (e == "avatar2") {
        return avatar2;
      } else if (e == "avatar3") {
        return avatar3;
      } else if (e == "avatar4") {
        return avatar4;
      } else if (e == "avatar5") {
        return avatar5;
      } else if (e == "avatar6") {
        return avatar6;
      } else if (e == "avatar7") {
        return avatar7;
      } else if (e == "avatar8") {
        return avatar8;
      } else if (e == "avatar9") {
        return avatar9;
      } else if (e == "avatar10") {
        return avatar10;
      }
    },
  },
  computed: {
    // ...mapGetters(["eurValue", "currencies", "bluAccount"]),
  },
};
</script>


<style lang="scss" scope>
.container-close-modal {
  text-align: end;
  justify-content: end;
  display: block;
  max-width: 100%;
  width: 100%;

  span {
    margin: 0 0 0 auto !important;
    cursor: pointer;
  }
}

.table_pool {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;

    h3 {
      font-size: 1.2rem;
      color: #999797;
      font-weight: 600;
    }

    a {
      color: #61b574;
      font-weight: 500;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .table_container_pool_modal {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    table {
      margin: 0.5rem;
      padding: 0;
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      overflow-x: auto;

      th,
      td {
        padding: 0.5rem;
        font-weight: 500;
      }

      th {
        padding: 0.5em;
        font-size: 1rem;
        color: #132787;
        border-bottom: 2px solid #9b9999;
      }

      .icon_table {
        display: flex;
        align-items: center;

        span {
          @media screen and (max-width: 700px) {
            font-size: 11px;  
          }
        }

        img {
          margin-right: 10px;
          width: 1.2rem;
        }
      }
    }
  }
}

.table_pool {
  .header_title {
    h3 {
      font-size: 0.9rem;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      h3 {
        font-size: 0.45rem !important;
        margin-bottom: 0;
      }
    }

    a {
      font-size: 0.9rem;
    }
  }

  .table_container_pool_modal {
    table {
      th,
      td {
        padding: 0.5em;
      }

      th {
        padding: 0.4em;
        font-size: 0.9rem;
        border-bottom: 1px solid #9b9999;
      }

      td {
        font-size: 0.9rem;
      }

      .icon_table {
        img {
          margin-right: 5px;
          width: 0.7rem;
        }
      }
    }
  }
}

.modal_mask {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 200%;
  left: 0;
  top: 0;
  z-index: 998;
  overflow: auto;
}

.wrapper_modal_other_currencies {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content_other_currencies {
    position: relative;
    padding: 2rem 2.5rem;
    border-radius: 6px;
    max-width: 55%;
    max-height: 465px;
    width: 100%;
    background-color: #fff;

    .title_other_currencies {
      display: flex;
      border-bottom: 1px solid black !important;
      padding-bottom: 0.5rem;
      width: 100%;
      font-size: 1.5rem;
      text-align: center;
      justify-content: center;

      h1 {
        font-size: 1rem;
        font-weight: 600;
        color: #342365;
      }

      .usdt_value {
        display: flex;
        align-items: center;
        img {
          width: 1.4rem;
          margin-right: 10px;
        }

        span {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    svg {
      position: absolute;
      top: 0.5rem;
      width: 1.2rem;
      right: 0.5rem;
      cursor: pointer;

      &:hover {
        color: #342365;
      }
    }

    .currencies_equivalent {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin: 1.2rem 0;
      }

      .wrapper_currencies_list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .currencies_list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.8rem;

          span {
            font-size: 1rem;
            font-weight: 600;
          }

          img {
            width: 1.4rem;
            margin-left: 15px;
          }

          .currencies_icon {
            width: 1.4rem;
            height: 1.4rem;
            margin-left: 15px;
          }
        }
      }

      p {
        margin-top: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .wrapper_modal_other_currencies {
    .content_other_currencies {
      padding: 2rem 1.5rem;
      max-width: 95%;
      max-height: 450px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .wrapper_modal_other_currencies {
    bottom: 20vh;

    .content_other_currencies {
      max-width: 95%;
      max-height: 450px;
    }
  }
}
</style>
