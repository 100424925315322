<template>
  <div>
    <InitialBanner
      image="bg_banner_blue_pool.png"
      imageTitle="pool.page_title"
    />

  
    <div v-if="modal">
      <LastPoolModal
        @toggleModalCurrencies="toggleModal()"
        :membersOnPoolProgram="lastMonthPools"
        :month="month"
      >
      </LastPoolModal>
    </div>

    <div class="container-pool-card white-card mt-5">
      <div class="container-content-pool-card">
        <p class="card-head-description">{{ $t('pool.card_head_description') }} 
          <a 
            :href="getLocale() === 'en' ? 'https://youtu.be/NujOX3n7d-g' : 'https://youtu.be/D8P1_01-uF4' "
            target="_blank" 
            class="color-orange"
          >{{ $t('pool.card_head_description_link') }}</a> 
        </p>
        
        <div class="contianer-pool-blocks">
          <div class="block-pool-values">
            <h3 class="subTitle-pool-card"> {{ $t('pool.pool_rewards', { month: $t(`months.${this.month}`), }) }} </h3>
            <div class="d-flex content-rewards">
              <span v-html="iconSets.blueIcon" class="incon-pool-page"></span>
              <div class="block-reward-values">
                <!-- <p> {{ bluePoolQuantity }} </p> -->
                <!-- <span>{{ bluePoolValue }}</span> -->
                <p v-if="activePool.amount >= 0 "> {{ activePool.amount.toFixed(2) }} </p>
                <span>{{ (blueCoin.value * activePool.amount).toFixed(2) }} USDT</span>
              </div>
            </div>
          </div>

          <div class="block-pool-countdown">
            <h3 class="subTitle-pool-card"> {{ $t('pool.pool_closes_in') }} </h3>
            <div class="pool-timer">
              <span v-for="(times, index) in times" v-bind:key="index">{{
                times.time + times.text + " "
              }}</span>
            </div>
          </div>

        </div>

        <div class="container-footer-description">
          <div class="d-flex align-items-center custom-container-leaderboard">
            <h3> {{ $t('pool.pool_leaderboard') }} </h3>
            <a class="color-orange ml-a" @click="() => toggleModal()"> {{ $t('pool.last_month_winners') }} </a>
          </div>

          <div v-if="loadingTable">
            <Loading></Loading>
          </div>

          <div class="table_container">
            <table>
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th style="text-align: right">
                    {{ $t("pool.referralVolume") }}
                  </th>
                  <th style="text-align: right">
                    {{ $t("pool.reward") }}
                  </th>
                  <th style="text-align: right">
                    {{ $t("pool.estimated") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in membersOnPoolProgram"
                  :key="index"
                >
                  <td>
                    <div class="icon_table">
                      <img 
                        :src="renderImage(item.avatar)"
                        :alt="item.avatar"
                        class="avatar-img"
                      />
                      <span>{{ item.nickname }}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      class="icon_table"
                      style="justify-content: end"
                    >
                      <img
                        src="../assets/icons/blue_icon_1.svg"
                        alt="blue_token"
                      />
                      <span>{{
                        item.referralVolume &&
                        item.referralVolume.toFixed(2)
                      }}</span>
                    </div>
                  </td>
                  <td style="text-align: right">
                    {{
                      item.rewardPercent &&
                      item.rewardPercent.toFixed(2)
                    }}%
                  </td>
                  <td>
                    <div
                      class="icon_table"
                      style="justify-content: end"
                    >
                      <img
                        src="../assets/icons/blue_icon_1.svg"
                        alt="blue_token"
                      />
                      {{ item.estimated && item.estimated.toFixed(2) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from "dayjs";
import InitialBanner from '../components/InitialBanner.vue'
import Loading from "../components/Loading";
import LastPoolModal from '../components/modals/LastPoolModal.vue'

import avatar1 from "../assets/imgs/avatar_pool/avatar1.svg";
import avatar2 from "../assets/imgs/avatar_pool/avatar2.svg";
import avatar3 from "../assets/imgs/avatar_pool/avatar3.svg";
import avatar4 from "../assets/imgs/avatar_pool/avatar4.svg";
import avatar5 from "../assets/imgs/avatar_pool/avatar5.svg";
import avatar6 from "../assets/imgs/avatar_pool/avatar6.svg";
import avatar7 from "../assets/imgs/avatar_pool/avatar7.svg";
import avatar8 from "../assets/imgs/avatar_pool/avatar8.svg";
import avatar9 from "../assets/imgs/avatar_pool/avatar9.svg";
import avatar10 from "../assets/imgs/avatar_pool/avatar10.svg";


export default {
  components: {
    InitialBanner,
    Loading,
    LastPoolModal,
  },
  data: () => ({
    activePool: "",
    blueCoin: 0,
    bluePoolQuantity: '4.00',
    bluePoolValue: '798.06 USDT',
    timeinterval: undefined,
    progress: 100,
    times: [
      { id: 0, text: "D", time: 1 },
      { id: 1, text: "H", time: 1 },
      { id: 2, text: "M", time: 1 },
      { id: 3, text: "S", time: 1 },
    ],
    startTime: "July 7, 2017 12:03:00",
    endTime: "July 9, 2052 14:55:00",
    month: "",
    membersOnPoolProgram: [],
    loadingTable: true,
    modal: false,
    lastMonthPools: [],
  }),
  methods: {
    toggleModal() {
      this.modal = !this.modal;
    },

    async getMonth(el) {
      let date = new Date(el.startAt);
      // console.log(date.getMonth());
      this.month = date.getMonth();

      if(month == 1){
        this.month = 12;
      }else{
        this.month = month;
      }

    },

    renderImage(e) {
      if (e == "avatar1") {
        return avatar1;
      } else if (e == "avatar2") {
        return avatar2;
      } else if (e == "avatar3") {
        return avatar3;
      } else if (e == "avatar4") {
        return avatar4;
      } else if (e == "avatar5") {
        return avatar5;
      } else if (e == "avatar6") {
        return avatar6;
      } else if (e == "avatar7") {
        return avatar7;
      } else if (e == "avatar8") {
        return avatar8;
      } else if (e == "avatar9") {
        return avatar9;
      } else if (e == "avatar10") {
        return avatar10;
      }
    },

    updateTimer() {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
        this.progress = 0;
      }
    },

    updateProgressBar() {
      let startTime = dayjs(new Date(this.startTime));
      let currentTime = new Date();
      let endTime = dayjs(new Date(this.endTime));

      let interval = parseFloat(
        ((currentTime - startTime) / (endTime - startTime)) * 100
      ).toFixed(2);

      this.progress = 100 - interval;
    },
    getLocale() {
      return this.$i18n.locale
    },
    getTimeRemaining() {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor((t / 1000) % 60); //seconds
        this.times[2].time = Math.floor((t / 1000 / 60) % 60); //minutes
        this.times[1].time = Math.floor((t / (1000 * 60 * 60)) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time =
          this.times[2].time =
          this.times[1].time =
          this.times[0].time =
            0;
        this.progress = 0;
      }
    },
    async getPool() {
      let pools = [];
      let resp = await this.$store.dispatch("getPools");
      console.log('resp => ', resp);
      if (resp.status == 200) {
        pools = resp.data;
      } else {
        alert("Something wrong");
      }

      for (let i in pools) {
        if (pools[i].active) {
          this.activePool = pools[i];
          this.getMonth(this.activePool);
          this.startTime = this.activePool.startAt;
          this.endTime = this.activePool.endAt;
        }
      }
    },
    async getSummary() {
      let resp = await this.$store.dispatch("getPoolsSummary");
      if (resp.status == 200) {
        let allPools = resp.data;
        for (let i in allPools) {
          if (allPools[i].referralVolume >= 1) {
            this.membersOnPoolProgram.push(allPools[i]);
            this.membersOnPoolProgram.sort(function (a, b) {
              if (a.referralVolume < b.referralVolume) {
                return 1;
              }
              if (a.referralVolume > b.referralVolume) {
                return -1;
              }
              return 0;
            });
          }
          this.loadingTable = false;
        }
      } else {
        alert("Something wrong");
      }
    },

    async getCurrencies() {   
      let currencies = [];
      if (this.currencies.length === 0) {
        const allCurrencies = await this.$store.dispatch('getCurrencies')
        currencies.push(...allCurrencies)
      }else {
        currencies.push(...this.currencies)
      }
      const filterByBluCoin = currencies.filter(currency => currency.symbol === 'BLU')

      if (filterByBluCoin.length > 0) {
        this.blueCoin = filterByBluCoin[0]
      }

    },
      async getSummaryByDate() {
      let date = new Date();
      let month = date.getMonth();
      let year = date.getFullYear();

      if (month < 10) {
        month = "0" + month;
      }

      var teste = new Date(),
      y = teste.getFullYear(),
      m = date.getMonth();
      var lastDay = new Date(year, month, 0);

      let last = lastDay.getDate();
      this.month = month;

      let start = `${year}-${month}-01T00:28:21.0Z`;
      let end = `${year}-${month}-${last}T23:28:21.0Z`;

      let params = {
        startAt: start,
        endAt: end,
      };

      let resp = await this.$store.dispatch("getPoolsSummaryByDate", params);
      if (resp.status == 200) {
        let allPools = resp.data;
        for (let i in allPools) {
          if (allPools[i].referralVolume >= 1) {
            this.lastMonthPools.push(allPools[i]);
            this.lastMonthPools.sort(function (a, b) {
              if (a.referralVolume < b.referralVolume) {
                return 1;
              }
              if (a.referralVolume > b.referralVolume) {
                return -1;
              }
              return 0;
            });
          }
          this.loadingTable = false;
        }
      } else {
        alert("Something wrong");
      }
    },
  },
  mounted() {
    this.getPool();
    this.getSummary();
    this.updateTimer();
    this.getSummaryByDate();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  },
  computed: {
    ...mapGetters({
      currencies: 'currencies',
    }),
  },
  created() {
    this.getCurrencies();
  }
}
</script>

<style>

</style>